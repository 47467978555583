import React from 'react';
import { CircularProgress, Typography, Link, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { OL_ZINDEXES, CONVECTIVE_LAYERS } from '../../config';
import useHighlightLayer from './hooks/use-highlight-layer';
import ConvectiveLegend from '../menu/legend-menu-items/convective-legend.js';

const sxStyles = {
    tableCell: {
        fontSize: '0.8em',
    },
    tableHeader: {
        border: 'none',
    },
    bulletinLink: {
        textDecoration: 'none',
        color: 'primary.main',
        '&:hover': {
            color: 'secondary.light',
        },
        whiteSpace: 'nowrap'
    }
};

const classes ={
    ConvectiveFeatureInfoContainer: {
        padding: '10px'
    },
    LegendContainer: {
        paddingTop: '0.5em'
    }
};

const convectiveField = {
    "Categorical Outlook" : "Category",
    "Significant Tornado Outlook" : "Outlook",
    "Probabilistic Tornado Outlook" : "Probability at Selected Location",
    "Significant Hail Outlook" : "Outlook",
    "Probabilistic Hail Outlook" : "Probability at Selected Location",
    "Significant Wind Outlook" : "Outlook",
    "Probabilistic Wind Outlook" : "Probability at Selected Location",
    "Significant Severe Weather Outlook" : "Outlook",
    "Probabilistic Severe Weather Outlook" : "Probability at Selected Location",
    "Day 3 Probabilistic Severe Weather Outlook" : "Probability at Selected Location",
    "Day 4-8 Probabilistic Severe Weather Outlook" : "Probability at Selected Location"
};

const convectiveKey = {
    "Categorical Outlook" : {
        2 : "Thunderstorm",
        3 : "Marginal",
        4 : "Slight",
        5 : "Enhanced",
        6 : "Moderate",
        8 : "High"
    },
    "Significant Tornado Outlook" : {
        10 : "Significant"
    },
    "Probabilistic Tornado Outlook" : {
        2 : "2%",
        5 : "5%",
        10 : "10%",
        15 : "15%",
        30 : "30%",
        45 : "45%",
        60 : "60%+"
    },
    "Significant Wind Outlook" : {
        10 : "Significant"
    },
    "Probabilistic Wind Outlook" : {
        5 : "5%",
        15 : "15%",
        30 : "30%",
        45 : "45%",
        60 : "60%+"
    },
    "Significant Hail Outlook" : {
        10 : "Significant"
    },
    "Probabilistic Hail Outlook" : {
        5 : "5%",
        15 : "15%",
        30 : "30%",
        45 : "45%",
        60 : "60%+"
    },
    "Significant Severe Weather Outlook" : {
        10 : "Significant"
    },
    "Probabilistic Severe Weather Outlook" : {
        5 : "5%",
        15 : "15%",
        30 : "30%",
        45 : "45%",
        60 : "60%+"
    },
    "Day 3 Probabilistic Severe Weather Outlook" : {
        5 : "5%",
        15 : "15%",
        30 : "30%",
        45 : "45%",
        60 : "60%+"
    },
    "Day 4-8 Probabilistic Severe Weather Outlook" : {
        15 : "15%",
        30 : "30%+",
    }
};

const dateFormat = {
    dateStyle: "medium",
    timeStyle: "long"
};

/**
* Component for rendering results from getFeatureInfo wms requests
*
* @prop (obj) data - json response obj from getFeatureInfo request
**/
export default function ConvectiveFeatureInfo(props) {

    const [clearHighlight, drawFeatureHighlight] = useHighlightLayer(props.map, OL_ZINDEXES.convective_highlight_layer);

    let layerCount = 0;
    for (const layer in props.data) {
        if (Object.keys(props.data[layer]).length !== 0) {
            layerCount = layerCount + 1;
        }
    }

    const convectiveTypes = new Set();
    for (const layer in props.data) {
        if (props.data[layer].features) {
            if (props.data[layer].features.length > 0 && props.olLayerState[layer].on) {
                convectiveTypes.add(layer);
            }
        }
    }

    clearHighlight();
    if (Object.keys(props.data).length > 0) {
        for (const layer in props.data) {
            if (props.data && props.data[layer] && props.data[layer].features) {
                for (const feature of props.data[layer].features) {
                    if (props.olLayerState[layer].on) {
                        drawFeatureHighlight(feature);
                    }
                }
            }
        }
    }

    //if not all feature service requests have responded
    if (layerCount < 21) {
        return(<CircularProgress sx={{ml: "10em", mt: "10px"}} />);
    }

    if (convectiveTypes.size === 0) {
        return(
            <div style={{padding: '40px 30px'}}>
                <Typography sx={{fontSize: '0.8em'}}>No Results Found</Typography>
                <br />
                <Typography sx={{fontSize: '0.8em'}}>No data was found for this location.</Typography>
            </div>
        );
    }

    let content = [...convectiveTypes].map((convectiveType,index) => {
        var bulletinLink = ""
        if(parseInt(CONVECTIVE_LAYERS[convectiveType].day) < 4) {
            bulletinLink = "https://www.spc.noaa.gov/products/outlook/day" + CONVECTIVE_LAYERS[convectiveType].day + "otlk.html"
        } else {
            bulletinLink = "https://www.spc.noaa.gov/products/exper/day4-8/"
        }
        var validTimeString = props.data[convectiveType].features[0].properties.valid
        var validTimestamp = Date.parse(validTimeString.slice(0,4) + '-' + validTimeString.slice(4,6) + '-' + validTimeString.slice(6,8) + 'T' + validTimeString.slice(8,10) + ':' + validTimeString.slice(10,12) + ':00Z')
        var expireTimeString = props.data[convectiveType].features[0].properties.expire
        var expireTimestamp = Date.parse(expireTimeString.slice(0,4) + '-' + expireTimeString.slice(4,6) + '-' + expireTimeString.slice(6,8) + 'T' + expireTimeString.slice(8,10) + ':' + expireTimeString.slice(10,12) + ':00Z')
        return (
            <div style={{
                marginLeft: 10, marginRight: 10, paddingBottom: 10, paddingTop: 5
                }}
                key={index}
            >
                {CONVECTIVE_LAYERS[convectiveType].layer_name === "probabilistic_severe_day3" ?
                    <Typography variant="overline">{CONVECTIVE_LAYERS[convectiveType].specific_label} - Day {CONVECTIVE_LAYERS[convectiveType].day}</Typography>
                : parseInt(CONVECTIVE_LAYERS[convectiveType].day) > 3 ?
                    <Typography variant="overline">Severe Weather Outlook - Day {CONVECTIVE_LAYERS[convectiveType].day}</Typography>
                : <Typography variant="overline">{CONVECTIVE_LAYERS[convectiveType].label} - Day {CONVECTIVE_LAYERS[convectiveType].day}</Typography>}
                <TableContainer key={index}>
                    <Table size="small">
                        {index === 0 ?
                        <TableHead>
                            <TableRow sx={sxStyles.tableHeader}>
                                <TableCell><Link href={bulletinLink} sx={sxStyles.bulletinLink} target="_blank" rel="noopener noreferrer">View Bulletin</Link></TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}></TableCell>
                            </TableRow>
                        </TableHead>
                        : null }
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>{convectiveField[CONVECTIVE_LAYERS[convectiveType].label]}:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{convectiveKey[CONVECTIVE_LAYERS[convectiveType].label][props.data[convectiveType].features[0].properties.dn]}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Valid From:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{Intl.DateTimeFormat("en-US", dateFormat).format(new Date(validTimestamp))}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Until:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{Intl.DateTimeFormat("en-US", dateFormat).format(new Date(expireTimestamp))}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Service Date:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{Intl.DateTimeFormat("en-US", dateFormat).format(props.data[convectiveType].features[0].properties.idp_filedate)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    });

    return(
        <div style={classes.ConvectiveFeatureInfoContainer}>
            <Typography align="center">NWS Storm Prediction Center</Typography>
            <Typography align="center">Convective Outlooks</Typography>
            {content}
            <div style={classes.LegendContainer} align="center">
                <ConvectiveLegend
                    olLayerState={props.olLayerState}
                />
            </div>
        </div>
    );
}