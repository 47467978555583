import React, { useState } from 'react';
import { CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from '@mui/material';

import useHighlightLayer from './hooks/use-highlight-layer';
import { OL_ZINDEXES } from '../../config';

const sxStyles = {
    tableContainer: {
        paddingLeft: '10px',
    },
    tableCell: {
        fontSize: '0.8em',
    },
    tableHeader: {
        border: 'none',
    },
    link: {
        fontSize: '0.9em',
    }
};

const classes = {
    NBSFeatureInfoContainer: {
        padding: '15px'
    },

};

/**
* Component for rendering results from getFreatureInfo wms requests
*
* @prop (obj) map - OL map object used for drawing feature info highlights
* @prop (obj) data - json response obj from getFreatureInfo request
**/
export default function NBSFeatureInfo(props) {

    const [clearHighlight, drawFeatureHighlight] = useHighlightLayer(props.map, OL_ZINDEXES.bluetopo_highlight_layer);

    if (Object.keys(props.data).length === 0) {
        return(<CircularProgress sx={{margin: '70px 20px'}} />);
    }

    if (props.data.bathymetry.features && (props.data.bathymetry.features.length === 0 || props.data.bathymetry.features[0].properties.ELEVATION === -50000000)) {
        return(
            <div style={{padding: '40px 30px'}}>
                <Typography sx={{fontSize: '0.8em'}}>No Results Found</Typography>
                <br />
                <Typography sx={{fontSize: '0.8em'}}>No data values, hyperlinks or other information were found for this location. Please try another location, toggle desired layers on/off, or adjust the time control, then try again.</Typography>
                <br />
            </div>
        );
    }

    // Handle drawing of GeoJSON features returned by getFeatureInfo
    clearHighlight();
    if (props.data.hasOwnProperty('bluetopo_tile_scheme') && props.data.bluetopo_tile_scheme.hasOwnProperty('features')) {
        for (const feature of props.data.bluetopo_tile_scheme.features) {
            drawFeatureHighlight(feature);
        }
    }

    let tileSchemeHeader = null;
    let tileSchemeContent = null;
    if (props.data.hasOwnProperty('bluetopo_tile_scheme')) {

        tileSchemeHeader = <div>
            <Typography align="center" color='#005da6' fontWeight='bold' psx={{margin:'10px'}}>BlueTopo Tile Scheme</Typography>
            <div style={{borderBottom: '1px solid rgba(0, 0, 0, .125)',marginLeft: 10, marginRight: 10,}}></div>
        </div>;

        if (props.data.bluetopo_tile_scheme.hasOwnProperty('features')) {

            tileSchemeContent = props.data.bluetopo_tile_scheme.features.map((feature, index) => {
                return(

                    <TableContainer sx={sxStyles.tableContainer} key={index}>
                        <Table size="small">
                            <TableBody>
                                <TableRow sx={sxStyles.tableHeader}>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Tile Identifier:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.tile}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Tile Delivery Date:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.delivered_date} ET</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>UTM Zone Number:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.utm}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Resolution:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.resolution}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Tile Scheme Issuance:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.issuance}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Link href={feature.properties.geotiff_link} sx={sxStyles.link} target="_blank" rel="noopener noreferrer">Download Source GeoTiff</Link></TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Link href={feature.properties.rat_link} sx={sxStyles.link} target="_blank" rel="noopener noreferrer">Download Source RAT</Link></TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                );
            });
        }
    }


    if (props.data.bathymetry.features && props.data.bathymetry.features.length > 0) {
        return(
            <div style={classes.NBSFeatureInfoContainer}>
               <center><img src="https://www.nauticalcharts.noaa.gov/data/images/bluetopo/logo.png" alt="BlueTopo logo" width="160" height="25"/></center>
                <div style={{
                    borderBottom: '1px solid rgba(0, 0, 0, .125)',
                    marginLeft: 10, marginRight: 10, marginTop: 15,
                    }}
                >
                </div>
                { (props.data.bathymetry.features[0].properties.ELEVATION !== 0) ?
                    <TableContainer sx={sxStyles.tableContainer}>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Elevation:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{Number.parseFloat(props.data.bathymetry.features[0].properties.ELEVATION).toFixed(2)}m</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Uncertainty:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>+/- {Number.parseFloat(props.data.bathymetry.features[0].properties.UNCERTAINTY).toFixed(2)}m</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Contributor:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{props.data.bathymetry.features[0].properties.CONTRIBUTOR}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : <div style={{padding: '40px 30px'}}>
                        <Typography sx={{fontSize: '0.8em'}}>No Results Found</Typography>
                        <br />
                        <Typography sx={{fontSize: '0.8em'}}>No data values, hyperlinks or other information were found for this location. Please try another location, toggle desired layers on/off, or adjust the time control, then try again.</Typography>
                        <br />
                    </div>
                }
                <br />
                {
                    tileSchemeHeader
                }
                {
                    tileSchemeContent
                }

            </div>
        );
    } else {
        return(null);
    }
};