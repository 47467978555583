import { createTheme } from '@mui/material/styles';

import { BREAKPOINT_VALUES } from "./config.js";
// NOAA Colors: Dark: #253e77, Light #017dc5

const theme = createTheme({
  palette: {
    primary: {
        main: '#005194',
    },
    secondary: {
       // main: '#b3cadf', //old nowcoast button light blue - used as background for top level menu buttons (basemaps,layers,legends, overlays)
        main: '#005194',
      light: '#4c7dc5', //mui palette light from 005194
    },
  },
  breakpoints: {
    values: {
      xs: BREAKPOINT_VALUES.xs, // cellphone
      sm: BREAKPOINT_VALUES.sm, // tablet
      md: BREAKPOINT_VALUES.md, // small laptop
      lg: BREAKPOINT_VALUES.lg, // laptop/desktop
      xl: BREAKPOINT_VALUES.xl, // widescreen
    },
  },
});

export default theme;
