import React from 'react';

/**
*   Component manages set interval for automatically refreshing OpenLayers sources
*
*   By "auto refresh", it is meant that on every interval, the source will request
*   the latest layer from the server. There is no time change, just a call to updating
*   the source params with copies of its current params to force a new request for the
*   same file
*
*   This process is ONLY needed for WWA at the time of this writing (4/26/21). It is unknown whether
*   or not there will be a similar use-case for any other layers, or if WWA map services will not change
*   to remove the need for this feature in the future.
*
* Props:
*   @prop (obj) sourceObj - a reference to the OpenLayers source object that needs to be refreshed
*   @prop (int) autoRefreshInterval - int containing time in milliseconds for interval
*/
class AutoRefreshInterval extends React.Component {
    constructor (props) {
        super ();

        // Class member
        this.autoRefreshInterval = null;

        // Callback bindings
        this.refreshSource = this.refreshSource.bind(this);
        this.handleInterval = this.handleInterval.bind(this);
    };

    componentWillUnmount() {
        if (this.autoRefreshInterval) {
            clearInterval(this.autoRefreshInterval);
            this.autoRefreshInterval = null;
        }
    }

    refreshSource() {
        if (!this.props.sourceObj) return;
        // Force OpenLayers to request latest copy of current data. The WWA file is being updated in place on the server
        // under the same name, so this makes sure we have the latest
        const newParams = this.props.sourceObj.getParams();
        const dateVal = new Date();
        newParams.time = dateVal.toISOString(); // Must change a parameter to force a new request
        this.props.sourceObj.updateParams(newParams);
    }

    handleInterval() {
        if (!this.autoRefreshInterval) {
            this.autoRefreshInterval = setInterval(() => {
                this.refreshSource();
            }, this.props.autoRefreshInterval);
        }
     }

    render() {
        this.handleInterval();
        return null;
    };
}

export default AutoRefreshInterval;