import { Circle, Fill, Stroke, Style, Text, Icon } from 'ol/style';
import { parseObsTimestamp, parseMETARWeather, parseMETARClouds } from './utilities.js';

const icons = require.context('../images/wfs-icons');

class featureSymbology {
    constructor(features, resolution) {
        this.size = features.length;
        this.features = features;
        this.feature = features[0];
        this.resolution = resolution;
        this.radianMultiplier = Math.PI / 180.0;
    }

    getStyle() {
        if(this.size > 1) {
            return this.getClusterStyle()
        } else {
            return this.getFeatureStyle(this.feature)
        }
    }

    getFeatureStyle() {}

    getClusterStyle() {}

    getLabel(fontSize, labelText, labelColor, offsetX, offsetY, zIndex, strokeWidth = undefined) {
        let stroke;
        if(strokeWidth) {
            stroke = new Stroke({
                color: 'black',
                width: strokeWidth
            })
        } else {
            stroke = undefined
        }
        return new Style({
            text: new Text({
                font: fontSize + 'px sans-serif',
                text: labelText,
                fill: new Fill({color: labelColor}),
                stroke: stroke,
                offsetX: offsetX,
                offsetY: offsetY
            }),
            zIndex: zIndex
        })
    };

    getBackgroundCircle(radius, color, zIndex) {
        return new Style({
            image: new Circle({
                radius: radius,
                fill: new Fill({
                    color: color
                })
            }),
            zIndex: zIndex
        })
    };

    getIcon(scale, src, zIndex, iconOrigin = 'top-left', displacement = [0,0], rotation = 0) {
        return new Style({
            image: new Icon({
                scale: scale,
                src: src,
                rotation: rotation,
                iconOrigin: iconOrigin,
                displacement: displacement
            }),
            zIndex: zIndex
        })
    };
}

class surfaceObsSymbology extends featureSymbology {
    constructor(features, resolution) {
        super(features, resolution);
        this.smallFontSize = '10';
        this.largeFontSize = '14';
    }

    getClusterStyle() {
        let activeClusterSize = 0;
        let currentStation;
        for(const station of this.features) {
            const timestamp = parseObsTimestamp(station.getProperties()['timeobs']);
            const expireTime = new Date(new Date() - (2 * 60 * 60 * 1000));
            if(timestamp >= expireTime) {
                activeClusterSize++;
                currentStation = station;
            }
        }
        if(activeClusterSize === 1) {
            this.getFeatureStyle(currentStation);
        } else if(activeClusterSize === 0) {
            return new Style({})
        } else {
            return [
                new Style({
                    image: new Circle({
                        radius: 20,
                        fill: new Fill({ color: 'rgba(76, 125, 197, 0.3)' })
                    })
                }),
                new Style({
                    image: new Circle({
                        radius: 14,
                        //fill: new Fill({ color: 'rgba(0, 81, 148, 0.7)' })
                        fill: new Fill({ color: 'rgba(76, 125, 197, 0.7)' })
                    }),
                    text: new Text({
                        text: activeClusterSize.toString(),
                        fill: new Fill({ color: '#fff' }),
                        stroke: new Stroke({
                            color: 'rgba(0, 0, 0, 0.6)',
                            width: 3
                        })
                    })
                })
            ]
        }
    };

    getFeatureStyle(station) {
        const stationID = station.getId();
        const timestamp = parseObsTimestamp(station.getProperties()['timeobs']);
        //if station hasn't been updated in 2+ hours, station symbol is not displayed
        const expireTime = new Date(new Date() - (2 * 60 * 60 * 1000));
        if(timestamp < expireTime) {
            return new Style({});
        } else {
            const strokeWidth = 1;
            const zLabel = 8;
            const temp = station.getProperties()['temperature'] === null ? "" : Math.round(parseFloat(station.getProperties()['temperature'])).toString();
            const visibility = station.getProperties()['visibility'] === null ? "" : Math.round(parseFloat(station.getProperties()['visibility'])).toString();
            const dewpoint = station.getProperties()['dewpoint'] === null ? "" : Math.round(parseFloat(station.getProperties()['dewpoint'])).toString();
            const sst = station.getProperties()['sst'] === null ? "" : Math.round(parseFloat(station.getProperties()['sst'])).toString();
            const waveheight = station.getProperties()['waveheight'] === null ? "" : station.getProperties()['waveheight'].toFixed(1).toString();
            const preschange = station.getProperties()['preschange'] === 9999 || station.getProperties()['preschange'] === null ? "" : station.getProperties()['preschange'].toFixed(1).toString();
            const sealevelpress = station.getProperties()['sealevelpress'] === null ? "" : station.getProperties()['sealevelpress'].toFixed(1).toString();
            const winddir = station.getProperties()['winddir'] === null ? "" : station.getProperties()['winddir'];
            const windspeed = station.getProperties()['windspeed'] === null ? "missing" : station.getProperties()['windspeed'];
            const windgust = station.getProperties()['windgust'];

            let weatherAbbreviation = "";
            let cloudCover = station.getProperties()['cloudcover'] === null ? "" : station.getProperties()['cloudcover'];
            if(station.getProperties()['rawdata'] && station.getProperties()['rawdata'] !== "null" && station.getProperties()['rawdata'] !== stationID) {
                let metarWeather = parseMETARWeather(station.getProperties()['rawdata']);
                let metarClouds = parseMETARClouds(station.getProperties()['rawdata']);
                if(metarWeather) {
                    for(const weather of metarWeather) {
                        weatherAbbreviation = weatherAbbreviation + weather.abbreviation;
                    }
                }
                if(metarClouds && metarClouds !== "null") {
                    cloudCover = metarClouds;
                }
            }

            if(this.resolution > 3000) {
                const smallFontSize = this.smallFontSize;
                return [
                    this.getBackgroundCircle(40, 'rgba(255, 255, 255, 0.5)', 2),
                    this.getLabel('9', stationID, 'black', 0, -25, zLabel, strokeWidth),
                    this.getLabel(smallFontSize, temp, 'red', -20, -15, zLabel, strokeWidth),
                    this.getLabel(smallFontSize, visibility, 'orange', -30, 0, zLabel, strokeWidth),
                    this.getLabel(smallFontSize, dewpoint, 'green', -20, 15, zLabel, strokeWidth),
                    this.getLabel(smallFontSize, sst, 'blue', 0, 25, zLabel, strokeWidth),
                    this.getLabel(smallFontSize, waveheight, '#ff00ff', 20, 15, zLabel, strokeWidth),
                    this.getLabel(smallFontSize, preschange, 'purple', 30, 0, zLabel, strokeWidth),
                    this.getLabel('8', sealevelpress, '#2424ff', 21, -15, zLabel, strokeWidth),
                    this.getCloudCoverStyle(cloudCover, 0.4),
                    this.getWindArrowStyle(winddir, windspeed, 0.3),
                ]
            } else {
                const labelFontSize = this.largeFontSize;
                return [
                    this.getBackgroundCircle(70, 'rgba(255, 255, 255, 0.5)', 2),
                    this.getLabel('12', stationID, 'black', 0, -37, 8, strokeWidth),
                    this.getLabel(labelFontSize, temp, 'red', -32, -20, zLabel, strokeWidth),
                    this.getLabel(labelFontSize, visibility, 'orange', -55, 0, zLabel, strokeWidth),
                    this.getWeatherSymbol(weatherAbbreviation, 0.5, -35, zLabel),
                    this.getLabel(labelFontSize, dewpoint, 'green', -32, 20, zLabel, strokeWidth),
                    this.getLabel(labelFontSize, sst, 'blue', 0, 30, zLabel, strokeWidth),
                    this.getLabel(labelFontSize, waveheight, '#ff00ff', 30, 20, zLabel, strokeWidth),
                    this.getLabel(labelFontSize, preschange, 'purple', 32, 0, zLabel, strokeWidth),
                    this.getLabel('12', sealevelpress, '#2424ff', 34, -22, zLabel, strokeWidth),
                    this.getCloudCoverStyle(cloudCover, 0.5),
                    this.getWindArrowStyle(winddir, windspeed, 0.5),
                    this.getWindGustStyle(winddir, windgust)
                ]
            }
        }
    };

    getWeatherSymbol(weather, scale, offsetX) {
        let image;
        if(weather.length > 0) {
            if(weather.includes("+TS")) {
                if(weather.includes("GR") || weather.includes("GS")){
                    image = './+TSGS.png';
                } else if(weather.includes("SA") || weather.includes("DU")) {
                    image = './TSSA.png';
                } else {
                    image = './+TSRA.png';
                }
            } else if(weather.includes("TS") || weather.includes("VCTS")) {
                if(weather.includes("SA") || weather.includes("DU")) {
                    image = './TSSA.png';
                } else if(weather.includes("GR") || weather.includes("GS")) {
                    image = './TSGR.png';
                } else if(weather.includes("RA") || weather.includes("SN") || weather.includes("PL")) {
                    image = './TSRA.png';
                } else if(weather.includes("VC") || weather.includes("VCTS")) {
                    image = './VCTS.png';
                } else {
                    image = './TS.png';
                }
            } else if(weather.includes("GR")) {
                if(weather.includes("-")) {
                    image = './-GR.png';
                } else {
                    image = './GR.png';
                }
            } else if(weather.includes("GS")) {
                if(weather.includes("-")) {
                    image = './-GS.png';
                } else {
                    image = './GS.png';
                }
            } else if(weather.includes("SHSN")) {
                if(weather.includes("-")) {
                    if(weather.includes("RA")) {
                        image = './-SHRASN.png';
                    } else {
                        image = './-SHSN.png';
                    }
                } else {
                    if(weather.includes("RA")) {
                        image = './SHRASN.png';
                    } else {
                        image = './SHSN.png';
                    }
                }
            } else if(weather.includes("SHRA")) {
                if(weather.includes("-")) {
                    if(weather.includes("SN")) {
                        image = './-SHRASN.png';
                    } else {
                        image = './-SHRA.png';
                    }
                } else {
                    if(weather.includes("SN")) {
                        image = './SHRASN.png';
                    } else {
                        image = './SHRA.png';
                    }
                }
            } else if(weather.includes("SH")) {
                if(weather.includes("PL") || weather.includes("PE")) {
                    image = './PL.png';
                } else if(weather.includes("VC")) {
                    image = './VCSH.png';
                } else if(weather.includes("-")) {
                    image = './-SHRA.png';
                } else {
                    image = './SHRA.png';
                }
            } else if(weather.includes("PL") || weather.includes("PE")) {
                image = './PL.png';
            } else if(weather.includes("IC")) {
                image = './IC.png';
            } else if(weather.includes("SG")) {
                image = './SG.png';
            } else if(weather.includes("UP")) {
                image = './UP.png';
            } else if(weather.includes("SN")) {
                if(weather.includes("-")) {
                    if(weather.includes("RA") || weather.includes("DZ")) {
                        image = './-RASN.png';
                    } else {
                        image = './-SN.png';
                    }
                } else {
                    if(weather.includes("RA") || weather.includes("DZ")) {
                        image = './RASN.png';
                    } else if(weather.includes("+")) {
                        image = './+SN.png';
                    } else {
                        image = './SN.png';
                    }
                }
            } else if(weather.includes("FZ")) {
                if(weather.includes("RA")) {
                    if(weather.includes("-")) {
                        image = './-FZRA.png';
                    } else {
                        image = './FZRA.png';
                    }
                }
                else if(weather.includes("DZ")) {
                    if(weather.includes("-")) {
                        image = './-FZDZ.png';
                    } else {
                        image = './FZDZ.png';
                    }
                } else {
                    image = './FZFG.png';
                }
            } else if(weather.includes("RA")) {
                if(weather.includes("DZ")) {
                    if(weather.includes("-")) {
                        image = './-DZRA.png';
                    } else {
                        image = './DZRA.png';
                    }
                } else if(weather.includes("-")) {
                    image = './-RA.png';
                } else if(weather.includes("+")) {
                    image = './+RA.png';
                } else {
                    image = './RA.png';
                }
            } else if(weather.includes("DZ")) {
                if(weather.includes("-")){
                    image = './-DZ.png';
                } else if(weather.includes("+")) {
                    image = './+DZ.png';
                }
                else {
                    image = './DZ.png';
                }
            } else if(weather.includes("FG") || weather.includes("VCFG")) {
                if(weather.includes("PR")) {
                    image = './PRFG.png';
                } else if(weather.includes("BC")) {
                    image = './BCFG.png';
                } else if(weather.includes("MI")) {
                    image = './MIFG.png';
                } else if(weather.includes("VC") || weather.includes("VCFG")) {
                    image = './VCFG.png';
                } else {
                    image = './FG.png';
                }
            } else if(weather.includes("DRSN")) {
                image = './DRSN.png';
            } else if(weather.includes("BLSN")) {
                image = './BLSN.png';
            } else if(weather.includes("SS") || weather.includes("DS")) {
                if(weather.includes("+")) {
                    image = './+SS.png';
                } else if(weather.includes("VC")) {
                    image = './VCSS.png';
                } else {
                    image = './SS.png';
                }
            } else if(weather.includes("DR")) {
                image = './SS.png';
            } else if(weather.includes("FC")) {
                image = './FC.png';
            } else if(weather.includes("SQ")) {
                image = './SQ.png';
            } else if(weather.includes("FC")) {
                image = './FC.png';
            } else if(weather.includes("VIRGA")) {
                image = './VIRGA.png';
            } else if(weather.includes("BR")) {
                image = './BR.png';
            } else if(weather.includes("PO")) {
                image = './PO.png';
            } else if(weather.includes("SA") || weather.includes("BLDU") || weather.includes("BLPY")) {
                image = './SA.png';
            } else if(weather.includes("DU")) {
                image = './DU.png';
            } else if(weather.includes("HZ")) {
                image = './HZ.png';
            } else if(weather.includes("FU") || weather.includes("VA")) {
                image = './FUVA.png';
            } else {
                image = '';
            }
            return this.getIcon(scale, icons(image), 5, 'bottom-right', [offsetX, 0])
        } else {
            return new Style({})
        }
    }

    getCloudCoverStyle(cloudCover, scale) {
        let image;
        switch(cloudCover) {
            case '10.':
                image = './ovc.png';
                break;
            case 'OVC':
                image = './ovc.png';
                break;
            case '8.8':
                image = './7_8.png';
                break;
            case '7.5':
                image = './bkn.png';
                break;
            case 'BKN':
                image = './bkn.png';
                break;
            case 'VV':
                image = './obscured.png';
                break;
            case '6.3':
                image = './5_8.png';
                break;
            case '5.0':
                image = './1_2.png';
                break;
            case '3.7':
                image = './3_8.png';
                break;
            case '2.5':
                image = './sct.png';
                break;
            case 'SCT':
                image = './sct.png';
                break;
            case 'SKT':
                image = './sct.png';
                break;
            case '1.2':
                image = './few.png';
                break;
            case 'FEW':
                image = './few.png';
                break;
            case '0.0':
                image = './clr.png';
                break;
            case 'SKC':
                image = './clr.png';
                break;
            case 'CLR':
                image = './clr.png';
                break;
            default:
                image = './missing.png';
                break;
        }
        return this.getIcon(scale, icons(image), 10);
    };

    getWindArrowStyle(winddir, windspeed, scale) {
        //get arrow symbol based on speed
        let iconFile = './WeatherSymbol_WMO_WindArrowNH_';
        let displacement = [-30, 8];
        if(windspeed <= 2.5) {
            iconFile = './calm';
        } else if(windspeed > 2.5 && windspeed <= 7.5) {
            iconFile = iconFile + '01';
        } else if(windspeed > 7.5 && windspeed <= 12.5) {
            iconFile = iconFile + '02';
        } else if(windspeed > 12.5 && windspeed <= 17.5) {
            iconFile = iconFile + '03';
        } else if(windspeed > 17.5 && windspeed <= 22.5) {
            iconFile = iconFile + '04';
        } else if(windspeed > 22.5 && windspeed <= 27.5) {
            iconFile = iconFile + '05';
        } else if(windspeed > 27.5 && windspeed <= 32.5) {
            iconFile = iconFile + '06';
        } else if(windspeed > 32.5 && windspeed <= 37.5) {
            iconFile = iconFile + '07';
        } else if(windspeed > 37.5 && windspeed <= 42.5) {
            iconFile = iconFile + '08';
        } else if(windspeed > 42.5 && windspeed <= 47.5) {
            iconFile = iconFile + '09';
        } else if(windspeed > 47.5 && windspeed <= 52.5) {
            iconFile = iconFile + '10';
        } else if(windspeed > 52.5 && windspeed <= 57.5) {
            iconFile = iconFile + '11';
        } else if(windspeed > 57.5 && windspeed <= 62.5) {
            iconFile = iconFile + '12';
        } else if(windspeed > 62.5 && windspeed <= 67.5) {
            iconFile = iconFile + '13';
        } else if(windspeed > 67.5 && windspeed <= 72.5) {
            iconFile = iconFile + '14';
        } else if(windspeed > 72.5 && windspeed <= 77.5) {
            iconFile = iconFile + '15';
        } else if(windspeed > 77.5 && windspeed <= 82.5) {
            iconFile = iconFile + '16';
        } else if(windspeed > 82.5 && windspeed <= 87.5) {
            iconFile = iconFile + '17';
        } else if(windspeed > 87.5 && windspeed <= 92.5) {
            iconFile = iconFile + '18';
        } else if(windspeed > 92.5 && windspeed <= 97.5) {
            iconFile = iconFile + '19';
        } else if(windspeed > 97.5 && windspeed <= 102.5) {
            iconFile = iconFile + '20';
        } else if(windspeed > 102.5 && windspeed <= 107.5) {
            iconFile = iconFile + '21';
        } else if(windspeed > 107.5 && windspeed <= 112.5) {
            iconFile = iconFile + '22';
        } else if(windspeed > 112.5 && windspeed <= 117.5) {
            iconFile = iconFile + '23';
        } else if(windspeed > 117.5 && windspeed <= 122.5) {
            iconFile = iconFile + '24';
        } else if(windspeed > 122.5 && windspeed <= 127.5) {
            iconFile = iconFile + '25';
        } else if(windspeed > 127.5 && windspeed <= 132.5) {
            iconFile = iconFile + '26';
        } else if(windspeed > 132.5 && windspeed <= 137.5) {
            iconFile = iconFile + '27';
        } else if(windspeed > 137.5 && windspeed <= 142.5) {
            iconFile = iconFile + '28';
        } else if(windspeed > 142.5 && windspeed <= 147.5) {
            iconFile = iconFile + '29';
        } else {
            iconFile = './WeatherSymbol_WMO_WindArrowMissing_99';
            displacement = [-30, 2];
        }

        let arrowImageSrc = icons(iconFile + '.png');

        if(windspeed <= 2.5) {
            return this.getIcon(scale, arrowImageSrc, 5, 'bottom-right');
        } else {
            //rotate arrow based on direction
            const iconRotationDegrees = (winddir + 90.0) > 360.0 ? (winddir + 90.0 - 360.0) : (winddir + 90.0);
            const iconRotationRadians = iconRotationDegrees * this.radianMultiplier;
            return this.getIcon(scale, arrowImageSrc, 5, 'bottom-right', displacement, iconRotationRadians);
        }
    }

    getWindGustStyle(winddir, windgust) {
        if(windgust) {
            const arrowLength = 88.0;
            let offsetX, offsetY, offsetAngleRadians;
            if(winddir <= 90.0) {
                offsetAngleRadians = (90.0 - winddir) * this.radianMultiplier;
                offsetY = Math.abs((arrowLength * Math.sin(offsetAngleRadians))) * -1;
                offsetX = Math.abs((arrowLength * Math.cos(offsetAngleRadians)));
            } else if (winddir > 90.0 && winddir <= 180.0) {
                offsetAngleRadians = (180 - winddir) * this.radianMultiplier;
                offsetX = Math.abs((arrowLength * Math.sin(offsetAngleRadians)));
                offsetY = Math.abs((arrowLength * Math.cos(offsetAngleRadians)));
            } else if (winddir > 180.0 && winddir <= 270.0) {
                offsetAngleRadians = (270.0 - winddir) * this.radianMultiplier;
                offsetY = Math.abs((arrowLength * Math.sin(offsetAngleRadians)));
                offsetX = Math.abs((arrowLength * Math.cos(offsetAngleRadians))) * -1;
            } else if (winddir <= 360.0 && winddir > 270.0) {
                offsetAngleRadians = (360.0 - winddir) * this.radianMultiplier;
                offsetX = Math.abs((arrowLength * Math.sin(offsetAngleRadians))) * -1;
                offsetY = Math.abs((arrowLength * Math.cos(offsetAngleRadians))) * -1;
            }
            return this.getLabel('bold 12', 'G ' + Math.round(windgust).toString(), 'black', offsetX, offsetY, 6);
        } else {
            return new Style({});
        }
    };
}

class staticSurfaceObsSymbology extends featureSymbology {
    constructor(features, resolution) {
        super(features, resolution);
        this.stationID = features[0].getId();
    }

    getStyle(serviceSource) {
        if(this.size > 1) {
            return this.getClusterStyle(serviceSource)
        } else {
            return this.getFeatureStyle(serviceSource)
        }
    };

    getFeatureStyle(serviceSource) {
        if(serviceSource.getFeatureById(this.stationID) || this.resolution > 3000) {
            return new Style({})
        } else {
            return this.getLabel('bold 10', this.stationID, 'gray', 0, -30, 3, 1);
        }
    };

    getClusterStyle(serviceSource) {
        let staticClusterSize = this.size;
        let serviceClusterSize = 0;
        this.features.forEach((feature) => {
            if(serviceSource.getFeatureById(feature.getId())) {
                serviceClusterSize++;
                staticClusterSize--;
            }
        })
        if(this.size === 1 && serviceClusterSize === 0) {
            return this.getFeatureStyle(serviceSource);
        } else {
            if(serviceClusterSize > 0) {
                return new Style({})
            } else {
                return [
                    new Style({
                        image: new Circle({
                            radius: 20,
                            fill: new Fill({ color: 'rgba(145, 145, 145, 0.3)' })
                        })
                    }),
                    new Style({
                        image: new Circle({
                            radius: 14,
                            fill: new Fill({ color: 'rgba(145, 145, 145, 0.8)' })
                        }),
                        text: new Text({
                            text: staticClusterSize.toString(),
                            fill: new Fill({ color: '#fff' }),
                            stroke: new Stroke({
                                color: 'rgba(0, 0, 0, 0.6)',
                                width: 3
                            })
                        })
                    })
                ]
            }
        }
    };
}

class COOPSSymbology extends featureSymbology {
    constructor(features, resolution) {
        super(features, resolution);
    }

    getFeatureStyle() {
        return this.getIcon(0.5, icons('./tide_station.png'), 5)
    }

    getClusterStyle() {
        return [
            new Style({
                image: new Circle({
                    radius: 20,
                    fill: new Fill({ color: 'rgba(0, 0, 128, 0.3)' })
                })
            }),
            new Style({
                image: new Circle({
                    radius: 14,
                    fill: new Fill({ color: 'rgba(0, 0, 128, 0.7)' })
                }),
                text: new Text({
                    text: this.size.toString(),
                    fill: new Fill({ color: '#fff' }),
                    stroke: new Stroke({
                        color: 'rgba(0, 0, 0, 0.6)',
                        width: 3
                    })
                })
            })
        ]
    }
}

export {featureSymbology, surfaceObsSymbology, staticSurfaceObsSymbology, COOPSSymbology};