import React from "react";
import { Typography, CircularProgress } from '@mui/material';
import LayerMenuItem from './layer-menu-item.js';
import LayerOptionsContainer from './layer-options-container.js';
import LightningDensityLegend from '../legend-menu-items/ltng-den-legend.js';

const classes = {
    menuItemBodyContainer: {
        padding: '0.5em',
    },
};

/**
* LightningDensityMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) lightningDensityActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setLightningDensityActive - callback for setting LightningDensityActive
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (react component) capUrlsContent - component containing content to display under capUrls tab
*/
function LightningDensityLayerMenuItem(props){

    return (
        <LayerMenuItem
            layerName={"ltng_den"}
            label={"Lightning Detection"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.lightningDensityActive}
            setLayerIsActive={props.setLightningDensityActive}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"ltng_den"}
                    layersList={props.layersList}
                    infoContent={<Typography variant="caption" style={{display: 'inline-block'}}>
                     The lightning strike density data (number of lightning strikes over a given time 
                     period within a box of fixed horizontal resolution) include both cloud-to-ground 
                     strikes, cloud-to-ground flash information and survey level cloud lightning information 
                     from the U.S. National Lightning Detection Network (NLDN) and the Global Lightning 
                     Detection Network (GLD360). The time period is 15 minutes and the horizontal resolution 
                     is 8 x 8 km (5 x 5 miles), similar to the resolution of the GOES Global Lightning 
                     Mapper. The data extends from the western South Pacific to north of Scotland. The 
                     15-minute gridded source data are updated at NWS/Ocean Prediction Center (OPC) every 
                     15 minutes at 10 minutes past the valid time. The purpose of this product is to provide 
                     mariners and others with enhanced "awareness of developing and transitory thunderstorm 
                     activity, to give users the ability to determine whether a cloud system is producing 
                     lightning and if that activity is increasing or decreasing…”. Source: NWS/NCEP/OPC
                    </Typography>}
                    legendContent={
                        props.styleInfo ?
                            <LightningDensityLegend
                            LightningDensityStyleInfo={props.styleInfo}
                            />
                        : <CircularProgress />
                    }
                    capUrlsContent={props.capUrlsContent}
                />
            </div>
        </LayerMenuItem>
    );
}

export default LightningDensityLayerMenuItem;