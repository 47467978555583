import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, Typography, useMediaQuery, IconButton, Grow, Paper, ClickAwayListener, Grid} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoRounded';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';


// JSS classes for styling non-MUI components
const classes = {
    headerContainer : {
        position: 'absolute',
        top: '0.75em',
        left: '4em',
        width: '16em',
    },
    noaaLogo: {
        maxHeight: '3em',
        marginRight: '0.5em',
        boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 1)',
        userSelect: 'none',
        borderRadius: '50%',
        display: 'block',
        float: 'left',
    },
    titleText: { // wraps title and prototype label
        flex: 1,
        color: "#fff",
        textShadow: "-1px 0 3px #222, 2px 2px 3px #222, 1px 0 2px #222, 0 -1px 2px #222", // multiple shadow offsets for darker outline
        userSelect: 'none',
        fontSize: '100%',
        position: 'relative',
        bottom: '-0.1em',
    },
    prototypeLabel: {
        fontSize: '60%',
        color: '#FFEB3B',
        marginLeft: '0.25em',
        textShadow: "0 0 3px black", // no offset for shadow text, just a glow around the edge of the text
        userSelect: 'none',
        fontStyle: 'italic',
        position: 'absolute',
        left: '2.95em',
        bottom: '-0.2em',

    },
};

function Header(props) {
    const theme = useTheme();

    // Handle style overrides and content changes breakpoints
    let banner = null;
    let siteInfoMenuComponent = null;
    let headerContainerStyle = classes.headerContainer;
    let noaaLogoStyle = classes.noaaLogo;

    if (useMediaQuery(theme.breakpoints.down('lg'))) { // sm, md  (.down is exclusive) (xs is overwritten below)
        banner = <Typography variant="h5">
            <span style={{...classes.titleText, fontSize: '120%',  bottom: '-0.1em'}}>{props.title}</span>
        </Typography>;

        noaaLogoStyle = {
            ...classes.noaaLogo,
            maxHeight: '2.75em',
            marginRight: '0.5em',
            marginLeft: '-1em',
        };

        headerContainerStyle = {
            ...classes.headerContainer,
            width: '12.5em',
        };
    }

    if (useMediaQuery(theme.breakpoints.up('lg'))) { // lg, xl (.up is inclusive)

        banner = <Typography variant="h4">
            <span style={classes.titleText}>{props.title}</span>
        </Typography>;
    }

    if (useMediaQuery(theme.breakpoints.only('xs'))) { // xs
        banner = <Typography variant="h6">
            <span style={{...classes.titleText, fontSize: '110%', bottom: '-0.1em'}}>{props.title}</span>
        </Typography>;

        noaaLogoStyle = {
            ...classes.noaaLogo,
            maxHeight: '2.5em',
            marginRight: '0.5em',
            marginLeft: '-0.5em',
        };

        headerContainerStyle = {
            ...classes.headerContainer,
            left: '1em',
            top: '0.25em',
            width: '10em',
        };
    }

    return (
        <div style={{
            zIndex: props.zIndexVal,
            ...headerContainerStyle
        }}>
            <img style={noaaLogoStyle} alt="NOAA" src={require("../images/noaa_logo_small_alpha_cropped.png")} />
            {banner}
            {siteInfoMenuComponent}
        </div>
    );
}

function NCHeader(props) {
    return (
        <Header
            title="nowCOAST"
            subtitle="Latest Marine Weather and Coastal Information"
            zIndexVal={props.zIndexVal}
        />
    );
}

export default NCHeader;
