import React, { useEffect, useRef, useState } from "react";
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import OLMapContext from "./map-context.js";
import OLLayerManager from '../layers/layer-manager.js';
import TimeControl from '../time-slider/time-control.js';

import 'ol/ol.css';
import { fromLonLat } from "ol/proj";

import eventListeners from "../../utilities/event-listeners.js";

///*****************************************************************************/
//// what if we made the map live in a hook with useReducer...
//
//
//
////function reducer(state, action) { // just a function, could be named anything but it must return a new state based on the action (action can be an object)
//  function reducer(map, action) {
//  // so in this case we will say action is an object like:
//  // { type: "action_to_do",
//  //   value: "value_to_use_if_needed" }
//  //
//  switch (action.type) {
//    case 'addLayer':
//      map.addLayer(action.value);
//      return map;
//    case 'removeLayer':
//      map.addLayer(action.value);
//      return map;
//    case 'changeCenter':
//      map.getView().setCenter(fromLonLat(action.value));
//      return map;
//    case 'changeZoom':
//      map.getView().setZoom(action.value);
//      return map;
//    default:
//      throw new Error();
//  }
//}
//
//function SomeComponent() {
//  //const [state, dispatch] = useReducer(reducer, initialState); // This is where we would create the map, passing it as the initial state
//  const [map, dispatch] = useReducer(reducer, new Map(...));
//  return ( // 99% sure dispatch is the same as setState functions, and can probly be called anything
//    <>
//      Count: {state.count}
//      <button onClick={() => dispatch({type: 'changeZoom', value: 22})}>-</button> // so this dispatch would change the map zoom to 22
//      <button onClick={() => dispatch({type: 'increment'})}>+</button>
//    </>
//  );
//  //but children would need the map from a context and also the dispatcher from context.... altho in the maps case... can we just alter it wherever we have it?
//  // really why use a reducer at all, if we could just place the map in useState at the top and pass it around with a context
//  // we could just alter it as we wish with no callback functions... IF all we are doing is passing around a reference to a persistent map object
//}
//
///******************************************************************************/

/**
* React wrapper for OL map object
*
* @prop (obj) map - Stable instance of OL map to use
* @prop (obj) children - react jsx passed to OLMap invocation
* @prop ([float, float]) - center of map viewer
* @prop (int) zoom - zoom level of viewer
* @prop (obj) layerConfig - complete LAYERS obj from app/config.js containing layer configurations
* @prop (obj) productToggles - contains layer group names (products) mapped to true/fales values for on/off
* @prop (obj) initializedCaps - contains initialization status of datasets that must wait on Get Capabilities requests to be enabled
* @prop (func) setInitializedCaps - setter function  for initializedCaps
* @prop (obj) timeValues - maps toggle-able animation layers objects that map layer names to their list
*                          of time values from capabilities
* @prop (func) updateTimeValues - setter function for timeValues
* @prop (func) updateStyleInfo - setter function for styleInfo
* @prop (obj) layerOpacities - maps layer group names to opacity values (0-100)
* @prop (obj) customLayerInfo - All info related to user-added layers
* @prop (bool) mapClickPopupOn - (optional) true if map-click popup is open, else false
* @prop (obj) olLayerState - maps ol layer names to obj containing "on" state as well as "layersParam", "stylesParam", and "currentSource"
* @prop (func) updateOlLayerState - callback for updating olLayerState
* @prop (bool) refreshLayers - Use to trigger the re-adding of all layers to the map when toggled
* @prop (obj) capHandlers - Contains all capability handlers for each product
*
*/


const DivMapContainer = styled("div")({
    flex: 1,
    position: 'relative',
    height: '100%',
    width: '100%',
});

// TODO: Restore or otherwise improve behavior/appearance of OpenLayers map elements following move to v9
const DivOlMapWindow = styled("div")({
    position: 'absolute',
    height: '100%',
    width: '100%',
    margin: 0,
    padding: 0,
    '& .ol-control': {
        '& button': {
            backgroundColor: 'rgba(0,0,0,0.5)',
            color: 'rgba(255,255,255)',
            boxShadow: '0px 0px 2px 2px rgba(255, 255, 255, 0.3)',
        },
    },
    '& .ol-scale-line': {
        backgroundColor: 'rgba(0,0,0,0.5)',
        bottom: '0.1em',
    },
    '& .ol-scale-line-inner': {
        color: 'rgba(255,255,255)',
        fontWeight: 'bold',
    },
    '& .ol-attribution': {
        bottom: 0,
    },
});

const OLMap = (props) => {
    const theme = useTheme();

    // Persists target element to place map in
	const mapElement = useRef(null);

    // Store current Time Dimension State (proper time val for all sources in app based on selected time in time slider)
    const [timeState, setTimeState] = useState(null);

    // Store union of all possible time values across all sources based on latest known capabilities
    const [timeValuesUnion, setTimeValuesUnion] = useState(null);

	// componentDidMount / Initialize Map (runs once after first render)
	useEffect(() => {
	    if (!props.map) return;
        props.map.setTarget(mapElement.current);

        // Register Listeners for Cap Handlers
        props.capHandlers.forEach((capHandler) => {
            if (capHandler.events.includes('capabilitiesUpdated')) {
                capHandler.handler.addEventListener('capabilitiesUpdated', eventListeners.capabilitiesUpdated(props.timeStateController.updateCapabilities, props.updateTimeValues));
            }
            if (capHandler.events.includes('stylesUpdated')) {
                capHandler.handler.addEventListener('stylesUpdated', eventListeners.stylesUpdated(props.updateStyleInfo));
            }
            if (capHandler.events.includes('infoUpdated')) {
                capHandler.handler.addEventListener('infoUpdated', eventListeners.infoUpdated(props.updateProductInfo));
            }
        });

        // Register Listeners for Time Dimension Controller
        props.timeStateController.addEventListener('timeValuesUpdated', eventListeners.timeValuesUpdated(setTimeValuesUnion));
        props.timeStateController.addEventListener('dimensionStateUpdated', eventListeners.dimensionStateUpdated(setTimeState));

        // Make a callback out of this...
        // Init Capabilities
        props.capHandlers.forEach(async (capHandler) => {
            await capHandler.handler.getCapabilities();
        });

        // componentWillUnmount (returned cleanup func will run before unmounting)
		return () => props.map.setTarget(undefined);
	}, [props.map, props.capHandlers, props.timeStateController, props.updateTimeValues, props.updateStyleInfo]);

	// Handle change to zoom/center
	useEffect(() => {
		if (!props.map) return;
        props.map.getView().setCenter(fromLonLat(props.center));
		props.map.getView().setZoom(props.zoom);
	}, [props.center, props.zoom, props.map]);

    //remove zoom control for cellphones
    if (useMediaQuery(theme.breakpoints.only('xs'))) {
        props.showZoom(false);
    } else {
        props.showZoom(true);
    }

	return (
		<OLMapContext.Provider value={ props.map }>
            <DivMapContainer>
                <DivOlMapWindow ref={mapElement}>
                    {props.children}
                    <OLLayerManager
                        map={props.map}
                        layerConfig={props.layerConfig}
                        layerToggles={props.productToggles}
                        dimensionState={timeState}
                        initializedCaps={props.initializedCaps}
                        setInitializedCaps={props.setInitializedCaps}
                        layerOpacities={props.layerOpacities}
                        customLayerInfo={props.customLayerInfo}
                        olLayerState={props.olLayerState}
                        refreshLayers={props.refreshLayers}
                    />
                    <TimeControl
                        timeValues={props.timeValues}
                        updateTimeValues={props.updateTimeValues}
                        updateTime={props.timeStateController ? props.timeStateController.updateState : null}
                        timeValuesUnion={timeValuesUnion}
                        zIndexVal={props.zIndexes.animation_control}
                        productToggles={props.productToggles}
                        updateSelectedTime={props.updateSelectedTime}
                        mapClickPopupOn={(props.mapClickPopupOn) ? props.mapClickPopupOn : false}
                        olLayerState={props.olLayerState}
                        capHandlers={props.capHandlers}
                    />
                </DivOlMapWindow>
            </DivMapContainer>
		</OLMapContext.Provider>
	);
}

export default OLMap;
