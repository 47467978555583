import React, { useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';

import MRMSQPELegend from '../menu/legend-menu-items/mrms-qpe-legend.js';


const red = '192';
const classes = {
    qpeFeatureInfoContainer: {
        padding: '10px',
    }
};

const sxStyles = {
    featureInfoHeader: {
        fontSize: '0.9em',
        mb: '0.5em'
    },
    featureInfoText: {
        fontSize: '0.9em',
    },
    noDataMessage: {
        fontSize: '0.9em',
        m: '2em 2em'
    },
};


// Note the region has been removed from idp_subset
const IDP_SUBSETS_TO_TIME_PERIODS = {
    "mrms_01h": "1 hours",
    "mrms_03h": "3 hours",
    "mrms_06h": "6 hours",
    "mrms_12h": "12 hours",
    "mrms_24h": "24 hours",
    "mrms_48h": "48 hours",
    "mrms_72h": "72 hours",
};

const IDP_SUBSETS_TO_REGIONS = {
    "co": "Continental US",
    "ak": "Alaska",
    "hi": "Hawaii",
    "pr": "Puerto Rico"
};

/**
* Component for rendering results for external mrms qpe arcgis service
*
* @prop (obj) data - json response obj from /identify request
* @prop (obj) styleInfo - contains info about styles/legends parsed from capabilities
* @prop (obj) olLayerState - maps ol layer names to obj containing on, layerParams, styleParams, and currentSources
*
* Notes on interpreting /Indetify responses (props.data):
*   Because there are 4 possible regions we make 4 requests, one for each region.
*   (This was presumed to be more simple than maintaining region polygons and checking which
*   region the click falls (avoids latency of extra requests but computationally complex))
*   So this component must check all 4 json responses in props.data and find the one that worked (see below)
*
*   When clicking a no data point within the requested region procesedValues tends to be "1" but
*   value tends to be 0 or -X. So this uses value <= 0 to detect no data within a proper region
*   when clicking outside of the requested region then both processedValues and value show "NoData"
*   So this uses value == "NoData" to detect if the data being examined is for the region that was
*   clicked or not
**/
export default function MRMSQPEFeatureInfo(props) {
    let dataLoading = true;
    let noDataCount = 0;
    let errorCount = 0;
    let conusDataFound = false;

    const displayData = Object.entries(props.data).map(([region, info],i) => {
        // DEBUG
        // console.log("Region/Subset is:", region, "Identify results are:", info)
        if (info && !info.error ) {
            if (info.value !== "NoData" && info.value >= 0.00) { // NWS confirmed that negative value means no data
                dataLoading = false;
                // Always prioritize the display of conus ("co") data over other regions that might return data for the same point
                if (conusDataFound) {
                    return(null);
                }
                if (region === "co") {
                    conusDataFound = true;
                }

                // Identify raster function (time period) used for this data: //catalogItems":{"objectIdFieldName":"objectid","features":[{"attributes":{"objectid":165559,"name":"MRMS_RadarOnly_QPE_01H_co.latest.grib2:1","category":1,"idp_subset":"mrms_01h_co"
                const subset = info.catalogItems.features[0].attributes.idp_subset;

                let estimate = (info.value * 0.03937008).toFixed(2); // Convert mm to inches
                let estimateMillimeters = info.value;

// Code for showing the color that was clicked - currently disabled
//                let rgbaVal = "rgba(0,0,0,0)";
//                if (estimate > 0) {
//                    // Identify current raster function from the subset
//                    const period = IDP_SUBSETS_TO_TIME_PERIODS[subset.slice(0, -3)].split(' ')[0];
//                    const rasterFunc = "rft_" + period + "hr";
//
//                    for (let i=0; i < props.styleInfo.ratFeatures[rasterFunc].length; i++) {
//                        // If on last attribute which says "Above X" don't parse just use it
//                        let onLastVal = true;
//                        let upperBound = null;
//                        let lowerBound = null;
//                        if (i !== props.styleInfo.ratFeatures[rasterFunc].length-1) {
//                            onLastVal = false;
//                            lowerBound = parseFloat(props.styleInfo.ratFeatures[rasterFunc][i].attributes.ClassName.slice(0,4));
//                            upperBound = parseFloat(props.styleInfo.ratFeatures[rasterFunc][i].attributes.ClassName.slice(7,10));
//                        }
//
//                        if (onLastVal || (estimate >= lowerBound && estimate < upperBound)) {
//                            const redVal = props.styleInfo.ratFeatures[rasterFunc][i].attributes.Red;
//                            const greenVal = props.styleInfo.ratFeatures[rasterFunc][i].attributes.Green;
//                            const blueVal = props.styleInfo.ratFeatures[rasterFunc][i].attributes.Blue;
//                            const alphaVal = props.styleInfo.ratFeatures[rasterFunc][i].attributes.Alpha;
//
//                            rgbaVal = "rgba(" + redVal + "," + greenVal + "," + blueVal + "," + alphaVal + ")";
//                            break;
//                        }
//                    }
//                }

                return(
                    <div style={classes.qpeFeatureInfoContainer} key={i}>
                        <Typography sx={sxStyles.featureInfoText}>Total Precipitation: {estimate} inches ({parseFloat(estimateMillimeters).toFixed(2)} mm)</Typography>
                        <Typography sx={sxStyles.featureInfoText}>Period: Past {IDP_SUBSETS_TO_TIME_PERIODS[subset.slice(0, -3)]}</Typography>
                    </div>
                );
            } else {
                noDataCount = noDataCount + 1;
            }
        } else if (info && info.error) {
            errorCount = errorCount + 1;
            return(null);
        } else {
            return(null);
        }

    });

    // If all four regions say "NoData" (Should be rare. Maybe if user clicks outside all regions or their service is broken"
    if (noDataCount >= 4) {
        return(<Typography sx={sxStyles.noDataMessage}>There are no MRMS QPE analysis estimates at this location.</Typography>)
    }

    if (errorCount >= 4) {
        return(
            <div style={classes.qpeFeatureInfoContainer}>
                <Typography sx={sxStyles.featureInfoText}>{props.data.co.error.message}</Typography>
                <Typography sx={sxStyles.featureInfoText}>{props.data.co.error.details[0]}</Typography>
            </div>
        )
    }

    if (dataLoading) {
        return(
            <CircularProgress sx={{ml: "10em", mt: "10px"}}/>);
    }

    return(
        <div style={classes.qpeFeatureInfoContainer}>
            <Typography sx={sxStyles.featureInfoHeader}>MRMS Quantitative Precipitation Estimates (QPE)</Typography>
            <div style={{
                borderBottom: '1px solid rgba(0, 0, 0, .125)',
                marginLeft: 10, marginRight: 10,
                }}
            >
            </div>
            {displayData}
            { props.styleInfo ?
                <MRMSQPELegend
                    MRMSQPEStyleInfo={props.styleInfo}
                    olLayerState={props.olLayerState}
                    hideHeader={true}
                />
            : <CircularProgress /> }
        </div>
    );

}