import WMSCapabilities from 'wms-capabilities';

import logger from './logger.js';
import eventManager from './event-manager.js';

// TEMPORARY
import { WMS_SERVICE_TITLES } from '../config.js';

// Define the maximum amount of hours of observation data (sat & radar) that the viewer should parse
const MAX_OBSERVATION_HOURS = 4;

/**
* Handles get requests for a set of capabilities at given url. Automatically requests new capabilities at
* regular intervals specified by capRequestInterval
*
* @member {string} _capUrl - getCapabilities url to retrieve capabilities from (base url, not full request url)
*   ex. "http://noaa.whatever.gov/geoserver/ofs/wms"
* @member {obj} _capabilities - contains latest known capabilities for given url
* @member {int} _capRequestInterval - interval for repeating getCapabilities requests
* @member {obj} _getCapabilitiesInterval - setInterval object that calls getCapabilities
* @member {obj} _styleInfo - container for latest know style info
* @member {int} _snapThreshold - snap threshold for time dimension (used with time slider/dimension controller)
* @member {array} _styleLayerNames - list of names of layers which contain styles to be parsed (for each product this
*                                  is defined in config.js)
*/

class WMSCapabilitiesHandler {

    /**
    * @param {string} product - name of product to collect capabilities for (should correspond to top level key
    *                           of LAYERS in config.js)
    * @param {array} capUrls - list of url/paths to collect capabilities from for this product
    * @param {int} capRequestInterval - interval in milliseconds between background getCapabilities requests
    * @param {int} snapThreshold - length of time between app's selected time and the closest data point beyond which,
    *                              the data should no longer be displayed (epoch milliseconds)
    *                              ie. if snapThreshold is 1 hour, then if there is no datapoints within 1 hour of selected
    *                              time, then that dataset will not display anything even if it is enabled
    * @param {array} styleLayerNames - list of names of layers which contain styles to be parsed (for each product this
    *                                  is defined in config.js)
    */
    constructor(product, capUrls, capRequestInterval, snapThreshold, styleLayerNames) {
        this._product = product;
        this._capUrls = capUrls;
        this._capabilities = {[this._product]: {}};
        this._styleInfo = {[this._product]: {}};
        this._productInfo = {[this._product]: {
            keywords: [],
            wmsCapUrls: []
        }};
        this._getCapabilitiesInterval = null;
        this._capRequestInterval = capRequestInterval;
        this._snapThreshold = snapThreshold;
        this._styleLayerNames = styleLayerNames;
        Object.assign(this, eventManager);
        //this.supportedDimensions = ['time', 'dim_time_reference']; //maybe? currently not used
    }

    /**
    * getCapabilities function makes getCapabilities request and parses relevant data into usable
    * object for storing and sending with capabilitiesUpdated event
    *
    * @params none
    * @returns none
    *
    * THIS METHOD REQUIRES THAT IT IS CALLED AS A PROMISE/ASYNC-AWAIT
    * (it is async here as well for background calls from setInterval)
    */
    async getCapabilities() {

        for (let i=0; i < this._capUrls.length; i++) {

            // Note: This URL is not the one we officially advertise. We must pull the right one from capabilities
            // but this will remain as a failsafe in case the parse fails so that the parse can continue
            let url = this._capUrls[i] + "?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetCapabilities";

//            //DEBUG
//            console.log ("[capabilities-handler-wms][getCapabilities] Getting capabilities with WMSCapabilitiesHandler at: " + url);

            const response = await fetch(url);
            const data = await response.text();
            const wmsCapObj = new WMSCapabilities();
            const capabilitiesXML = wmsCapObj.parse(data);

            try {
                // Parse cap urls to display in menu if available
                if (capabilitiesXML.Capability.Request.GetCapabilities.DCPType[0].HTTP.Get.OnlineResource) {
                    url = capabilitiesXML.Capability.Request.GetCapabilities.DCPType[0].HTTP.Get.OnlineResource + "VERSION=1.3.0&REQUEST=GetCapabilities";
                    let currentUrls = this._productInfo[this._product].wmsCapUrls.map((urlInfo) => {
                        return(urlInfo.url);
                    });
                    if (!currentUrls.includes(url)) {
                        if (capabilitiesXML.Service.Title) {
                            // NOTE: Temporarily disabling parse of title while we fix services. Using hard coded list in the meantime.
                            //this._productInfo[this._product].wmsCapUrls.push({'url': url, 'title': capabilitiesXML.Service.Title}); // this is the only line originally
                            if (WMS_SERVICE_TITLES[this._capUrls[i]]) {
                                this._productInfo[this._product].wmsCapUrls.push({'url': url, 'title': WMS_SERVICE_TITLES[this._capUrls[i]]});
                            } else {
                                this._productInfo[this._product].wmsCapUrls.push({'url': url, 'title': capabilitiesXML.Service.Title});
                            }

                        }
                    }
                }

                if (url.includes('ofs/')) { // S111 --------------------------- S111 ----------------------------------- S111

                    // For parsing info/metadata (keywords)
                    let tmpProductInfo = {
                        keywords: [],
                    };

                    capabilitiesXML.Capability.Layer.Layer.forEach(layer => {
                        // Getting latest reference time for forecast
                        const timeRefs = layer.Dimension[1].values.split(",");
                        const latestRefTime = timeRefs[timeRefs.length-1];
                        // Get all forecast times following latest reference time
                        const refTime = new Date(latestRefTime);
                        const forecastTimes = layer.Dimension[0].values.split(",");
                        let startTimeIndex = 0;
                        for (let i = 0; i < forecastTimes.length; i++) {
                            const tmpTime = new Date(forecastTimes[i]);
                            if (tmpTime > refTime) {
                                startTimeIndex = i;
                                break;
                            }
                        }

                        const validForecastTimes = forecastTimes.slice(startTimeIndex);

                        // DEBUG
                        // console.log("Updating S111 capabilities for " + layer.Name + " with ref time: " + latestRefTime + " and time values: " + validForecastTimes);

                        //Build capabilities object to send out
                        const tmpCaps = {
                            [layer.Name] : {
                                dimensions: {
                                    time: validForecastTimes,
                                    dim_time_reference: [latestRefTime]
                                },
                                layerGroup: this._product, // Must match top level key of LAYERS in config (consider refactoring to inject a better way)
                                snapThreshold: this._snapThreshold
                            }
                        }

                        Object.assign(this._capabilities[this._product], tmpCaps);

                        // Parse keywords
                        tmpProductInfo.keywords = tmpProductInfo.keywords.concat(layer.KeywordList);

                        // Parsing styles: layer must match names found in this._styleLayerNames (originally defined in config.js)
                        if (this._styleLayerNames.indexOf(layer.Name) !== -1) { // current layer has style/legend info that is needed
                            let tmpStyleInfo = null;
                            for (let styleInfo of layer.Style) {
                                const styleEntry = {
                                    name: styleInfo.Name,
                                    title: styleInfo.Title,
                                    format: styleInfo.LegendURL[0].Format,
                                    width: styleInfo.LegendURL[0].size[0],
                                    height: styleInfo.LegendURL[0].size[1],
                                    url: styleInfo.LegendURL[0].OnlineResource
                                };
                                if (tmpStyleInfo === null) {
                                    tmpStyleInfo = Object.assign({}, {[layer.Name] : [styleEntry]});
                                } else {
                                    tmpStyleInfo[layer.Name].push(styleEntry);
                                }
                            }
                               Object.assign(this._styleInfo.s111, tmpStyleInfo);
                        }
                    });

                    // Remove duplicates from keywords array and store new keywords in obj (merged with pre-existing keywords)
                    // Note: This deduplicates keywords among different layers within the same service
                    // as well as any keywords already stored while parsing different services for the same product
                    tmpProductInfo.keywords = [...new Set(tmpProductInfo.keywords.concat(this._productInfo[this._product].keywords))];
                    Object.assign(this._productInfo[this._product], tmpProductInfo);

                    // ADDRESS ISSUE: you dont want to dispatch caps (times) unless they have changed, so maybe store previous capabilities
                    // and compare to see if new ones are different

                } else if (url.includes('/s100/')) { // S100 --------------------------- S100 ----------------------------------- S100

                  // For parsing info/metadata (keywords)
                    let tmpProductInfo = {
                        keywords: [],
                    };

                    capabilitiesXML.Capability.Layer.Layer.forEach(layer => {
                        if (this._styleLayerNames.indexOf(layer.Name) !== -1) { // current layer has legend info that is needed
                            let tmpStyleInfo = null;
                            for (let styleInfo of layer.Style) {
                                const styleEntry = {
                                    name: styleInfo.Name,
                                    title: styleInfo.Title,
                                    format: styleInfo.LegendURL[0].Format,
                                    width: styleInfo.LegendURL[0].size[0],
                                    height: styleInfo.LegendURL[0].size[1],
                                    url: styleInfo.LegendURL[0].OnlineResource
                                };
                                if (tmpStyleInfo === null) {
                                    tmpStyleInfo = Object.assign({}, {[layer.Name] : [styleEntry]});
                                } else {
                                    tmpStyleInfo[layer.Name].push(styleEntry);
                                }
                            }
                            Object.assign(this._styleInfo[this._product], tmpStyleInfo);
                        }

                        tmpProductInfo.keywords = tmpProductInfo.keywords.concat(layer.KeywordList);
                    });


                    // Remove duplicates from keywords array and store new keywords in obj
                    tmpProductInfo.keywords = [...new Set(tmpProductInfo.keywords)];
                    Object.assign(this._productInfo[this._product], tmpProductInfo);

                    // Note: Static dataset no time values

            } else if (url.includes('/ndfd_')) { // NDFD --------------------------- NDFD ----------------------------------- NDFD

                    // For parsing info/metadata
                    let tmpProductInfo = {
                        keywords: [],
                    };

                    // Parse time values (only for conus - requests will be made to layer group with conus times)
                    capabilitiesXML.Capability.Layer.Layer.forEach(layerGroup => {
                        layerGroup.Layer.forEach(layer => {
                            if (!layer.Name.includes('conus_')) {
                                return
                            }

                            const validForecastTimes = layer.Dimension[0].values.split(",");

                            const tmpCaps = {
                                [layerGroup.Name] : {
                                    dimensions: {
                                        time: validForecastTimes,
                                    },
                                    layerGroup: this._product, // ToDo: See what layerGroup is used for and see if we still need it now that the top key is product (same as layerGroup)
                                    snapThreshold: this._snapThreshold
                                }
                            }
                            Object.assign(this._capabilities[this._product], tmpCaps);

                            // Parsing styles: layer must match names found in this._styleLayerNames (originally defined in config.js)
                            if (this._styleLayerNames.indexOf(layer.Name) !== -1) { // current layer has style/legend info that is needed
                                let tmpStyleInfo = null;
                                for (let styleInfo of layer.Style) {
                                    //let styleInfo = layer.Style[0]; // required one style per layer
                                    const styleEntry = {
                                        name: styleInfo.Name,
                                        title: styleInfo.Title,
                                        format: styleInfo.LegendURL[0].Format,
                                        width: styleInfo.LegendURL[0].size[0],
                                        height: styleInfo.LegendURL[0].size[1],
                                        url: styleInfo.LegendURL[0].OnlineResource
                                    };
                                    if (tmpStyleInfo === null) {
                                        tmpStyleInfo = Object.assign({}, {[layer.Name] : [styleEntry]});
                                    } else {
                                        tmpStyleInfo[layer.Name].push(styleEntry);
                                    }
                                }
                                Object.assign(this._styleInfo.ndfd, tmpStyleInfo);
                            }

                            // Parsing info/metadata (keywords)
                            tmpProductInfo.keywords = tmpProductInfo.keywords.concat(layerGroup.KeywordList);
                        });
                    });

                    // Remove duplicates from keywords array and update productInfo
                    tmpProductInfo.keywords = [...new Set(tmpProductInfo.keywords.concat(this._productInfo[this._product].keywords))];
                    Object.assign(this._productInfo[this._product], tmpProductInfo);

                } else if (url.includes('/weather_radar/')) { // MRMS (weather_radar) --------------------------- MRMS (weather_radar) ----------------------------------- MRMS (weather_radar)
                    // Service Refactoring Notes:
                    // Changes:
                    // 1. service name went from mrms -> weather_radar
                    // 2. geoserver_layer_group name went from mrms_basereflect to base_reflectivity_mosaic
                    // 3. top-level key for this._styleInfo COULD HAVE changed from mrms -> weather_radar BUT DID NOT
                    //    The question here is: Is that key representing the product name or service name?
                    //    Before, the key was both the product and service name (mrms). I tried weather_radar instinctual
                    //    but mrms key is used downstream. Is it actually worth changing? Especially since we expect to
                    //    maintain an emphasis on product name to handle changes to ndfd and ofs
                    // 4. The iterative parse (using forEach) was pointless and only serviced to obfuscate the code
                    //    Sure it leans closer to better programming practices but its worse to hide a hard-coded parse
                    //    in a loop that only runs once. So the PARSE HAS BEEN REWRITTEN to be completely straightforward
                    //    and readable. It is just also requires that the capabilities NEVER CHANGE. This is not ideal
                    //    but as we being the real refactoring it will be helpful to simply see what data this parse needs
                    //    and where it is found in the xml. We will improve this later in the refactoring process as needed

                    // MRMS uses Geoserver Layer Group for the layer parameter in requests. Times are parsed from the first`
                    // sub-layer in the group. Although the times do not match for all sublayers, they are close enough that
                    // one time list can serve for the layer group. Geoserver takes care of it with its own time snapping.
                    // Style/Legend info is also parsed from the first sub-layer in the layer group. This is valid because
                    // all sub-layers link to the same legend image for mrms. Also the style url for the layer group does
                    // not return a valid legend image so we cannot use it.

                    // Parse keywords (only needed from one layer. No need to deduplicate)
                    let tmpProductInfo = {
                        keywords: [...capabilitiesXML.Capability.Layer.Layer[0].Layer[0].KeywordList],
                    };
                    Object.assign(this._productInfo[this._product], tmpProductInfo);

                    const timeVals = capabilitiesXML.Capability.Layer.Layer[0].Layer[0].Dimension[0].values.split(",");
                    //NOTE: this pulls time from just one sub-layer to be used for all layers via the layer group as described
                    //above. Do our customers know about this? Will they have trouble understanding how to parse to use the
                    //layer group?

                    // Shorten timeVals list to where it only contains the last 4 hours of data
                    let latestTimeVal = new Date(timeVals[timeVals.length-1]);
                    let earliestTimeVal = latestTimeVal.setHours(latestTimeVal.getHours() - MAX_OBSERVATION_HOURS);
                    let truncatedTimeVals = [];
                    for (let i = 0; i < timeVals.length; i++) {
                        let curTimeVal = new Date(timeVals[i]);
                        if (curTimeVal <= earliestTimeVal) continue;
                        truncatedTimeVals.push(timeVals[i]);
                    }

                    //Build capabilities object to send out
                    const tmpCaps = {
                        [capabilitiesXML.Capability.Layer.Layer[0].Name] : { // This is the Geoserver Layer Group's name
                            dimensions: {
                                time: truncatedTimeVals,
                            },
                            layerGroup: this._product, // This arbitrary key/value must match top level key of LAYERS in config (consider calling this key "product" so as not to confuse with geoserver layer groups)
                            snapThreshold: this._snapThreshold // Snap threshold for mapviewer from config.js (Nothing to do with geoserver time snapping)
                        }
                    };
                    Object.assign(this._capabilities[this._product], tmpCaps);

                    // Parsing style info: Parsed from the first sub-layer within the geoserver layer group
                    let styleInfo = capabilitiesXML.Capability.Layer.Layer[0].Layer[0].Style[0];

                    // Currently we use the sub-layer name as a key in this object which points to
                    // the object containing the style info. However, the legend component needs to know this
                    // key and that has required hard coding of an obscure piece of information.
                    // ToDo: come up with a solution. Tropical cyclones passes the layer names in which are used as the key
                    // perhaps this case can be covered in a similar way using currentSources...
                    const tmpStyleInfo = {
                        [capabilitiesXML.Capability.Layer.Layer[0].Layer[0].Name] : [
                            {
                                name: styleInfo.Name,
                                title: styleInfo.Title,
                                format: styleInfo.LegendURL[0].Format,
                                width: styleInfo.LegendURL[0].size[0],
                                height: styleInfo.LegendURL[0].size[1],
                                url: styleInfo.LegendURL[0].OnlineResource
                            }
                        ]
                    };

                    Object.assign(this._styleInfo[this._product], tmpStyleInfo); //NOTE THE HARDCODE KEY HERE (mrms) which must match the key created above
                    // This key name is discussed in #3 in Service Refactoring Notes above

                    // ADDRESS ISSUE: you dont want to dispatch caps unless they have changed, so maybe store previous capabilities
                    // and compare to see if new ones are different (this is an old comment from earliest version of the react map
                    // it is still valid. The comparison here would be significantly faster than rehashing dimension state for no
                    // reason. But with mrms it is unavoidable... happens every 4 minutes so perhaps still not worth it)

                } else if (url.includes('/mrms_qpe/')) { // MRMS QPE --------------------------- MRMS QPE ----------------------------------- MRMS QPE

                    // Currently unused as long as we are contructing our own legends from rat tables
                    capabilitiesXML.Capability.Layer.Layer.forEach(layer => {
                        // Parsing styles: layer must match names found in this._styleLayerNames (originally defined in config.js)
                        if (this._styleLayerNames.indexOf(layer.Name) !== -1) { // current layer has style/legend info that is needed
                            let tmpStyleInfo = null;
                            for (let styleInfo of layer.Style) {
                                const styleEntry = {
                                    name: styleInfo.Name,
                                    title: styleInfo.Title,
                                    format: styleInfo.LegendURL[0].Format,
                                    url: styleInfo.LegendURL[0].OnlineResource
                                };
                                if (tmpStyleInfo === null) {
                                    tmpStyleInfo = Object.assign({}, {[layer.Name] : [styleEntry]});
                                } else {
                                    tmpStyleInfo[layer.Name].push(styleEntry);
                                }
                            }
                            Object.assign(this._styleInfo.mrms_qpe, tmpStyleInfo);
                        }
                    });


                    const ratUrlPart1 = "https://mapservices.weather.noaa.gov/raster/rest/services/obs/mrms_qpe/ImageServer/rasterAttributeTable?renderingRule=%7B%22rasterFunction%22%3A%22";
                    const ratUrlPart2 = "%22%7D&f=json";
                    const rasterFuncs = ["rft_1hr", "rft_3hr", "rft_6hr", "rft_12hr", "rft_24hr", "rft_48hr", "rft_72hr"];

                    let ratInfo = {ratFeatures: {}};
                    rasterFuncs.forEach(async (rasterFunc) => {
                        const ratUrl = ratUrlPart1 + rasterFunc + ratUrlPart2;

                        const ratResponse = await fetch(ratUrl);
                        const data = await ratResponse.text();
                        const ratInfoUpdate = JSON.parse(data);

                        Object.assign(ratInfo.ratFeatures, {[rasterFunc]: ratInfoUpdate.features});


                    });

                    Object.assign(this._styleInfo.mrms_qpe, ratInfo);

                } else if (url.includes('/bluetopo/')) { // NBS --------------------------- NBS ----------------------------------- NBS

                    // For parsing info/metadata (keywords)
                    let tmpProductInfo = {
                        keywords: [],
                    };

                    capabilitiesXML.Capability.Layer.Layer.forEach(layer => {
                        logger("Found bathy capabilities for " + layer.Name, 'debug', 'getCapabilities' );

                        // Parsing styles: layer must match names found in this._styleLayerNames (originally defined in config.js)
                        if (this._styleLayerNames.indexOf(layer.Name) !== -1) { // current layer has style/legend info that is needed
                            let tmpStyleInfo = null;
                            for (let styleInfo of layer.Style) {
                                const styleEntry = {
                                    name: styleInfo.Name,
                                    title: styleInfo.Title,
                                    format: styleInfo.LegendURL[0].Format,
                                    width: styleInfo.LegendURL[0].size[0],
                                    height: styleInfo.LegendURL[0].size[1],
                                    url: styleInfo.LegendURL[0].OnlineResource
                                };
                                if (tmpStyleInfo === null) {
                                    tmpStyleInfo = Object.assign({}, {[layer.Name] : [styleEntry]});
                                } else {
                                    tmpStyleInfo[layer.Name].push(styleEntry);
                                }
                            }
                            Object.assign(this._styleInfo.nbs, tmpStyleInfo);
                        }

                        tmpProductInfo.keywords = tmpProductInfo.keywords.concat(layer.KeywordList);
                    });

                    // Remove duplicates from keywords array and store new keywords in obj
                    tmpProductInfo.keywords = [...new Set(tmpProductInfo.keywords)];
                    // NOTE: This overwrites whatever was previously stored in this._productInfo.nbs.keywords
                    // It will only work for products that consist of a single service
                    Object.assign(this._productInfo[this._product], tmpProductInfo);

                } else if (url.includes('/s102/')) { // S102 --------------------------- S102 ----------------------------------- S102

                    capabilitiesXML.Capability.Layer.Layer.forEach(layer => {
                        // Parsing style info (still in for loop)
                        let tmpStyleInfo = null;

                        for (let styleInfo of layer.Style) {
                            tmpStyleInfo = {
                                [layer.Name] : [
                                    {
                                        name: styleInfo.Name,
                                        title: styleInfo.Title,
                                        format: styleInfo.LegendURL[0].Format,
                                        width: styleInfo.LegendURL[0].size[0],
                                        height: styleInfo.LegendURL[0].size[1],
                                        url: styleInfo.LegendURL[0].OnlineResource
                                    }
                                ]
                            }
                        }
                        Object.assign(this._styleInfo.s102, tmpStyleInfo);

                        // Note: No capabilities parse/updates for time values with s102
                    });

                } else if (url.includes('/tropical_storm_surge/')) { // TROPICAL SS --------------------------- TROPICAL SS ----------------------------------- TROPICAL SS
                    // Service Refactoring Notes:
                    // Only changes layer names: nhc_inundation -> inundation, nhc_tidalmask -> tidalmask
                    // But note: These needed to be changed in the tropical_ss layer menu item where there is
                    // hardcoded object mapping dropdown options to layer names. (no changes were needed for legend)

                    // For parsing info/metadata (keywords)
                    let tmpProductInfo = {
                        keywords: [],
                    };

                    capabilitiesXML.Capability.Layer.Layer.forEach(layer => {
                        if (this._styleLayerNames.indexOf(layer.Name) !== -1) { // current layer has legend info that is needed
                            let tmpStyleInfo = null;
                            for (let styleInfo of layer.Style) {
                                const styleEntry = {
                                    name: styleInfo.Name,
                                    title: styleInfo.Title,
                                    format: styleInfo.LegendURL[0].Format,
                                    width: styleInfo.LegendURL[0].size[0],
                                    height: styleInfo.LegendURL[0].size[1],
                                    url: styleInfo.LegendURL[0].OnlineResource
                                };
                                if (tmpStyleInfo === null) {
                                    tmpStyleInfo = Object.assign({}, {[layer.Name] : [styleEntry]});
                                } else {
                                    tmpStyleInfo[layer.Name].push(styleEntry);
                                }
                            }
                            Object.assign(this._styleInfo[this._product], tmpStyleInfo);
                        }

                        tmpProductInfo.keywords = tmpProductInfo.keywords.concat(layer.KeywordList);
                    });


                    // Remove duplicates from keywords array and store new keywords in obj
                    tmpProductInfo.keywords = [...new Set(tmpProductInfo.keywords)];
                    Object.assign(this._productInfo[this._product], tmpProductInfo);

                } else if (url.includes('/tropical_cyclones/')) { // TROPICAL CYCLONES--------------------------- TROPICAL CYCLONES ----------------------------------- TROPICAL CYCLONES
                    // Service Refactoring Notes: No changes were required anywhere after service renaming
                    // Update: tropical cyclone layers now split into past and present layer groups (past
                    // contains observations for current hurricanes)
                    // Style just needed to be changed to iterate over the layer groups and then
                    // over the layers for each layer group
                    // Styles were fixed according to note below

                    // For parsing info/metadata (keywords)
                    let tmpProductInfo = {
                        keywords: [],
                    };

                    // Tropical cyclones does not have time data to collect. It needs one style per layer. (8 total)
                    capabilitiesXML.Capability.Layer.Layer.forEach(layerGroup => {
                        layerGroup.Layer.forEach(layer => {
                            // Parsing style info (tropical cyclone parse does not support multiple styles per layer)
                            let tmpStyleInfo = null;
                            // Note: styleLayerNames was originally intended to dictate the names of geoserver layers whose
                            // styles we want. The idea is to collect ALL styles contained within a given geoserver layer.
                            // However, these layers show 2 style objects: [0] is the correct one and [1] is the style object
                            // for the layer-group. The capabilities xml does not appear to be structured this way when viewed
                            // manually in the browser via caps request. But in the web dev console, the javascript is picking
                            // up a <Style> element containing an array with 2 objects. As long as this is the case we do
                            // not want the standard behavior. This has been changed to only iterate once.
                            // Todo: Come up with a more complete solution/understanding of the cause/consider if styleLayerNames
                            // config is what we want (should we specify style names instead of layer names for instance?)
                            for (let styleInfo of layer.Style) {
                                if (this._styleLayerNames.indexOf(layer.Name) !== -1) { // current layer has legend info that is needed
                                    tmpStyleInfo = {
                                        [layer.Name] : [
                                            {
                                                name: styleInfo.Name,
                                                title: styleInfo.Title,
                                                format: styleInfo.LegendURL[0].Format,
                                                width: styleInfo.LegendURL[0].size[0],
                                                height: styleInfo.LegendURL[0].size[1],
                                                url: styleInfo.LegendURL[0].OnlineResource
                                            }
                                        ]
                                    }
                                }
                                break; // Temporary: Disabled complete iteration (we only want the first style for each layer)
                            }
                            Object.assign(this._styleInfo[this._product], tmpStyleInfo);

                            tmpProductInfo.keywords = tmpProductInfo.keywords.concat(layer.KeywordList);
                        });
                    });

                    // Remove duplicates from keywords array and store new keywords in obj
                    tmpProductInfo.keywords = [...new Set(tmpProductInfo.keywords)];
                    Object.assign(this._productInfo[this._product], tmpProductInfo);

                } else if (url.includes('/stofs3d/')) { // STOFS --------------------------- STOFS ----------------------------------- STOFS
                    // Service Refactoring Notes:
                    // service name change stofs -> stofs3d
                    // change to layer name: surge_max_water_level -> stofs3d_atlantic_disturbance
                    // This needed to be updated as layer key in legend component as well
                    // Part 2: Added a real caps parse so it can be time-enabled
                    // also change layer name: stofs3d_atlantic_disturbance -> stofs3d_atlc_water_disturbance

                    // For parsing info/metadata (keywords)
                    let tmpProductInfo = {
                        keywords: [],
                    };

                    // Parsing Caps
                    capabilitiesXML.Capability.Layer.Layer.forEach(layer => {
                        //Build capabilities object to send out (no ref time for stofs)
                        const tmpCaps = {
                            [layer.Name] : {
                                dimensions: {
                                    time: layer.Dimension[0].values.split(","),
                                },
                                layerGroup: this._product,
                                snapThreshold: this._snapThreshold
                            }
                        };
                        Object.assign(this._capabilities[this._product], tmpCaps);

                        tmpProductInfo.keywords = tmpProductInfo.keywords.concat(layer.KeywordList);
                    });

                    let tmpStyleInfo = null;
                    tmpStyleInfo = {
                        [this._styleLayerNames[0]] : [
                            {
                                name: capabilitiesXML.Capability.Layer.Layer[0].Style[0].Name,
                                title: capabilitiesXML.Capability.Layer.Layer[0].Style[0].Title,
                                format: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].Format,
                                width: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].size[0],
                                height: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].size[1],
                                url: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].OnlineResource
                            }
                        ]
                    }
                    Object.assign(this._styleInfo[this._product], tmpStyleInfo);

                    // Remove duplicates from keywords array and store new keywords in obj
                    tmpProductInfo.keywords = [...new Set(tmpProductInfo.keywords)];
                    Object.assign(this._productInfo[this._product], tmpProductInfo);

                } else if (url.includes('/sea_surface_temperature/')) { // SST --------------------------- SST ----------------------------------- SST

                    // For parsing info/metadata (keywords)
                    let tmpProductInfo = {
                        keywords: [],
                    };

                    // Parsing Caps
                    capabilitiesXML.Capability.Layer.Layer.forEach(layer => {
                        //Build capabilities object to send out (no ref time for sst)
                        const tmpCaps = {
                            [layer.Name] : {
                                dimensions: {
                                    time: layer.Dimension[0].values.split(","),
                                },
                                layerGroup: this._product,
                                snapThreshold: this._snapThreshold
                            }
                        };
                        Object.assign(this._capabilities[this._product], tmpCaps);

                        tmpProductInfo.keywords = tmpProductInfo.keywords.concat(layer.KeywordList);
                    });

                    let tmpStyleInfo = null;
                    tmpStyleInfo = {
                        [this._styleLayerNames[0]] : [
                            {
                                name: capabilitiesXML.Capability.Layer.Layer[0].Style[0].Name,
                                title: capabilitiesXML.Capability.Layer.Layer[0].Style[0].Title,
                                format: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].Format,
                                width: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].size[0],
                                height: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].size[1],
                                url: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].OnlineResource
                            }
                        ]
                    }
                    Object.assign(this._styleInfo[this._product], tmpStyleInfo);

                    // Remove duplicates from keywords array and store new keywords in obj
                    tmpProductInfo.keywords = [...new Set(tmpProductInfo.keywords)];
                    Object.assign(this._productInfo[this._product], tmpProductInfo);

                } else if (url.includes('/marine_pathogen/')) { // VIBRIO --------------------------- VIBRIO ----------------------------------- VIBRIO

                    // For parsing info/metadata (keywords)
                    let tmpProductInfo = {
                        keywords: [],
                    };

                    // Parsing Caps
                    capabilitiesXML.Capability.Layer.Layer.forEach(layer => {
                        // Getting latest reference time for forecast
                        const timeRefs = layer.Dimension[1].values.split(",");
                        const latestRefTime = timeRefs[timeRefs.length-1];
                        // Get all forecast times following latest reference time
                        const refTime = new Date(latestRefTime);
                        const forecastTimes = layer.Dimension[0].values.split(",");
                        let startTimeIndex = 0;
                        for (let i = 0; i < forecastTimes.length; i++) {
                            const tmpTime = new Date(forecastTimes[i]);
                            if (tmpTime >= refTime) {
                                startTimeIndex = i;
                                break;
                            }
                        }

                        const validForecastTimes = forecastTimes.slice(startTimeIndex);

                        //Build capabilities object to send out
                        const tmpCaps = {
                            [layer.Name] : {
                                dimensions: {
                                    time: validForecastTimes,
                                    dim_time_reference: [latestRefTime]
                                },
                                layerGroup: this._product,
                                snapThreshold: this._snapThreshold
                            }
                        };

                        Object.assign(this._capabilities[this._product], tmpCaps);

                        tmpProductInfo.keywords = tmpProductInfo.keywords.concat(layer.KeywordList);
                    });

                    let tmpStyleInfo = null;
                    tmpStyleInfo = {
                        [this._styleLayerNames[0]] : [
                            {
                                name: capabilitiesXML.Capability.Layer.Layer[0].Style[0].Name,
                                title: capabilitiesXML.Capability.Layer.Layer[0].Style[0].Title,
                                format: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].Format,
                                width: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].size[0],
                                height: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].size[1],
                                url: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].OnlineResource
                            }
                        ]
                    }
                    Object.assign(this._styleInfo[this._product], tmpStyleInfo);

                    // Remove duplicates from keywords array and store new keywords in obj
                    tmpProductInfo.keywords = [...new Set(tmpProductInfo.keywords)];
                    Object.assign(this._productInfo[this._product], tmpProductInfo);

                } else if (url.includes('/lightning_detection/')) { // LIGHTNING --------------------------- LIGHTNING ----------------------------------- LIGHTNING

                    // For parsing info/metadata (keywords)
                    let tmpProductInfo = {
                        keywords: [],
                    };

                    // Parsing Caps
                    capabilitiesXML.Capability.Layer.Layer.forEach(layer => {
                        //Build capabilities object to send out (no ref time for lightning density)
                        const tmpCaps = {
                            [layer.Name] : {
                                dimensions: {
                                    time: layer.Dimension[0].values.split(","),
                                },
                                layerGroup: this._product,
                                snapThreshold: this._snapThreshold
                            }
                        };
                        Object.assign(this._capabilities[this._product], tmpCaps);

                        tmpProductInfo.keywords = tmpProductInfo.keywords.concat(layer.KeywordList);
                    });

                    let tmpStyleInfo = null;
                    tmpStyleInfo = {
                        [this._styleLayerNames[0]] : [
                            {
                                name: capabilitiesXML.Capability.Layer.Layer[0].Style[0].Name,
                                title: capabilitiesXML.Capability.Layer.Layer[0].Style[0].Title,
                                format: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].Format,
                                width: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].size[0],
                                height: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].size[1],
                                url: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].OnlineResource
                            }
                        ]
                    }
                    Object.assign(this._styleInfo[this._product], tmpStyleInfo);

                    // Remove duplicates from keywords array and store new keywords in obj
                    tmpProductInfo.keywords = [...new Set(tmpProductInfo.keywords)];
                    Object.assign(this._productInfo[this._product], tmpProductInfo);

                } else if (url.includes('/boundaries/')) { // BOUNDARIES --------------------------- BOUNDARIES ----------------------------------- BOUNDARIES
                    // For parsing info/metadata (keywords)
                    let tmpProductInfo = {
                        keywords: [],
                    };

                    capabilitiesXML.Capability.Layer.Layer.forEach(layer => {
                        if (this._styleLayerNames.indexOf(layer.Name) !== -1) { // current layer has legend info that is needed
                            let tmpStyleInfo = null;
                            for (let styleInfo of layer.Style) {
                                const styleEntry = {
                                    name: styleInfo.Name,
                                    title: styleInfo.Title,
                                    format: styleInfo.LegendURL[0].Format,
                                    width: styleInfo.LegendURL[0].size[0],
                                    height: styleInfo.LegendURL[0].size[1],
                                    url: styleInfo.LegendURL[0].OnlineResource
                                };
                                if (tmpStyleInfo === null) {
                                    tmpStyleInfo = Object.assign({}, {[layer.Name] : [styleEntry]});
                                } else {
                                    tmpStyleInfo[layer.Name].push(styleEntry);
                                }
                            }
                            Object.assign(this._styleInfo[this._product], tmpStyleInfo);
                        }

                        tmpProductInfo.keywords = tmpProductInfo.keywords.concat(layer.KeywordList);
                    });


                    // Remove duplicates from keywords array and store new keywords in obj
                    tmpProductInfo.keywords = [...new Set(tmpProductInfo.keywords)];
                    Object.assign(this._productInfo[this._product], tmpProductInfo);

                    // Note: Static dataset no time values

                }  else if (url.includes('/alerts/')) { // WWA --------------------------- WWA ----------------------------------- WWA
                    // Service Refactoring Notes:
                    // Changes:
                    //  This only required changing www -> alerts
                    //  Additionally, in config.js, the layer name changed from wwa_all_hazards -> watches_warnings_advisories
                    //  which included the value fed into _styleLayerNames used here

                    // Parse keywords (only needed from one layer. No need to deduplicate)
                    let tmpProductInfo = {
                        keywords: [...capabilitiesXML.Capability.Layer.Layer[0].KeywordList],
                    };
                    Object.assign(this._productInfo[this._product], tmpProductInfo);

                    // Parsing style info
                    // WWA only needs one set of legend info, it grabs the top one, which should be "watches_warnings_advisories"
                    // that can be parsed here with: capabilitiesXML.Capability.Layer.Layer[0].Style[0].Name but to be safe
                    // it will be hard coded here (to change value, change it in config.js)
                    // (this parse does not support multiple styles)
                    let tmpStyleInfo = null;
                    tmpStyleInfo = {
                        [this._styleLayerNames[0]] : [ //watches_warnings_advisories
                            {
                                name: capabilitiesXML.Capability.Layer.Layer[0].Style[0].Name,
                                title: capabilitiesXML.Capability.Layer.Layer[0].Style[0].Title,
                                format: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].Format,
                                width: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].size[0],
                                height: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].size[1],
                                url: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].OnlineResource
                            }
                        ]
                    }
                    Object.assign(this._styleInfo[this._product], tmpStyleInfo);

                    // Note: Currently no capabilities parse/updates for time values with wwa

                } else if (url.includes('/satellite/')) { // SATELLITE --------------------------- SATELLITE ----------------------------------- SATELLITE
                // Service Refactoring Notes:
                // This needed to be updated as layer key in legend component as well
                //Build capabilities object to send out

                    // For parsing info/metadata (keywords)
                    let tmpProductInfo = {
                        keywords: [],
                    };

                    // Parsing Caps
                    capabilitiesXML.Capability.Layer.Layer.forEach(layer => {
                        //Build capabilities object to send out (no ref time for stofs)
                        const timeVals = layer.Dimension[0].values.split(",");

                        // Shorten timeVals list to where it only contains the last 4 hours of data
                        let latestTimeVal = new Date(timeVals[timeVals.length-1]);
                        let earliestTimeVal = latestTimeVal.setHours(latestTimeVal.getHours() - MAX_OBSERVATION_HOURS);
                        let truncatedTimeVals = [];
                        for (let i = 0; i < timeVals.length; i++) {
                            let curTimeVal = new Date(timeVals[i]);
                            if (curTimeVal <= earliestTimeVal) continue;
                            truncatedTimeVals.push(timeVals[i]);
                        }

                        const tmpCaps = {
                            [layer.Name] : {
                                dimensions: {
                                    time: truncatedTimeVals,
                                },
                                layerGroup: this._product,
                                snapThreshold: this._snapThreshold
                            }
                        };
                        Object.assign(this._capabilities[this._product], tmpCaps);

                        tmpProductInfo.keywords = tmpProductInfo.keywords.concat(layer.KeywordList);
                    });

                    let tmpStyleInfo = null;
                    tmpStyleInfo = {
                        [this._styleLayerNames[0]] : [
                            {
                                name: capabilitiesXML.Capability.Layer.Layer[0].Style[0].Name,
                                title: capabilitiesXML.Capability.Layer.Layer[0].Style[0].Title,
                                format: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].Format,
                                width: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].size[0],
                                height: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].size[1],
                                url: capabilitiesXML.Capability.Layer.Layer[0].Style[0].LegendURL[0].OnlineResource
                            }
                        ]
                    }
                    Object.assign(this._styleInfo[this._product], tmpStyleInfo);

                    // Remove duplicates from keywords array and store new keywords in obj
                    tmpProductInfo.keywords = [...new Set(tmpProductInfo.keywords)];
                    Object.assign(this._productInfo[this._product], tmpProductInfo);

                } else {
                    console.log("[WMSCapabilitiesHandler] Capabilities URL not supported in:", this._capUrls);
                }
            } catch (e) {
                console.log("[WMSCapabilitiesHandler] Caught exception while parsing Get Capabilities from URL:", this._capUrls);
                console.log(e);
            }
        }

//        //DEBUG
//       console.log("Capabilities parse complete. Dispatching the following caps and styles:");
//       console.log(this._capabilities);
//       console.log(this._styleInfo);
//       console.log(this._productInfo);

        // All parses complete, send data out via custom events if applicable
        if (Object.keys(this._capabilities[this._product]).length > 0) {
            this.dispatchEvent("capabilitiesUpdated", this._capabilities);
        }

        if (Object.keys(this._styleInfo[this._product]).length > 0) {
            this.dispatchEvent("stylesUpdated", this._styleInfo);
        }

        if (Object.keys(this._productInfo[this._product]).length > 0) {
            this.dispatchEvent("infoUpdated", this._productInfo);
        }

        // Start setInterval for getCapabilities only after first manual request and not subsequent manual requests if they occur
        // and only if a cap request interval arg was passed in
        if (!this._getCapabilitiesInterval && this._capRequestInterval) {
            this._getCapabilitiesInterval = setInterval(() => {
            logger("Calling getCapabilities from setInterval", 'debug', 'getCapabilities' );
            this.getCapabilities();
            }, this._capRequestInterval);
        }
    }
}

export default WMSCapabilitiesHandler;
