
/**
* findIndexBS (Binary Search -- REQUIRES sorted array)
* Simple binary search for retrieving index of item in sorted array of ints
*
* @param (number) val - value to get index of
* @param (array - iterable/sorted) list - array to search
* @param (int) startIndex
* @param (int) endIndex
*
* @returns (number) - index of element in array, null if not found
*/

export function findIndexBS(val, list, startIndex, endIndex) {

    const midIndex = Math.floor((startIndex + endIndex) / 2);

    // Base Cases
    if (val === list[midIndex]) {
        return (midIndex);
    }
    if (startIndex > endIndex) {
        return (null);
    }

    // Recursive step, look below or above the midIndex
    if (val < list[midIndex]) {
        return findIndexBS(val, list, startIndex, midIndex - 1);
    } else {
        return findIndexBS(val, list, midIndex + 1, endIndex);
    }
}

// Note: currently using below function. Top function may be useful but consider removing in the future

/**
* findClosestIndexBS (Binary Search -- REQUIRES sorted array)
* Simple binary search for retrieving closest index of item in sorted array of ints
*
* @param (number) val - value to get index of
* @param (array - iterable/sorted) list - array to search
* @param (int) startIndex
* @param (int) endIndex
*
* @returns (number) - index of closest element in array
*/

export function findClosestIndexBS(val, list, startIndex, endIndex) {
    if (!list || list.length === 0) return null;

    const midIndex = Math.floor((startIndex + endIndex) / 2);

    // Base Cases
    // exact match
    if (val === list[midIndex]) {
        return (midIndex);
    }
    // val is between two indexes, so return the closest one
    if (Math.abs(startIndex - endIndex) === 1) {
        const diff1 = Math.abs(val - list[startIndex]);
        const diff2 = Math.abs(val - list[endIndex]);
        return (diff1 < diff2) ? startIndex : endIndex;
    }

    // Recursive step, look below or above the midIndex
    if (val < list[midIndex]) {
        if (val < list[midIndex-1]) {
            return findClosestIndexBS(val, list, startIndex, midIndex - 1);
        } else {
            return findClosestIndexBS(val, list, midIndex - 1, midIndex); // to base case
        }
    } else {
        if (val > list[midIndex+1]) {
            return findClosestIndexBS(val, list, midIndex + 1, endIndex);
        } else {
            return findClosestIndexBS(val, list, midIndex, midIndex + 1); // to base case
        }
    }
}

/**
* Function originally defined in time-slider.js - Consider moving to a common file
*
* Returns string from epoch time in form "<month>/<day> <hour>:<min> <am/pm>"
*/
export function shortDateString(val) {
    const dateVal = new Date(val);
    const month = dateVal.getMonth() + 1;
    const day = dateVal.getDate();
    let ampm = "am";
    let hours = dateVal.getHours();
    if (hours >= 12) { //0-11 is am, 12-23 is pm
        ampm = "pm";
        hours = hours%12;
    }
    hours = hours === 0 ? 12 : hours;
    let minutes = dateVal.getMinutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return (month + "/" + day + " " + hours + ":" + minutes + " " + ampm);
}

/**
 * getTimeZone
 * getTimeZoneName sourced from: https://stackoverflow.com/a/73584867 ;
 * short' returns EST, 'long' returns Easter Standard time
 *
 * @returns (string) - string containing shorthand timezone from system timezone
 * (unsupported timezones will return an empty string)
**/
export function getTimeZone() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const getTimeZoneName = (timeZone: string, nameType: 'short' | 'long') => {
        const formatter = new Intl.DateTimeFormat('en-US', { timeZone, timeZoneName: nameType })
        const formatted = formatter.formatToParts(new Date())

        return formatted.find(({ type }) => type === 'timeZoneName')?.value || ''
    }
    return getTimeZoneName(timeZone, 'short');
}

/**
* timeStringFromRequestUrl
* Pulls 'time' parameter from a URL request and returns it as a formatted string
*
* @param (string) val - Feature info request URL
*
* @returns (string) - formatted datetime string
**/

export function timeStringFromRequestUrl(val) {
    const urlParams = new URLSearchParams(val)
    return (shortDateString(urlParams.get("time")) + " " + getTimeZone())
}

/**
* Function used to parse surface obs timestamp
* @param (string) - date string provided by surface obs WFS
* @returns (Date obj) - Date object with observation timestamp
**/
export function parseObsTimestamp(dateString) {
    const dateTime = dateString.split(" ");
    const date = dateTime[0].split("/");
    const time = dateTime[1].split(":");
    let ZModifier;
    if(dateTime[2].toUpperCase() === "PM" && parseInt(time[0]) < 12) {
        ZModifier = 12;
    } else {
        if(parseInt(time[0]) === 12) {
            ZModifier = -1;
        } else {
            ZModifier = 0;
        }
    }
    const month = parseInt(date[0]) - 1;
    const day = parseInt(date[1]);
    const year = parseInt(date[2]);
    const hour = parseInt(time[0]) + ZModifier;
    const minute = parseInt(time[1]);
    const second = parseInt(time[2]);
    const timestamp = new Date(Date.UTC(year, month, day, hour, minute, second));
    return timestamp;
}

/**
* Function used to parse a METAR code for weather
* @param (string) - METAR code
* @returns (obj) - weather object with weather abbreviation and meaning
**/
export function parseMETARWeather(metar) {
    const parser = require("metar");
    try {
        const parsedMETAR = parser(metar);
        return parsedMETAR.weather;
    } catch(e) {return null}
}

export function parseMETARClouds(metar) {
    const parser = require("metar");
    try {
        const parsedMETAR = parser(metar);
        const clouds = parsedMETAR.clouds;
        let cloudCodes = [];
        let cloudCover = "null";
        for(const cloud of clouds) {
            cloudCodes.push(cloud.abbreviation);
        }
        if(cloudCodes.includes("OVC") || cloudCodes.includes("10.")) {
            cloudCover = "OVC";
        } else if(cloudCodes.includes("8.8")) {
            cloudCover = "8.8";
        } else if(cloudCodes.includes("BKN") || cloudCodes.includes("7.5")) {
            cloudCover = "BKN";
        } else if(cloudCodes.includes("6.3")) {
            cloudCover = "6.3";
        } else if(cloudCodes.includes("5.0")) {
            cloudCover = "5.0";
        } else if(cloudCodes.includes("VV")) {
            cloudCover = "VV";
        } else if(cloudCodes.includes("3.7")) {
            cloudCover = "3.7";
        } else if(cloudCodes.includes("2.5") || cloudCodes.includes("SCT") || cloudCodes.includes("SKT")) {
            cloudCover = "SCT";
        } else if(cloudCodes.includes("1.2") || cloudCodes.includes("FEW")) {
            cloudCover = "FEW";
        } else if(cloudCodes.includes("0.0") || cloudCodes.includes("SKC") || cloudCodes.includes("CLR")) {
            cloudCover = "CLR";
        }
        return cloudCover;
    } catch(e) {return "null"}
}