import React from 'react';
import { Typography, ListSubheader } from '@mui/material';


const sxStyles = {
    label: {
        color: '#252525',
        fontSize: '98%',
    },
};

const classes = {
    legendIMG:{
        maxWidth: '100%',
        maxHeight: '95em'
    },
};

// OFS Surface Currents uses one legend for all layers thus only one layer is parsed from capabilities
// the layer used as a key for the legend info is hard coded here, this should always match the parse found in capabilities-handler-wms.js
const LAYER_KEY = "cbofs_sfc_currents";

/**
* Displays legend image with a label above it
*
* @prop (obj) S111StyleInfo - legend info that pertains only to OFS surface currents (derived from top-level state obj: styleInfo)
*
* NOTE: dynamic styles not enabled for this layer. This component always grabs first available legend with [0]
*/
function S111Legend(props){

    if (!props.S111StyleInfo) return null;

    return (
        <ListSubheader component="div" >
            <Typography sx={sxStyles.label}>{props.S111StyleInfo[LAYER_KEY][0].title}</Typography>
            <img alt="" src={props.S111StyleInfo[LAYER_KEY][0].url} />
        </ListSubheader>
    );
}

export default S111Legend;