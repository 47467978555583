import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import SSTLegend from '../menu/legend-menu-items/sst-legend.js';
import { timeStringFromRequestUrl } from '../../utilities/utilities.js';

const classes ={
    SSTFeatureInfoContainer: {
        padding: '10px',
          width: '90%',
    },

};

/**
* Component for rendering results from getFreatureInfo wms requests
*
* @prop (obj) data - json response obj from getFreatureInfo request
* @prop (obj) urls - urls response obj from getFreatureInfo request
* @prop (obj) styleInfo - legend info that pertains only to SST (derived from top-level state obj: styleInfo)
* @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
* @prop (obj) productToggles
**/
export default function SSTFeatureInfo(props) {
    const dataList = []
    let dataDoneLoading = true; // data is loading until there is a props.data[regionKey].features is defined for all regions

    if (Object.keys(props.data).length === 0 || !dataDoneLoading) {
        return(<CircularProgress sx={{margin: '70px 20px'}} />);
    }

    // Get all selected layers
    const tempTypes = new Set();
    for (const layer in props.data) {
        if (props.data[layer].features) {
            if (props.olLayerState[layer].on) { // && props.data[layer].features.length > 0) {
                tempTypes.add(layer);
            }
        }
    }

    for (const dataSource in props.data) {
        const dataObject = props.data[dataSource]
        const dataUrl = props.urls[dataSource]

        if (!dataObject.hasOwnProperty('features')) {
            // features is not set
            dataDoneLoading = false;
            break;
        }

        let idxPos = null
        let titleText = null
        if (String(dataSource) === "nasa_sport_sea_surface_temperature") {
            titleText = "NASA SPoRT Northern Hemisphere Sea Surface Temperature Analysis (Experimental)"
            idxPos = 0
        } else if (String(dataSource) === "global_sea_surface_temperature") {
            titleText = "NWS Global Near Sea Surface Temperature Analysis"
            idxPos = 1
        }

        let timeText = timeStringFromRequestUrl(dataUrl)

        if (tempTypes.has(String(dataSource)) ) {
            if (dataObject.features && dataObject.features.length > 0 && dataObject.numberReturned === 1) {
                if (dataObject.features[0].properties['Band1'] >= 25.5 && dataObject.features[0].properties['Band1'] !== 9999.0) {
                    const pointDataValue = Number.parseFloat(dataObject.features[0].properties['Band1']).toFixed(1)
                    const celsius = (dataObject.features[0].properties['Band1'] - 32) * 5/9;
                    let tempData =
                        <div key={idxPos}>
                            <Typography sx={{fontSize: '0.8em', 'fontWeight': 'bold'}} mb={1} align="center">{titleText}</Typography>
                            <Typography sx={{fontSize: '0.8em'}} mb={1} align="center">{pointDataValue} °F / {Number.parseFloat(celsius).toFixed(1)} °C   ({timeText})</Typography>
                        </div>
                    dataList[idxPos] = tempData
                } else {
                    let tempData =
                        <div key={idxPos}>
                            <Typography sx={{fontSize: '0.8em', 'fontWeight': 'bold'}} mb={1} align="center">{titleText}</Typography>
                            <Typography sx={{fontSize: '0.8em'}} mb={1} align="center">No feature info found at the specified location. Please try again in another location.</Typography>
                        </div>
                    dataList[idxPos] = tempData
                }
            } else {
                let tempData =
                    <div key={idxPos}>
                        <Typography sx={{fontSize: '0.8em', 'fontWeight': 'bold'}} mb={1} align="center">{titleText}</Typography>
                        <Typography sx={{fontSize: '0.8em'}} mb={1} align="center">No feature info found at the specified location. Please try again in another location.</Typography>
                    </div>
                dataList[idxPos] = tempData
            }
        }
    }


    if (dataList.length !== 0 && dataDoneLoading){
        return(
            <div style={classes.SSTFeatureInfoContainer} align='center'>
                { (props.styleInfo) ?
                    <SSTLegend
                        SSTStyleInfo={props.styleInfo}
                    />
                    : null
                }
                {dataList.map((item) => (item))}
                <br />

            </div>
        );
    } else {
        return(
            <div style={{padding: '50px 20px'}}>
                <Typography sx={{fontSize: '0.8em'}} >No feature info found at the specified location. Please try again in another location.</Typography>
            </div>
        );
    }
}
