import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Slider from '@mui/material/Slider';
import PropTypes from 'prop-types';


const ValueLabelTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        boxShadow: theme.shadows[2],
        fontSize: '70%',
        '& *' : {
            color: theme.palette.primary.main,
        }
    },
}));

function OpacitySliderValueLabel(props) {
    const { children, open, value } = props;
    return (
        <ValueLabelTooltip arrow open={open} enterTouchDelay={0} placement="top" title={value} >
            {children}
        </ValueLabelTooltip>
    );
}

OpacitySliderValueLabel.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
};

const classes = {
    sliderDiv: {
        width: '100%', // Slider grows to fill its container - container size is dictated here, but its nested in a grid item whos size should be relied on more
    },
};

const NowCoastOpacitySlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-thumb': {
        height: 12,
        width: 12,
        boxShadow: theme.shadows[2],
        backgroundColor: theme.palette.primary.main,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'rgba(0,0,0,0.6)',
        transition: 'none'
    },
}));

function OpacitySlider(props) {

    // Local state for value of time slider (remember previous prop val to see if opacity was changed from elsewhere)
    const [opacityState, setOpacityState] = useState({
        sliderVal: typeof props.opacity === "number" ? props.opacity : 100,
        prevPropSliderVal: 0
    });

    const handleSliderOnChange = (e, value) => {
        //props.clearOpacityIndex();
        props.updateLayerOpacities({[props.layerName]:value});
        setOpacityState({
            ...opacityState,
            'sliderVal': value
        });
    };

    const makeValueLabel = (val) => {
        return(val + ' %');
    };

    // Adjust local state(opacity) connected to slider on render to match opacity passed in by props
    if (typeof props.opacity === "number") {
        if (props.opacity !== opacityState.prevPropSliderVal) {
            setOpacityState({
                'sliderVal': props.opacity,
                'prevPropSliderVal': props.opacity
            });
        }
    }

    return (
        <div style={classes.sliderDiv}>
            <NowCoastOpacitySlider
                value={typeof opacityState.sliderVal === "number" ? opacityState.sliderVal : 0}
                valueLabelFormat={makeValueLabel}
                min={0}
                max={100}
                step={1}
                onChange = {handleSliderOnChange}
                valueLabelDisplay="auto" // defaults to "off" in mui5 so must be set
                components={{
                    ValueLabel: OpacitySliderValueLabel,
                }}
            />
        </div>
    );
}
export default OpacitySlider