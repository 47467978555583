import React, { useState } from "react";
import { Select, InputLabel, MenuItem, Typography, CircularProgress, Checkbox, FormControlLabel, FormGroup, FormLabel } from '@mui/material';

import { OFS_SFC_CURRENTS_INFO } from '../../../config.js';
import LayerOptionsContainer from './layer-options-container.js';
import S111Legend from '../legend-menu-items/ofs-surface-currents-legend.js';

import LayerMenuItem from './layer-menu-item.js';

const classes = {
    menuItemBodyContainer: {
        paddingTop: '0.5em',
        paddingBottom: '0.5em',
    },
};

const sxStyles = {
    layerCheckBoxLabel: {
        fontSize: '80%',
    },
    regionCheckBoxLabel: {
        fontSize: '80%',
        color: '#777',
    },
    layerCheckBox: {
        marginLeft: '1.3em',
        marginBottom: -1,
    },
    regionCheckBox: {
        marginLeft: '-0.2em',
        marginBottom: -1,
    }
}


/**
* S111Select: Selects current variable to be displayed for s111
*
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*   @prop (bool) greatLakesChecked - State used by OFS/S111 menu item component
*   @prop (func) setGreatLakesChecked - State used by OFS/S111 menu item component
*   @prop (bool) coastalOceanChecked - State used by OFS/S111 menu item component
*   @prop (func) setCoastalOceanChecked - State used by OFS/S111 menu item component
*   @prop (bool) globalOceanChecked - State used by OFS/S111 menu item component
*   @prop (func) setGlobalOceanChecked - State used by OFS/S111 menu item component
*   @prop (obj) greatLakesControlStates - State used by OFS/S111 menu item component
*   @prop (func) setGreatLakesControlStates - State used by OFS/S111 menu item component
*   @prop (obj) coastalOceanControlStates - State used by OFS/S111 menu item component
*   @prop (func) setCoastalOceanControlStates - State used by OFS/S111 menu item component
*/
function OfsControl(props){

    // Temporarily decomposing props for what used to be local state until a better state management solution
    // is implemented (to avoid decomposition, just put props. in front of all usages of these vars.)
    const greatLakesChecked = props.greatLakesChecked;
    const setGreatLakesChecked = props.setGreatLakesChecked;
    const coastalOceanChecked = props.coastalOceanChecked;
    const setCoastalOceanChecked = props.setCoastalOceanChecked;
    const globalOceanChecked = props.globalOceanChecked;
    const setGlobalOceanChecked = props.setGlobalOceanChecked;
    const greatLakesControlStates = props.greatLakesControlStates;
    const setGreatLakesControlStates = props.setGreatLakesControlStates;
    const coastalOceanControlStates = props.coastalOceanControlStates;
    const setCoastalOceanControlStates = props.setCoastalOceanControlStates;

//    const [greatLakesChecked, setGreatLakesChecked] = useState(false);
//    const [coastalOceanChecked, setCoastalOceanChecked] = useState(false);
//    const [globalOceanChecked, setGlobalOceanChecked] = useState(false);
//
//    // NOTE: Keys below are hard-coded to match OL Layer names in config.js and keys in olLayerState object
//    const [greatLakesControlStates, setGreatLakesControlStates] = useState({
//        leofs_sfc_currents: false,
//        lmhofs_sfc_currents: false,
//        loofs_sfc_currents: false,
//        lsofs_sfc_currents: false,
//    });
//
//    const [coastalOceanControlStates, setCoastalOceanControlStates] = useState({
//        gomofs_sfc_currents: false,
//        nyofs_sfc_currents: false,
//        cbofs_sfc_currents: false,
//        dbofs_sfc_currents: false,
//        tbofs_sfc_currents: false,
//        ngofs_sfc_currents: false,
//        sfbofs_sfc_currents: false,
//        wcofs_sfc_currents: false,
//    });


    // Event handler for region checkbox:
    // Turns off all layers for that region or turns on whatever regions are checked
    // Args:
    //  - regionChecked - local state (hook) for the region that is being checked
    //  - regionControlStates - the local controlStates (hook) for that region
    //  - setRegionState - setter for state (hook) for the region that is being checked
    //  - setRegionControlStates - setter for local controlStates (hook) for this region
    //  - setUncheckedRegion- Optional - setter for state of a region to be unchecked (for mutual exclusion)
    const toggleRegion = (regionChecked, regionControlStates, setRegionState, setRegionControlStates, setUncheckedRegion=false) => {
        // Build state update for all layers in region
        // If regionChecked is true, then we are switching off, so use false for on value
        // of all layers. Else we are switching on, use the value stored in regionControlStates
        // Also if region is being turned on and all layers in region are off then turn on a specific layer
        let allLayersOff = true;
        const layerUpdates = {};
        for (const layerName in regionControlStates) {
            const layerIsOn = (regionChecked) ? false : regionControlStates[layerName];

            Object.assign(layerUpdates, {
                [layerName] : {
                    ...props.olLayerState[layerName],
                    'on': layerIsOn
                },
            });

            if (layerIsOn) {
                allLayersOff = false;
            }
        }

        if (allLayersOff && !regionChecked) {
            // Turning on the top layer in the list, to match behavior of nowcoast@idp
            // (because all layers were off and region was turned on)
            const curLayerName = Object.keys(layerUpdates)[0];

            Object.assign(layerUpdates, {
                [curLayerName] : {
                    ...props.olLayerState[curLayerName],
                    'on': true
                },
            });

            setRegionControlStates({
                ...regionControlStates,
                [curLayerName] : true
            })
        }

        props.updateOlLayerState(layerUpdates);
        setRegionState(!regionChecked);

        // Mutual Exclusion with global oceans region
        if (setUncheckedRegion && !regionChecked && globalOceanChecked) setUncheckedRegion(); //toggleGlobalOcean
    };

    // Event handler for toggling a checkbox for individual layers
    // Args:
    //  - regionChecked - local state (hook) for the region that the layer belongs to
    //  - layerName - Name of layer to toggle (must be a valid key in regionControlStates
    //  - regionControlStates - the local controlStates (hook) for this region
    //  - setRegionControlStates - setter for local controlStates (hook) for this region
    //  - setRegionState - setter for state (hook) for the region that the layer belongs to
    const toggleLayer = (regionChecked, layerName, regionControlStates, setRegionControlStates, setRegionState) => {
        // Toggle top-level state for this layer if region is on (regionChecked === true)
        if (regionChecked) {
            props.updateOlLayerState({
                [layerName] : {
                    ...props.olLayerState[layerName],
                    'on': !regionControlStates[layerName]
                },
            });
        }

        // If region was off and layer was off, toggle the region on with the layer
        // Must update state the same way as when toggling the region since that i
        if (!regionChecked && !regionControlStates[layerName]) {
            const updatedRegionControlStates = {
                ...regionControlStates,
                [layerName] : !regionControlStates[layerName] // true
            };
            toggleRegion(regionChecked, updatedRegionControlStates, setRegionState, setRegionControlStates, toggleGlobalOcean);
        }

        // Toggle value for this layer in local regionControlStates
        setRegionControlStates({
            ...regionControlStates,
            [layerName] : !regionControlStates[layerName]
        });
    };

    // Event handler for Global Oceans checkbox (All layers are on or off for this )
    // - setUncheckedRegion- Optional - setter for state of a region to be unchecked (for mutual exclusion)
    const toggleGlobalOcean = (setUncheckedRegion=false) => {
        props.updateOlLayerState({
            rtofs_east_sfc_currents : {
                ...props.olLayerState['rtofs_east_sfc_currents'],
                'on': !globalOceanChecked
            },
            rtofs_west_sfc_currents : {
                ...props.olLayerState['rtofs_west_sfc_currents'],
                'on': !globalOceanChecked
            }
        });
        setGlobalOceanChecked(!globalOceanChecked);

        // Mutual Exclusion with coastal region
        if (setUncheckedRegion && !globalOceanChecked && coastalOceanChecked) setUncheckedRegion(); // Wrapped toggleRegion() with appropriate args passed in
    };

    return(
        <FormGroup>
        <FormLabel><Typography variant="overline" >Gridded Model Forecast Guidance</Typography></FormLabel>
        <Typography variant="overline">Surface Currents (Speed)</Typography>
        <FormControlLabel
            label={<Typography sx={sxStyles.regionCheckBoxLabel}>Great Lakes (NOS)</Typography>}
            control={
                <Checkbox
                    checked={greatLakesChecked}
                    onChange={() => {toggleRegion(greatLakesChecked, greatLakesControlStates, setGreatLakesChecked, setGreatLakesControlStates)}}
                    size="small"
                />
            }
            sx={sxStyles.regionCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>Lake Erie</Typography>}
            control={
                <Checkbox
                    checked={greatLakesControlStates.leofs_sfc_currents}
                    onChange={() => {toggleLayer(greatLakesChecked, "leofs_sfc_currents", greatLakesControlStates, setGreatLakesControlStates, setGreatLakesChecked)}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>Lakes Michigan & Huron</Typography>}
            control={
                <Checkbox
                    checked={greatLakesControlStates.lmhofs_sfc_currents}
                    onChange={() => {toggleLayer(greatLakesChecked, "lmhofs_sfc_currents", greatLakesControlStates, setGreatLakesControlStates, setGreatLakesChecked)}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>Lake Ontario</Typography>}
            control={
                <Checkbox
                    checked={greatLakesControlStates.loofs_sfc_currents}
                    onChange={() => {toggleLayer(greatLakesChecked, "loofs_sfc_currents", greatLakesControlStates, setGreatLakesControlStates, setGreatLakesChecked)}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>Lakes Superior</Typography>}
            control={
                <Checkbox
                    checked={greatLakesControlStates.lsofs_sfc_currents}
                    onChange={() => {toggleLayer(greatLakesChecked, "lsofs_sfc_currents", greatLakesControlStates, setGreatLakesControlStates, setGreatLakesChecked)}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.regionCheckBoxLabel}>Estuary/Coastal Ocean (NOS)</Typography>}
            control={
                <Checkbox
                    checked={coastalOceanChecked}
                    onChange={() => {toggleRegion(coastalOceanChecked, coastalOceanControlStates, setCoastalOceanChecked, setCoastalOceanControlStates, toggleGlobalOcean)}}
                    size="small"
                />
            }
            sx={sxStyles.regionCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>Chesapeake Bay</Typography>}
            control={
                <Checkbox
                    checked={coastalOceanControlStates.cbofs_sfc_currents}
                    onChange={() => {toggleLayer(coastalOceanChecked, "cbofs_sfc_currents", coastalOceanControlStates, setCoastalOceanControlStates, setCoastalOceanChecked)}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>Delaware Bay</Typography>}
            control={
                <Checkbox
                    checked={coastalOceanControlStates.dbofs_sfc_currents}
                    onChange={() => {toggleLayer(coastalOceanChecked, "dbofs_sfc_currents", coastalOceanControlStates, setCoastalOceanControlStates, setCoastalOceanChecked)}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>Gulf of Maine</Typography>}
            control={
                <Checkbox
                    checked={coastalOceanControlStates.gomofs_sfc_currents}
                    onChange={() => {toggleLayer(coastalOceanChecked, "gomofs_sfc_currents", coastalOceanControlStates, setCoastalOceanControlStates, setCoastalOceanChecked)}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>NY & NJ Harbor</Typography>}
            control={
                <Checkbox
                    checked={coastalOceanControlStates.nyofs_sfc_currents}
                    onChange={() => {toggleLayer(coastalOceanChecked, "nyofs_sfc_currents", coastalOceanControlStates, setCoastalOceanControlStates, setCoastalOceanChecked)}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>Northern Gulf of Mexico</Typography>}
            control={
                <Checkbox
                    checked={coastalOceanControlStates.ngofs_sfc_currents}
                    onChange={() => {toggleLayer(coastalOceanChecked, "ngofs_sfc_currents", coastalOceanControlStates, setCoastalOceanControlStates, setCoastalOceanChecked)}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>San Francisco Bay</Typography>}
            control={
                <Checkbox
                    checked={coastalOceanControlStates.sfbofs_sfc_currents}
                    onChange={() => {toggleLayer(coastalOceanChecked, "sfbofs_sfc_currents", coastalOceanControlStates, setCoastalOceanControlStates, setCoastalOceanChecked)}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>Tampa Bay</Typography>}
            control={
                <Checkbox
                    checked={coastalOceanControlStates.tbofs_sfc_currents}
                    onChange={() => {toggleLayer(coastalOceanChecked, "tbofs_sfc_currents", coastalOceanControlStates, setCoastalOceanControlStates, setCoastalOceanChecked)}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>West Coast</Typography>}
            control={
                <Checkbox
                    checked={coastalOceanControlStates.wcofs_sfc_currents}
                    onChange={() => {toggleLayer(coastalOceanChecked, "wcofs_sfc_currents", coastalOceanControlStates, setCoastalOceanControlStates, setCoastalOceanChecked)}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.regionCheckBoxLabel}>Global Ocean (NWS)</Typography>}
            control={
                <Checkbox
                    checked={globalOceanChecked}
                    onChange={() => {toggleGlobalOcean(() => {toggleRegion(coastalOceanChecked, coastalOceanControlStates, setCoastalOceanChecked, setCoastalOceanControlStates)})}}
                    size="small"
                />
            }
            sx={sxStyles.regionCheckBox}
        />
        </FormGroup>
    );
}

/**
* S111LayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) s111Active - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setS111Active - callback for setting s111Active
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*   @prop (bool) greatLakesChecked - State used by OFS/S111 menu item component
*   @prop (func) setGreatLakesChecked - State used by OFS/S111 menu item component
*   @prop (bool) coastalOceanChecked - State used by OFS/S111 menu item component
*   @prop (func) setCoastalOceanChecked - State used by OFS/S111 menu item component
*   @prop (bool) globalOceanChecked - State used by OFS/S111 menu item component
*   @prop (func) setGlobalOceanChecked - State used by OFS/S111 menu item component
*   @prop (obj) greatLakesControlStates - State used by OFS/S111 menu item component
*   @prop (func) setGreatLakesControlStates - State used by OFS/S111 menu item component
*   @prop (obj) coastalOceanControlStates - State used by OFS/S111 menu item component
*   @prop (func) setCoastalOceanControlStates - State used by OFS/S111 menu item component
*   @prop (react component) capUrlsContent - component containing content to display under capUrls tab
*/
function S111LayerMenuItem(props){

    return (
        <LayerMenuItem
            layerName={"s111"}
            label={"Surface Water Currents"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.s111Active}
            setLayerIsActive={props.setS111Active}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"s111"}
                    layersList={props.layersList}
                    infoContent={<Typography variant="caption"> Latest  forecast guidance of water currents from NOAA
                    Operational Oceanographic Forecast Modeling  Systems for oceans, coastal ocean and Great Lakes.
                    Data Source: NOS and NWS/NCEP</Typography>}
                    legendContent={
                        props.styleInfo ?
                            <S111Legend
                                S111StyleInfo={props.styleInfo}
                            />
                        : <CircularProgress />
                    }
                    capUrlsContent={props.capUrlsContent}
                >
                    <OfsControl
                        olLayerState={props.olLayerState}
                        updateOlLayerState={props.updateOlLayerState}
                        greatLakesChecked={props.greatLakesChecked}
                        setGreatLakesChecked={props.setGreatLakesChecked}
                        coastalOceanChecked={props.coastalOceanChecked}
                        setCoastalOceanChecked={props.setCoastalOceanChecked}
                        globalOceanChecked={props.globalOceanChecked}
                        setGlobalOceanChecked={props.setGlobalOceanChecked}
                        greatLakesControlStates={props.greatLakesControlStates}
                        setGreatLakesControlStates={props.setGreatLakesControlStates}
                        coastalOceanControlStates={props.coastalOceanControlStates}
                        setCoastalOceanControlStates={props.setCoastalOceanControlStates}
                    />
                </LayerOptionsContainer>
            </div>
        </LayerMenuItem>
    );
}

export default S111LayerMenuItem;