import React, { useState, Fragment } from 'react';
import { CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

import FeatureInfoAccordion from './feature-info-accordion'
import useHighlightLayer from './hooks/use-highlight-layer'

import { OL_ZINDEXES, ZONE_LAYERS } from '../../config';

const sxStyles = {
    tableCell: {
        fontSize: '0.8em',
    },
    tableHeader: {
        //backgroundColor: '#ddd',
        border: 'none',
    },
    bulletinLink: {
        textDecoration: 'none',
        color: 'primary.main',
        '&:hover': {
            color: 'secondary.light',
        }
    },
};

// JSS styles for non-mui components
const classes = {
    fedBoundariesFeatureInfoContainer: {
        marginTop: '10px',
    },
};

const dateFormat = {
    dateStyle: "medium",
    timeStyle: "long",
    timeZone: "UTC"
};

/**
* Component for rendering results from getFeatureInfo requests
*
* @prop (obj) map - OL map object used for drawing feature info highlights
* @prop (obj) data - json response obj from getFeatureInfo request
* @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
**/
export default function FederalBoundariesFeatureInfo(props) {

    const [productsOpen, setProductsOpen] = useState({});

    const [clearHighlight, drawFeatureHighlight] = useHighlightLayer(props.map, OL_ZINDEXES.federal_boundaries_highlight_layer);

    let layerCount = 0;
    for (const layer in props.data) {
        if (Object.keys(props.data[layer]).length !== 0) {
            layerCount = layerCount + 1;
        }
    }

    // Get all unique zone types
    const zoneTypes = new Set();
    for (const layer in props.data) {
        if (props.data[layer].features) {
            if (props.data[layer].features.length > 0 && props.olLayerState[layer].on) {
                zoneTypes.add(layer);
            }
        }
    }

    // Handle drawing of GeoJSON features returned by getFeatureInfo
    clearHighlight();
    if (Object.keys(props.data).length > 0) {
        for (const layer in props.data) {
            if (props.data && props.data[layer] && props.data[layer].features) {
                for (const feature of props.data[layer].features) {
                    if(productsOpen[layer] && productsOpen[layer] === true) {
                        drawFeatureHighlight(feature);
                    }
                }
            }
        }
    }

    //if not all feature service requests have responded
    if (layerCount < 3) {
        return(<CircularProgress sx={{ml: "10em", mt: "10px"}} />);
    }

    //if no zone features at click location
    if (zoneTypes.size === 0) {
        return(
            <div style={{padding: '40px 30px'}}>
                <Typography sx={{fontSize: '0.8em'}}>No Results Found</Typography>
                <br />
                <Typography sx={{fontSize: '0.8em'}}>No data values, hyperlinks or other information were found for this location.</Typography>
            </div>
        );
    }

    let content = [...zoneTypes].map((zoneType,index) => {
        return (
            <FeatureInfoAccordion key={index}
                styleOverride={{borderLeft : "10px solid " + ZONE_LAYERS[zoneType].color}}
                featureName={ZONE_LAYERS[zoneType].label}
                featureOn={productsOpen[zoneType]}
                setFeatureOn={(opened) => setProductsOpen((prevState) => {return({...prevState, [zoneType]: opened})})}
            >
                <div style={{padding: '5px 0px 10px 0px'}}>
                {
                    props.data[zoneType].features.map((feature, index) => {
                        return(
                            <TableContainer sx={sxStyles.tableContainer} key={index}>
                                <Table size="small">
                                    <TableBody>
                                        {feature.properties.wfo ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>WFO ID:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.wfo}</TableCell>
                                            </TableRow>
                                            : feature.properties.site_id ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>RFC ID:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.site_id}</TableCell>
                                            </TableRow>
                                            : <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Name:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.name}</TableCell>
                                            </TableRow>
                                        }
                                        {feature.properties.region ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Region:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.region}</TableCell>
                                            </TableRow>
                                            : feature.properties.basin_id ?
                                            <Fragment><TableRow>
                                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>RFC Abbreviation:</TableCell>
                                                    <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.basin_id}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Basin Name:</TableCell>
                                                    <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.rfc_name}</TableCell>
                                                </TableRow>
                                            </Fragment>
                                            : null
                                        }
                                        {feature.properties.rfc_city ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Location:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.rfc_city}</TableCell>
                                            </TableRow>
                                            : feature.properties.city ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>WFO Name:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.city}</TableCell>
                                            </TableRow>
                                            : null
                                        }
                                        {feature.properties.st ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>State:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.st}</TableCell>
                                            </TableRow>
                                            : feature.properties.state ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>State:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.state}</TableCell>
                                            </TableRow>
                                            : null
                                        }
                                        {feature.properties.idp_filedate ?
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Service Date:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{Intl.DateTimeFormat("en-US", dateFormat).format(feature.properties.idp_filedate)}</TableCell>
                                            </TableRow>
                                        : null
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        );
                    })
                }
                </div>
            </FeatureInfoAccordion>
        )
    });

    return(
        <div style={classes.fedBoundariesFeatureInfoContainer}>
            {content}
        </div>
    );
}