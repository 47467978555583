import React, { useState } from 'react';
import { Grid, IconButton} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import LayersRoundedIcon from '@mui/icons-material/LayersRounded';
import OpacityIcon from '@mui/icons-material/Opacity';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import BallotIcon from '@mui/icons-material/Ballot';
import LinkIcon from '@mui/icons-material/Link';
import OpacitySlider from '../../ui-controls/opacity-slider.js';


const IconLabelTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        boxShadow: theme.shadows[2],
        fontSize: '65%',
        '& *' : {
            color: theme.palette.primary.light,
        }
    },
}));

const sxStyles = {
    iconButtonLabel: {
        display: 'flex',
        flexDirection: 'column',
        color: 'secondary',
    },
};

const classes = {
    menuItemContainer : {
        padding: '0.25em',
    },
    opacitySliderContainer: {
        padding: '0.5em 2em',
        marginTop: '1.5em',
    },
    legendContainer: {
        marginTop: '1em',
    },
    optionsTabContainer: {
        marginTop: '1em',
    },
    activeLayerDivider: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginLeft: 4,
        marginRight: 4,
        marginTop: 8,
    },
};

/**
* Generic Container for layer options sub menu within layer menu
* Contains tab-like layout with toggle-able tabs for options, opacity, and info
*
* Pass content for options tab (which should be specific to each product) as children
*
* @prop (number) opacity - value of current opacity
* @prop (func) updateLayerOpacities - callback for changing opacity
* @prop (str) layerName = name of layer (should correspond to key in layer_toggles associated with product this component is used for
* @prop (html/react component) infoContent - component containing content to display under info tab (note that options content is passed via children prop)
* @prop (react component) legendContent - component containing content to display under legend tab
* @prop (react component) capUrlsContent - component containing content to display under capUrls tab
*
* Notes on usage;
* - child property contains component to display as layer options. If no child is provided then layer tab will not display
* - For all other tabs:
*   - opacity is always on (does not need to be passed in)
*   - info must be passed in as prop, will currently display tab whether the prop got a value or not
*   - legend  must be passed in as prop, will currently display tab whether the prop got a value or not
*/
function LayerOptionsContainer(props){
    const theme = useTheme();

    let displayLayerTab = false;
    let tabWidth = 3; // width of grid items for 4 tabs (ie. not displaying layers tab by default)
    if (props.children) {
        displayLayerTab = true;
        tabWidth = 2; //width for displaying 5 tabs (layers tab has content to display via children prop)
    }

    const [currentTab, setCurrentTab] = useState((displayLayerTab) ? "layerOptions" : "legend"); // other values: "info", "opacity", "capUrls"

    const layerTabOn = (currentTab === "layerOptions");
    const opacityTabOn = (currentTab === "opacity");
    const infoTabOn = (currentTab === "info");
    const legendTabOn = (currentTab === "legend");
    const capUrlsTabOn = (currentTab === "capUrls");

    return (
        <div style={classes.menuItemContainer} >
            <Grid container>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                        { displayLayerTab ?
                            <Grid item xs={tabWidth}>
                                <IconLabelTooltip arrow title="Layers" placement="bottom">
                                    <IconButton
                                        color='secondary'
                                        size='small'
                                        onClick={() => {setCurrentTab("layerOptions")}}
                                        style={{
                                            border: layerTabOn ? "2px solid " + theme.palette.primary.main : "",
                                        }}
                                    >
                                        <LayersRoundedIcon color='secondary'  className={classes.iconButtonLabel} />
                                    </IconButton>
                                </IconLabelTooltip>
                            </Grid>
                            : null
                        }
                        <Grid item xs={tabWidth}>
                            <IconLabelTooltip arrow title="Legend" placement="bottom">
                                <IconButton
                                    color='secondary'
                                    size='small'
                                    onClick={() => {setCurrentTab("legend")}}
                                    style={{
                                        border: legendTabOn ? "2px solid " + theme.palette.primary.main : "",
                                    }}
                                >
                                    <BallotIcon color='secondary' sx={sxStyles.iconButtonLabel} />
                                </IconButton>
                            </IconLabelTooltip>
                        </Grid>
                        <Grid item xs={tabWidth}>
                            <IconLabelTooltip arrow title="Opacity" placement="bottom">
                                <IconButton
                                    color='secondary'
                                    size='small'
                                    onClick={() => {setCurrentTab("opacity")}}
                                    style={{
                                        border: opacityTabOn ? "2px solid " + theme.palette.primary.main : "",
                                    }}
                                >
                                    <OpacityIcon color='secondary' sx={sxStyles.iconButtonLabel} />
                                </IconButton>
                            </IconLabelTooltip>
                        </Grid>
                        <Grid item xs={tabWidth}>
                            <IconLabelTooltip arrow title="Info" placement="bottom">
                                <IconButton
                                    color='secondary'
                                    size='small'
                                    onClick={() => {setCurrentTab("info")}}
                                    style={{
                                        border: infoTabOn ? "2px solid " + theme.palette.primary.main : "",
                                    }}
                                >
                                    <InfoRoundedIcon color='secondary' sx={sxStyles.iconButtonLabel}/>
                                </IconButton>
                            </IconLabelTooltip>
                        </Grid>
                        <Grid item xs={tabWidth}>
                            <IconLabelTooltip arrow title="Map Services" placement="bottom">
                                <IconButton
                                    color='secondary'
                                    size='small'
                                    onClick={() => {setCurrentTab("capUrls")}}
                                    style={{
                                        border: capUrlsTabOn ? "2px solid " + theme.palette.primary.main : "",
                                    }}
                                >
                                    <LinkIcon color='secondary' sx={sxStyles.iconButtonLabel}/>
                                </IconButton>
                            </IconLabelTooltip>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item xs={12}>
                    <div style={classes.activeLayerDivider} ></div>
                        { (layerTabOn) ?
                            <div style={classes.optionsTabContainer}>

                                {props.children}
                            </div>: null
                        }
                        { (legendTabOn) ?
                            <div style={classes.legendContainer}>
                                {props.legendContent}
                            </div>: null
                        }
                        { (opacityTabOn) ?
                            <div style={classes.opacitySliderContainer}>
                                <OpacitySlider
                                    opacity={props.opacity}
                                    updateLayerOpacities={props.updateLayerOpacities}
                                    layerName={props.layerName}
                                />
                            </div> : null
                        }
                        { (infoTabOn) ?
                            <div style={classes.optionsTabContainer}>
                                {props.infoContent}
                            </div>: null
                        }
                        { (capUrlsTabOn) ?
                            <div style={classes.optionsTabContainer}>
                                {props.capUrlsContent}
                            </div>: null
                        }
                    </Grid>
            </Grid>
        </div>
    );
}

// Povide link to API info for old nowcoast prior to creating new info pages/scheme???
// <a href="https://nowcoast.noaa.gov/arcgis/rest/services/nowcoast/wwa_meteocean_tropicalcyclones_trackintensityfcsts_time/MapServer" target="_blank" rel="noopener noreferrer">Additional Info</a>

export default LayerOptionsContainer;
