import React from 'react';
import { Typography, Grid } from '@mui/material';
import { ZONE_FORECAST_LAYERS } from '../../../config.js';

const sxStyles = {
    label: {
        color: '#252525',
        fontSize: '80%',
        mb: 1
    },
    legendLabel: {
        color: '#252525',
        fontSize: '88%',
        textAlign: 'center'
    },
    gridContainer: {
        columnGap: 2,
        direction: "row",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    legendContainer: {
        spacing: 3,
        paddingBottom: 1,
        justifyContent: "center"
    }
};

const classes = {
    legendIMG:{
        width: '100%'
    }
};

/**
* Displays legend image with a label above it
*
* @prop olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*
*/
function ZoneForecastsLegend(props){
    //external WMS
    const boundariesJSON = "https://mapservices.weather.noaa.gov/static/rest/services/nws_reference_maps/nws_reference_map/MapServer/";

    let marineContent = Object.keys(ZONE_FORECAST_LAYERS).map((forecastType,index) => {
        if(props.olLayerState[forecastType].on && ZONE_FORECAST_LAYERS[forecastType].group === 'marine') {
            let mapServerLink = boundariesJSON
            return (
                <Grid container sx={sxStyles.gridContainer} key={ZONE_FORECAST_LAYERS[forecastType].id}>
                    <Grid item >
                        <img style={classes.legendIMG} alt="" src={mapServerLink + ZONE_FORECAST_LAYERS[forecastType].legend_img} />
                    </Grid>
                    <Grid item >
                        <Typography sx={sxStyles.label} >{ZONE_FORECAST_LAYERS[forecastType].label}</Typography>
                    </Grid>
                </Grid>
            )
        } else {return (null)};
    })

    let inlandContent = Object.keys(ZONE_FORECAST_LAYERS).map((forecastType,index) => {
        if(props.olLayerState[forecastType].on && ZONE_FORECAST_LAYERS[forecastType].group === 'inland') {
            return (
            <Grid container sx={sxStyles.gridContainer} key={ZONE_FORECAST_LAYERS[forecastType].id}>
                <Grid item >
                    <img style={classes.legendIMG} alt="" src={boundariesJSON + ZONE_FORECAST_LAYERS[forecastType].legend_img} />
                </Grid>
                <Grid item >
                    <Typography sx={sxStyles.label} >{ZONE_FORECAST_LAYERS[forecastType].label}</Typography>
                </Grid>
            </Grid>)
        } else {return (null)};
    })

    return (
        <div style={{marginLeft:'0.5em' }}>
            <Typography sx={{...sxStyles.label, fontSize: '100%'}}>Zone Text Forecasts</Typography>
            {marineContent.every(Element => Element === null) ?
            null
            : <Typography sx={{...sxStyles.label, fontSize: '90%'}}>Marine Weather Forecast Zones</Typography> }
            {beachLegend(props)}
            {marineContent}
            {inlandContent.every(Element => Element === null) ?
            null
            : <Typography sx={{...sxStyles.label, fontSize: '90%'}}>Inland Weather Forecast Zones</Typography> }
            {inlandContent}
        </div>
    );
}

export default ZoneForecastsLegend;

export function beachLegend(props) {
    if(props.olLayerState["beach_forecasts_day1"].on || props.olLayerState["beach_forecasts_day2"].on) {
        let mapServerLink = "https://mapservices.weather.noaa.gov/vector/rest/services/outlooks/marine_beachforecast_summary/MapServer/"
        let symbolLink = ZONE_FORECAST_LAYERS["beach_forecasts_day1"].legend_img
        return(
            <Grid container sx={sxStyles.legendContainer} key={ZONE_FORECAST_LAYERS["beach_forecasts_day1"].id}>
                <Typography sx={sxStyles.legendLabel}>Rip Current/Swim Risk</Typography>
                <Grid container sx={sxStyles.gridContainer}>
                    <Grid item >
                        <img style={classes.legendIMG} alt="" src={mapServerLink + symbolLink.Low} />
                    </Grid>
                    <Grid item >
                        <Typography sx={sxStyles.label} >Low</Typography>
                    </Grid>
                </Grid>
                <Grid container sx={sxStyles.gridContainer}>
                    <Grid item >
                        <img style={classes.legendIMG} alt="" src={mapServerLink + symbolLink.Moderate} />
                    </Grid>
                    <Grid item >
                        <Typography sx={sxStyles.label} >Moderate</Typography>
                    </Grid>
                </Grid>
                <Grid container sx={sxStyles.gridContainer}>
                    <Grid item >
                        <img style={classes.legendIMG} alt="" src={mapServerLink + symbolLink.High} />
                    </Grid>
                    <Grid item >
                        <Typography sx={sxStyles.label} >High</Typography>
                    </Grid>
                </Grid>
                <Grid container sx={sxStyles.gridContainer}>
                    <Grid item >
                        <img style={classes.legendIMG} alt="" src={mapServerLink + symbolLink.NotProvided} />
                    </Grid>
                    <Grid item >
                        <Typography sx={sxStyles.label} >Not Provided</Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    } else {return (null)};
}