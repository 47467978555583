import React, { useState } from 'react';
import { flushSync } from 'react-dom';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Slider from '@mui/material/Slider';
import PropTypes from 'prop-types';


// So much cargo culting here... can tinker with custom tooltip examples in mui5 docs
const ValueLabelTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        boxShadow: theme.shadows[2],
        fontSize: '70%',
        '& *' : {
            color: theme.palette.primary.main,
        }
    },
}));


function AnimationSpeedSliderValueLabel(props) {
    const { children, open, value } = props;
    return (
        <ValueLabelTooltip arrow open={open} enterTouchDelay={0} placement="top" title={value} >
            {children}
        </ValueLabelTooltip>
    );
}


AnimationSpeedSliderValueLabel.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
};

const NowCoastAnimationSpeedSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-thumb': {
        height: 15,
        width: 15,
        //marginTop: -5,
        boxShadow: theme.shadows[2],
        backgroundColor: theme.palette.primary.main,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'rgba(0,0,0,0.6)',
        transition: 'none'
    },
}));

const classes = {
    sliderDiv: {
        width: '100%', // Slider grows to fill its container - container size is dictated here, but its nested in a grid item whos size should be relied on more
    },
};

// ToDo: verify that these flushSync calls properly remove tooltip lag in all cases
// Partial result: you cant use flush sync in a lifecycle method (ie. when the component is already rendering)
// but putting in handleSliderOnChange callback doesnt cause errors.... doesnt really fix the issue on its own tho yet
function AnimationSpeedSlider(props) {

    // Local state for value of time slider (remember previous prop val to see if AnimationSpeed was changed from elsewhere)
    const [animationSpeedState, setAnimationSpeedState] = useState({
        sliderVal: typeof props.animationSpeed === "number" ? props.animationSpeed : 2.0,
        prevPropSliderVal: 0
    });

    const handleSliderOnChange = (e, value) => {
        flushSync(() => {
            props.setAnimationSpeed(value);
            setAnimationSpeedState({
                ...animationSpeedState,
                'sliderVal': value
            });
        });
    };

    // Adjust local state(AnimationSpeed) connected to slider on render to match AnimationSpeed passed in by props
    if (typeof props.animationSpeed === "number") {
        if (props.animationSpeed !== animationSpeedState.prevPropSliderVal) {
            setAnimationSpeedState({
                'sliderVal': props.animationSpeed,
                'prevPropSliderVal': props.animationSpeed
            });
        }
    }

    return (
        <div style={classes.sliderDiv}>
            <NowCoastAnimationSpeedSlider
                value={typeof animationSpeedState.sliderVal === "number" ? animationSpeedState.sliderVal : 0}
                min={0.2}
                max={2.6}
                step={0.2}
                onChange={handleSliderOnChange}
                valueLabelDisplay="auto" // defaults to "off" in mui5 so must be set
                components={{
                    ValueLabel: AnimationSpeedSliderValueLabel,
                }}
                aria-label="custom thumb label"
            />
        </div>
    );
}
export default AnimationSpeedSlider;
