import React from "react";
import { Typography, CircularProgress } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';

import LayerMenuItem from './layer-menu-item.js';

import LayerOptionsContainer from './layer-options-container.js';
import PathogenLegend from '../legend-menu-items/pathogen-legend.js';

const classes = {
    menuItemBodyContainer: {
        padding: '0.5em',
    },
};

/**
* RegionOptions: Check boxes for toggling layers
*
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*/

function RegionOptions(props) {

    return(
        <FormGroup>
            <FormLabel><Typography variant="overline">Gridded Model Forecast Guidance<br></br></Typography></FormLabel>
            <Typography variant="caption">Vibrio Vulnificus Probability of Occurrence</Typography>
            <FormControlLabel
                control={<Checkbox size="small" checked={props.olLayerState["vibrio_cbofs"].on} onChange={() => {props.updateOlLayerState({'on': !props.olLayerState["vibrio_cbofs"].on}, "vibrio_cbofs")}} />}
                label={<Typography variant="caption">Chesapeake Bay</Typography>}
            />
        </FormGroup>
    );

}

/**
* PathogenLayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) pathogenActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setPathogenActive - callback for setting pathogenActive
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*   @prop (react component) capUrlsContent - component containing content to display under capUrls tab
*/
function PathogenLayerMenuItem(props){

    return (
        <LayerMenuItem
            layerName={"pathogen"}
            label={"Marine Pathogen"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.pathogenActive}
            setLayerIsActive={props.setPathogenActive}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"pathogen"}
                    layersList={props.layersList}
                    infoContent={<Typography variant="caption"> Latest model forecast guidance of probability of occurrence of the 
                    Vibrio vulnificus (Vv), a marine pathogen which can cause illness, in the Chesapeake Bay. The Vv forecast guidance 
                    is issued once per day for Chesapeake Bay based on predictions from the 0000 UTC forecast cycle of the NOS Chesapeake 
                    Bay Operational Forecast System. The guidance includes predictions for the present day (Day 1) and the next day (Day 2). 
                    The Day 1 forecast guidance is based on a daily average of water temperature and salinity to remove tidal effects and 
                    diurnal changes and is valid around 06 UTC (2:00 AM EDT) of the present day and the Day 2 guidance is valid centered at 
                    06 UTC for the next day. A color scale from dark blue to yellow is used to depict the probability of occurrence from 0 
                    to 100% at 2% increments. Data Source: NOS/CO-OPS and NCCOS.</Typography>}
                    legendContent={
                        props.styleInfo ?
                            <PathogenLegend
                                PathogenStyleInfo={props.styleInfo}
                            />
                        : <CircularProgress />
                    }
                    capUrlsContent={props.capUrlsContent}
                >
                <RegionOptions
                        olLayerState={props.olLayerState}
                        updateOlLayerState={props.updateOlLayerState}
                        activeStylesList={props.activeStylesList}
                        PathogenStyleInfo={props.styleInfo}
                    />
                </LayerOptionsContainer>
            </div>
        </LayerMenuItem>
    );
}

export default PathogenLayerMenuItem;