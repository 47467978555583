import { findIndexBS } from '../../utilities/utilities.js';

/**
* DEPRECATED (ticks no longer generated with time resolutions)
*
* Create timeValues/timeSliderTicks (a list of all discrete values used within the
* time slider component - the only values the times slider can have) from the timeValuesUnion
* (the union of all possible timeValues across all datasets that are animated, retrieved
* from get capabilities)
*/
export function makeTimeSliderTicks(timeValuesUnion, timeSliderResolution) {
    if (!timeValuesUnion) return;
    const timeVals = timeValuesUnion.slice();
    if (!timeSliderResolution) {
        return({timeVals});
    }
    const startTime = timeVals[0];
    const endTime = timeVals[timeVals.length - 1];
    const timeTicks = [startTime];
    let i = 0;
    while (timeTicks[i] < endTime - (Math.floor(timeSliderResolution/2))) {
        timeTicks.push(timeTicks[i] + timeSliderResolution);
        i++;
    }
    return(timeTicks);
}

/**
*   Returns the next index (forward by default, or backward) from the array of timeValues based on
*   previous selectedTimeIndex or, if the selectedTimeIndex is unknown, based on the selectedTime
*
*   @param (int) selectedTime - Current time (value must exist within timeValues so that its index can be found)
*   @param (int) selectedTimeIndex - index of current time in timeValues array (may be null)
*   @param ([int]) timeValues - Array of timeValues
*   @param (bool) stepBackward - defined if function should decrease index, null/false/undefined if index should increase (move time forward)
*/
export function stepAnimationIndex(selectedTime, selectedTimeIndex, timeValues, stepBackward) {
        if (!selectedTime || !timeValues) return null;

        let curTimeIndex = selectedTimeIndex;
        if (!typeof(curTimeIndex) === "number" || !curTimeIndex >= 0) {
            curTimeIndex = findIndexBS(selectedTime, timeValues, 0, timeValues.length - 1);
            if (!typeof(curTimeIndex) === "number") curTimeIndex = 0;
        }

        if (stepBackward) {
            if (curTimeIndex <= 0) {
                if (timeValues.length >= 1 ) {
                    curTimeIndex = timeValues.length - 1;
                } else {
                    curTimeIndex = 0;
                }
            } else {
                curTimeIndex -= 1;
            }
        }else{//forward
            if (curTimeIndex >= timeValues.length - 1) {
                curTimeIndex = 0;
            } else {
                curTimeIndex += 1;
            }
        }

        return curTimeIndex;
    };
