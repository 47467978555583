import React, { useState } from 'react';
import { Typography, TableContainer, Table, TableRow, TableCell, TableBody } from '@mui/material';
import FeatureInfoAccordion from './feature-info-accordion';
import SurfaceObsLegend from '../menu/legend-menu-items/surface-obs-legend.js';
import { parseObsTimestamp, parseMETARWeather, parseMETARClouds } from '../../utilities/utilities.js';

const sxStyles = {
    tableCell: {
        fontSize: '0.8em',
    },
    fieldCell: {
        fontSize: '0.8em',
        minWidth: 175
    },
    links: {
        '& a': {
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            },
            '&:visited': {
                color: 'primary.main'
            }
        }
    }
};

const classes ={
    FeatureInfoContainer: {
        padding: '10px'
    },
    LegendContainer: {
        paddingTop: '0.5em'
    }
};

const dateFormat = {
    dateStyle: "medium",
    timeStyle: "long"
};

const cloudCoverText = {
    "10." : "Overcast",
    "OVC" : "Overcast",
    "8.8" : "7/8 Sky Covered",
    "BKN" : "Mostly Cloudy",
    "7.5" : "Mostly Cloudy",
    "6.3" : "5/8 Sky Covered",
    "5.0" : "Partly Cloudy",
    "3.7" : "3/8 Sky Covered",
    "2.5" : "Scattered Clouds",
    "SCT" : "Scattered Clouds",
    "1.2" : "Few Clouds",
    "FEW" : "Few Clouds",
    "0.0" : "Clear",
    "SKC" : "Clear",
    "CLR" : "Clear",
    "VV" : "Vertical Visibility"
}

/**
* Component for rendering results from getFeatureInfo wms requests
*
* @prop (obj) data - json response obj from getFeatureInfo request
**/
export default function SurfaceObsFeatureInfo(props) {

    const [productsOpen, setProductsOpen] = useState({});
    let singleStationFlag = false;

    if (Object.keys(props.data[0]).length === 0) {
        return(
            <div style={{padding: '40px 30px'}}>
                <Typography sx={{fontSize: '0.8em'}}>No Results Found</Typography>
                <br />
                <Typography sx={{fontSize: '0.8em'}}>No data was found for this location.</Typography>
            </div>
        );
    }

    if(Object.keys(props.data).length === 1) {
        singleStationFlag = true;
    }

    let content = [...props.data].map((station,index) => {
        if(singleStationFlag) {
            return getStationTable(station, index);
        } else {
            return (
                <FeatureInfoAccordion key={index}
                    featureName={station.getId()}
                    featureOn={productsOpen[station.getId()]}
                    setFeatureOn={(opened) => setProductsOpen((prevState) => {return({...prevState, [station.getId()]: opened})})}
                >
                    {getStationTable(station, index)}
                </FeatureInfoAccordion>
            );
        }
    });

    return(
        <div style={classes.FeatureInfoContainer}>
            <Typography align="center" sx={{'paddingBottom': '5px'}}>Surface & Marine Weather Observations</Typography>
            {content}
            <div style={classes.LegendContainer} align="center">
                <SurfaceObsLegend/>
            </div>
        </div>
    );
}

function getStationTable(station, index) {
    let weatherString = "";
    let cloudCover = station.getProperties()['cloudcover'];
    let endTags = [];
    if(station.getProperties()['rawdata'] && station.getProperties()['rawdata'] !== "null" && station.getProperties()['rawdata'] !== station.getId()) {
        let metarWeather = parseMETARWeather(station.getProperties()['rawdata']);
        let metarClouds = parseMETARClouds(station.getProperties()['rawdata']);
        if(metarWeather) {
            for(const weather of metarWeather) {
                if(weather.abbreviation === 'VC') {
                    endTags.push(weather.meaning)
                } else {
                    weatherString = weatherString + " " + weather.meaning.toUpperCase();
                    if(endTags.length > 0) {
                        weatherString = weatherString + " " + endTags.pop().toUpperCase();
                    }
                }
            }
        }
        if(metarClouds && metarClouds !== "null") {
            cloudCover = metarClouds;
        }
    }

    return (
        <div key={index} style={{padding: '5px 0px 10px 0px'}}>
            <TableContainer key={index}>
                <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Station ID:</TableCell>
                        <TableCell align="left" sx={sxStyles.tableCell}>{station.getId()}</TableCell>
                    </TableRow>
                    { !station.getProperties()['location'] || station.getProperties()['location'] === "" ?
                        null :
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Station Name:</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{station.getProperties()['location']}</TableCell>
                        </TableRow>
                    }
                    { station.getProperties()['tblname'] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Station Type:</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{station.getProperties()['tblname']}</TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['timeobs'] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Observation Time:</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{Intl.DateTimeFormat("en-US", dateFormat).format(parseObsTimestamp(station.getProperties()['timeobs']))}</TableCell>
                        </TableRow>
                        : null
                    }
                    { cloudCover && cloudCover !== "null" && cloudCoverText[cloudCover] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Cloud Cover:</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{cloudCoverText[cloudCover]}</TableCell>
                        </TableRow>
                        : null
                    }
                    { weatherString.length > 0 ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Present Weather:</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{weatherString}</TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['temperature'] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Air Temperature (&deg;F):</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{Math.round(parseFloat(station.getProperties()['temperature']))}</TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['visibility'] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Visibility (mi):</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{Math.round(parseFloat(station.getProperties()['visibility']))}</TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['dewpoint'] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Dew Point Temperature (&deg;F):</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{Math.round(parseFloat(station.getProperties()['dewpoint']))}</TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['sst'] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Sea Surface Temperature (&deg;F):</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{Math.round(parseFloat(station.getProperties()['sst']))}</TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['waveheight'] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Significant Wave Height (ft):</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{station.getProperties()['waveheight'].toFixed(1)}</TableCell>
                        </TableRow>
                        : null
                    }
                    { (station.getProperties()['preschange'] || station.getProperties()['preschange'] === 0) && station.getProperties()['preschange'] !== 9999 ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>3-Hour Pressure Change (mb):</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{station.getProperties()['preschange'].toFixed(1)}</TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['sealevelpress'] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Mean Sea Level Pressure (mb):</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{station.getProperties()['sealevelpress'].toFixed(1)}</TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['windspeed'] || station.getProperties()['windspeed'] === 0 ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Wind Velocity (kts):</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{station.getProperties()['windspeed'].toFixed(1)}</TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['windgust'] ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Wind Gust Speed (kts):</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{station.getProperties()['windgust'].toFixed(1)}</TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['winddir'] || station.getProperties()['winddir'] === 0?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Wind Direction:</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{getCardinalFromDegrees(station.getProperties()['winddir'])}</TableCell>
                        </TableRow>
                        : null
                    }
                    { (Array.from(station.getId())[0] === 'K' || Array.from(station.getId())[0] === 'C' || Array.from(station.getId())[0] === 'M') && station.getProperties()['tblname'] !== 'MARITIME' ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Link:</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>
                                <Typography variant='caption' sx={sxStyles.links}>
                                    <a href={"https://forecast.weather.gov/data/obhistory/" + station.getId() + ".html"}
                                    target="_blank" rel="noopener noreferrer">View Past Observations</a>
                                </Typography>
                            </TableCell>
                        </TableRow>
                        : null
                    }
                    { station.getProperties()['tblname'] === 'MARITIME' && station.getId().length === 5 && (station.getId()[4] >= '0' && station.getId()[4] <= '9') ?
                        <TableRow>
                            <TableCell component="th" scope="row" sx={sxStyles.fieldCell}>Link:</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>
                                <Typography variant='caption' sx={sxStyles.links}>
                                    <a href={"https://www.ndbc.noaa.gov/station_page.php?station=" + station.getId()}
                                    target="_blank" rel="noopener noreferrer">View Past Observations</a>
                                </Typography>
                            </TableCell>
                        </TableRow>
                        : null
                    }
                </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

function getCardinalFromDegrees(angle) {
    if ((angle > 348.75 && angle <= 360 ) || (angle >= 0 && angle <= 11.25 )) {
        return "N";
    } else if (angle > 11.25 && angle <= 33.75) {
        return "NNE";
    } else if (angle > 33.75 && angle <= 56.25) {
        return "NE";
    } else if (angle > 56.25 && angle <= 78.75) {
        return "ENE";
    } else if (angle > 78.75 && angle <= 101.25 ) {
        return "E";
    } else if (angle > 101.25 && angle <= 123.75) {
        return "ESE";
    } else if (angle > 123.75 && angle <= 146.25) {
        return "SE";
    } else if (angle > 146.25 && angle <= 168.75 ) {
        return "SSE";
    } else if (angle > 168.75 && angle <= 191.25) {
        return "S";
    } else if (angle > 191.25 && angle <= 213.75) {
        return "SSW";
    } else if (angle > 213.75 && angle <= 236.25) {
        return "SW";
    } else if (angle > 236.25 && angle <= 258.75) {
        return "WSW";
    } else if (angle > 258.75 && angle <= 281.25) {
        return "W";
    } else if (angle > 281.25 && angle <= 303.75 ) {
        return "WNW";
    } else if (angle > 303.75 && angle <= 326.25) {
        return "NW";
    } else { // (angle > 326.25 && angle <= 348.75)
        return "NNW";
    }
}