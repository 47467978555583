import {React, Fragment} from 'react';
import {Typography} from '@mui/material';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';

import LayerMenuItem from './layer-menu-item.js';
import LayerOptionsContainer from './layer-options-container.js';
import FederalBoundariesLegend from '../legend-menu-items/federal-boundaries-legend.js';
import { ZONE_LAYERS } from '../../../config.js';

const classes = {
    menuItemBodyContainer: {
        padding: '0.5em',
    },
};

const sxStyles = {
    links: {
        '& a': {
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            },
            '&:visited': {
                color: 'primary.main'
            }
        }
    },
};

/**
* BoundaryOptions: Check boxes for toggling layers from federal_agency_boundaries
*
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*/

function BoundaryOptions(props) {

    let extContent = Object.keys(ZONE_LAYERS).map((zoneType,index) => {
        if (ZONE_LAYERS[zoneType].type === 'external') {
            return (
                <FormControlLabel key={ZONE_LAYERS[zoneType].id}
                    control={<Checkbox size="small" checked={props.olLayerState[zoneType].on} onChange={() => {props.updateOlLayerState({'on': !props.olLayerState[zoneType].on}, zoneType)}} />}
                    label={<Typography variant="caption">{ZONE_LAYERS[zoneType].label}</Typography>}
                />
            )
        }
        else {
            return (null)
        }
    })

    let intContent = Object.keys(ZONE_LAYERS).map((zoneType,index) => {
        if (ZONE_LAYERS[zoneType].type === 'internal') {
            return (
                <FormControlLabel key={ZONE_LAYERS[zoneType].id}
                    control={<Checkbox size="small" checked={props.olLayerState[zoneType].on} onChange={() => {props.updateOlLayerState({'on': !props.olLayerState[zoneType].on}, zoneType)}} />}
                    label={<Typography variant="caption">{ZONE_LAYERS[zoneType].label}</Typography>}
                />
            )
        }
        else {
            return (null)
        }
    })

    return(
        <FormGroup>
            <FormLabel>
            <Typography variant="overline">External Services</Typography></FormLabel>
            {extContent}
            <FormLabel><Typography variant="overline">nowCOAST Services</Typography></FormLabel>
            {intContent}
        </FormGroup>
    );
}

/**
* FedAgencyBoundariesLayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) productActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setProductActive - callback for setting encActive
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state, style and layer params, as well as current source
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*   @prop (react component) capUrlsContent - component containing content to display under capUrls tab
*/
function FedAgencyBoundariesLayerMenuItem(props){

    let zoneAbstracts = Object.keys(ZONE_LAYERS).map((zoneType,index) => {
        return(
            <Fragment key={ZONE_LAYERS[zoneType].id}>
                <Typography variant="caption">{ZONE_LAYERS[zoneType].abstract}</Typography>
                <br /><br />
            </Fragment>
        )
    })

    let zoneLinks = Object.keys(ZONE_LAYERS).map((zoneType,index) => {
        if (ZONE_LAYERS[zoneType].type === 'external') {
            return (
                <Fragment key={ZONE_LAYERS[zoneType].id}>
                    <Typography variant='caption' sx={sxStyles.links}>
                        <a href={"https://mapservices.weather.noaa.gov/static/rest/services/nws_reference_maps/nws_reference_map/MapServer/" + ZONE_LAYERS[zoneType].id}
                        target="_blank" rel="noopener noreferrer">{ZONE_LAYERS[zoneType].label}</a>
                    </Typography>
                    <br />
                </Fragment>
            )
        }
        else {
            return (null)
        }
    })

    const links = <>
        <Typography variant='caption'>External Services</Typography>
        <br />
        {zoneLinks}
        <br />
        <Typography variant='caption'>nowCOAST Services</Typography>
        {props.capUrlsContent}
    </>;

    return (
        <LayerMenuItem
            layerName={"federal_agency_boundaries"}
            label={"Federal Boundaries"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.productActive}
            setLayerIsActive={props.setProductActive}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"federal_agency_boundaries"}
                    layersList={props.layersList}
                    infoContent={zoneAbstracts}
                    legendContent={
                        <FederalBoundariesLegend
                            olLayerState={props.olLayerState}
                        />
                    }
                    capUrlsContent={links}
                >
                    <BoundaryOptions
                        olLayerState={props.olLayerState}
                        updateOlLayerState={props.updateOlLayerState}
                    />
                </LayerOptionsContainer>
            </div>
        </LayerMenuItem>
    );
}

export default FedAgencyBoundariesLayerMenuItem;