import React from 'react';
import { Typography, ListSubheader } from '@mui/material';

const sxStyles = {
    label: {
        color: '#252525',
        fontSize: '90%',
    },
};

const classes = {
    label: {
        color: '#252525',
    },
    legendIMG:{
        maxWidth: '85%',
        maxHeight: '18em'
    },
};


/**
* Displays legend image with a label above it
*
* @prop (array) cycloneLayerList - current tropical cyclone layers that are turned on (derived from olLayerState top-level state obj in app.js)
* @prop (obj) tropicalCycloneStyleInfo - legend info that pertains only to tropical cyclones (derived from top-level state obj: styleInfo)
* @prop (string) activeTropicalSSLayer - current tropical storm surge layer being displayed (this string is also a source name and OL source layers parameter value)
*   (the value for activeTropicalSSLayer is derived from olLayerState[layerName].layersParam within app.js state, but only the actual value should be passed to this component
*
* NOTE: dynamic styles not enabled for this layer. This component always grabs first available legend with [0]
*/
function TropicalCycloneLegend(props){

    let cycloneLegendItems = null;
    if (props.tropicalCycloneStyleInfo && props.cycloneLayerList && props.cycloneLayerList instanceof Array) {
        cycloneLegendItems = props.cycloneLayerList.map(layerName => {
            return (
                <div key={layerName} component="div" style={classes.label}>
                    <Typography sx={{mb: '5px', ...sxStyles.label}}>{props.tropicalCycloneStyleInfo[layerName][0].title}</Typography>
                    <img
                        style={classes.legendIMG} alt=""
                        src={props.tropicalCycloneStyleInfo[layerName][0].url}
                    />
                </div>
            );
        });
    }

    let ssLegendItems = null;
    if (props.tropicalCycloneStyleInfo && props.tropicalCycloneStyleInfo[props.activeTropicalSSLayer] ) {
        ssLegendItems = <div>
            <Typography sx={{mt: '10px', mb: '5px', ...sxStyles.label}}>{props.tropicalCycloneStyleInfo[props.activeTropicalSSLayer][0].title}</Typography>
            <img style={classes.legendIMG} alt="" src={props.tropicalCycloneStyleInfo[props.activeTropicalSSLayer][0].url} />
        </div>;
    }

    return (
        <div>
        <Typography>Tropical Cyclones & Storm Surge</Typography>
            {cycloneLegendItems}
            {ssLegendItems}
        </div>
    );
}

export default TropicalCycloneLegend;
