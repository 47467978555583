import React, { useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';

import { OFS_SFC_CURRENTS_INFO } from '../../config';
import S111Legend from '../menu/legend-menu-items/ofs-surface-currents-legend';

const classes = {
    OFSFeatureInfoContainer: {
        padding: '10px',
    }
};

/**
* Component for rendering results from getFreatureInfo wms requests
*
* @prop (obj) data - json response obj from getFreatureInfo request
* @prop (obj) styleInfo - style info obj for s111/ofs sfc currents
* @prop (obj) olLayerState - state info for each layer
**/
export default function OFSFeatureInfo(props) {

    let dataDoneLoading = true; // data is loading until there is a props.data[regionKey].features is defined for all regions
    for (const layerName in props.data) {
        if (!props.data[layerName].hasOwnProperty('features')) {
            // features is not set
            dataDoneLoading = false;
            break;
        }
    }

    if (!dataDoneLoading) {
        return(<CircularProgress sx={{margin: '70px 45%'}} />);
    }

    const ofsReturnedFeatures = Object.entries(props.olLayerState).map(([layerName, info],i) => {
        if (!props.data[layerName] || !props.olLayerState[layerName].on ) {
            return null;
        }

        if (props.data[layerName] && props.data[layerName].features && props.data[layerName].features.length > 0) {
            const speed = Number.parseFloat(props.data[layerName].features[0].properties.Band1).toFixed(0);
            const direction = Number.parseFloat(props.data[layerName].features[0].properties.Band2).toFixed(0);
            return (
                <div style={{
                    borderBottom: '1px solid rgba(0, 0, 0, .125)',
                    marginLeft: 10, marginRight: 10, paddingBottom: 20
                    }}
                    key={i}
                >
                    <Typography>{OFS_SFC_CURRENTS_INFO[layerName].label}</Typography>
                    <Typography sx={{fontSize: '0.8em'}}>Speed (knots): {(speed === "-9999") ? "Missing/NaN" : speed}</Typography>
                    <Typography sx={{fontSize: '0.8em'}}>Direction (degrees): {(direction === "-9999") ? "Missing/NaN" : direction}</Typography>
                </div>
            );
        } else {
            return (
                <div style={{
                    borderBottom: '1px solid rgba(0, 0, 0, .125)',
                    marginLeft: 10, marginRight: 10, paddingBottom: 20
                    }}
                    key={i}
                >
                    <Typography>{OFS_SFC_CURRENTS_INFO[layerName].label}</Typography>
                    <Typography sx={{fontSize: '0.8em'}}>No feature info returned for this time/location.</Typography>
                </div>
            );
        }
    });

    if (ofsReturnedFeatures.length > 0) {
        return(
            <div style={classes.OFSFeatureInfoContainer}>
                <Typography >Surface Currents</Typography>
                <div style={{
                    borderBottom: '1px solid rgba(0, 0, 0, .125)',
                    marginLeft: 10, marginRight: 10, paddingBottom: 10
                    }}
                >
                </div>
                <br />
                {ofsReturnedFeatures}
                <br />
                {   (props.styleInfo) ?
                    <S111Legend
                        S111StyleInfo={props.styleInfo}
                    />
                    : null
                }
            </div>
        );
    } else {
        return(
            <div style={{padding: '50px 20px'}}>
                <Typography sx={{fontSize: '0.8em'}} >No feature info found at the specified location. Please try again in another location.</Typography>
            </div>
        );
    }
}
