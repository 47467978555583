import React from 'react';
import { stepAnimationIndex } from './time-utilities.js';

/**
*   Component manages set interval for running animation
*/
class AnimationInterval extends React.Component {
    constructor (props) {
        super ();

        this.animationInterval = null;
        this.prevAnimationSpeed = null;

        this.animate = this.animate.bind(this);
        this.handleInterval = this.handleInterval.bind(this);
    };

    animate() {
        const newTimeValuesIndex = stepAnimationIndex(this.props.selectedTime, this.props.selectedTimeIndex, this.props.timeValues)
        if (typeof(newTimeValuesIndex) === "number") {
            this.props.setSelectedTime(this.props.timeValues[newTimeValuesIndex]);
            this.props.setSelectedTimeIndex(newTimeValuesIndex);
        }
    }

    handleInterval(resetInterval) {
        if (this.props.playing) {
            if (!this.animationInterval) {
                this.animationInterval = setInterval(() => {
                    this.animate();
                }, 1000 / this.props.animationSpeed);
            } else if (resetInterval) { // for resetting the interval if it was already playing but the animation speed was changed
                clearInterval(this.animationInterval);
                this.animationInterval = null;
                this.animationInterval = setInterval(() => {
                    this.animate();
                }, 1000 / this.props.animationSpeed);
            }
        } else {
            if (this.animationInterval) {
                clearInterval(this.animationInterval);
                this.animationInterval = null;
            }
        }
     }

    render() {
        if (this.prevAnimationSpeed !== this.props.animationSpeed) {
            this.handleInterval(true);
        } else {
            this.handleInterval(false);
        }
        this.prevAnimationSpeed = this.props.animationSpeed
        return null;
    };
}

export default AnimationInterval;