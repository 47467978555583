import React, { useState } from 'react';
import { CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from '@mui/material';

import { SATELLITE_INFO } from '../../config';
const classes = {
    satelliteFeatureInfoContainer: {
        padding: '10px',
    }
};

const sxStyles = {
    tableContainer: {
    },
    tableCell: {
        fontSize: '0.65em',
    },
    tableHeader: {
        //backgroundColor: '#ddd',
        border: 'none',
    },
    bulletinLink: {
        textDecoration: 'none',
        color: 'primary.main',
        '&:hover': {
            color: 'secondary.light',
        }
    },
};

/**
* Component for rendering results from getFreatureInfo wms requests
*
* @prop (obj) data - json response obj from getFreatureInfo request
* @prop (str) activeVariable - satellite variable being displayed. Corresponds with source names
**/
export default function SatelliteFeatureInfo(props) {

    if (Object.keys(props.data).length === 0) {
        return(<CircularProgress sx={{margin: '70px 20px'}} />);
    }


    if (props.data.satellite.features && props.data.satellite.features.length > 0 ) {
        const layer_name = SATELLITE_INFO[props.activeVariable].layerName
        const label = SATELLITE_INFO[props.activeVariable].label

        var content = '';
        var band = '';
        var wavelength = '';
        var band_name = '';
        var type = '';
        var resolution = '';
        var time = '';

        if (layer_name === 'goes_longwave_imagery'){
            time = '5 min'
            band = "14";
            wavelength = "11.2 μm";
            band_name = "Longwave window";
            type = "Infrared";
            resolution = "2 km";
            content = "GOES 16 (East) / GOES 18 (West) thermal infrared band depicts cloud cover and land/sea temperature patterns. The channel is used in many baseline products, such as Fire Detection, Volcanic Ash Detection, and Derived Motion Wind Vector. Credit: NOAA/NESDIS Office of Satellite and Product Operations"

        } else if (layer_name === 'goes_shortwave_imagery') {
            time = '5 min'
            band = "7";
            wavelength = "3.9 μm";
            band_name = "Shortwave window";
            type = "Infrared";
            resolution = "2 km";
            content = "GOES 16 (East) / GOES 18 (West) mid-infrared band can be used to identify fog and low clouds at night, identify fire hot spots, detect volcanic ash, estimate sea-surface temperatures, and discriminate between ice crystal sizes during the day. The 3.9 μm is unique among ABI bands because it senses both emitted terrestrial radiation as well as significant reflected solar radiation during the day. Credit: NOAA/NESDIS Office of Satellite and Product Operations"

        } else if (layer_name == 'goes_visible_imagery') {
            time = '5 min'
            band = "2"
            wavelength = "0.64 μm"
            band_name = "Red"
            type = "Visible"
            resolution = "0.5m"
            content = "GOES 16 (East) / GOES 18 (West) provides detection and analysis of clouds and weather systems during daytime. Half-kilometer resolution allows detection of boundaries and small clouds, especially useful in mesoscale sectors for rapidly changing phenomena. Credit: NOAA/NESDIS Office of Satellite and Product Operations"

        } else if (layer_name === 'goes_snow_ice_imagery') {
            time = '5 min'
            band = "5"
            wavelength = "1.6 μm"
            band_name = "Snow/Ice"
            type = "Near-Infrared"
            resolution = "1 km"
            content = "GOES 16 (East) / GOES 18 (West) takes advantage of the difference between the refraction components of water and ice at 1.61 μm. Liquid water clouds are bright in this channel; ice clouds are darker because ice absorbs (rather than reflects) radiation at 1.61 μm. Thus you can infer cloud phase: compare at right the darker region of the cirrus anvil to the more reflective water-based cumulus clouds to the right of the storm. Land/water contrast is great at 1.61 μm (lakes are readily apparent in the image) and shadows can be particularly striking. Fires can also be detected at night using this band. Credit: NOAA/NESDIS Office of Satellite and Product Operations"

        } else if (layer_name === 'goes_water_vapor_imagery') {
            time = '5 min'
            band = "8"
            wavelength = "6.2 μm"
            band_name = "Upper-level Water Vapor"
            type = "Infrared"
            resolution = "2 km"
            content = "GOES 16 (East) / GOES 18 (West) indicates the amount of water vapor contained in the mid to upper levels of the troposphere, with the darker grays indicating drier air and the brighter grays/whites indicating more saturated air. Used for tracking upper-tropospheric winds, identifying jet streams, forecasting hurricane track and mid-latitude storm motion, monitoring severe weather potential, estimating upper/mid-level moisture and identifying regions where the potential for turbulence exists. Credit: NOAA/NESDIS Office of Satellite and Product Operations"

        } else if (layer_name === 'global_longwave_imagery_mosaic') {
            time = 'Hourly'
            band = "14"
            wavelength = "~12.0 μm"
            band_name = "Longwave window"
            type = "Infrared"
            resolution = "8 km"
            content = "The Global Mosaic of Geostationary Satellite Imagery (GMGSI) is derived from GOES-West, GOES-East, Himawari-9, Meteosat-9, and Meteosat-11, the thermal infrared band depicts cloud cover and land/sea temperature patterns. The channel is used in many baseline products, such as Fire Detection, Volcanic Ash Detection, and Derived Motion Wind Vector. Credit: NOAA/NESDIS Office of Satellite and Product Operations"

        } else if (layer_name === 'global_shortwave_imagery_mosaic') {
            time = 'Hourly'
            band = "7"
            wavelength = "~3.8 μm"
            band_name = "Shortwave window"
            type = "Infrared"
            resolution = "8 km"
            content = "The Global Mosaic of Geostationary Satellite Imagery (GMGSI) is derived from GOES-West, GOES-East, Himawari-9, Meteosat-9, and Meteosat-11, the mid-infrared band can be used to identify fog and low clouds at night, identify fire hot spots, detect volcanic ash, estimate sea-surface temperatures, and discriminate between ice crystal sizes during the day. Credit: NOAA/NESDIS Office of Satellite and Product Operations"

        } else if (layer_name === 'global_visible_imagery_mosaic') {
            time = 'Hourly'
            band = "2"
            wavelength = "~0.6 μm"
            band_name = "Red"
            type = "Visible"
            resolution = "8 km"
            content = "The Global Mosaic of Geostationary Satellite Imagery (GMGSI) is derived from GOES-West, GOES-East, Himawari-9, Meteosat-9, and Meteosat-11, imagery provides detection and analysis of clouds and weather systems during daytime. Especially useful in mesoscale sectors for rapidly changing phenomena. Credit: NOAA/NESDIS Office of Satellite and Product Operations"

        } else if (layer_name === 'global_water_vapor_imagery_mosaic') {
            time = 'Hourly'
            band = "8"
            wavelength = "~6.7 μm"
            band_name = "Shortwave window"
            type = "Infrared"
            resolution = "8 km"
            content = "The Global Mosaic of Geostationary Satellite Imagery (GMGSI) is derived from GOES-West, GOES-East, Himawari-9, Meteosat-9, and Meteosat-11, imagery indicates the amount of water vapor contained in the mid to upper levels of the troposphere, with the darker grays indicating drier air and the brighter grays/whites indicating more saturated air. Used for tracking upper-tropospheric winds, identifying jet streams, forecasting hurricane track and mid-latitude storm motion, monitoring severe weather potential, estimating upper/mid-level moisture and identifying regions where the potential for turbulence exists. Credit: NOAA/NESDIS Office of Satellite and Product Operations"

        };
        return(
            <div style={classes.satelliteFeatureInfoContainer}>
                <center><Typography>Geostationary Weather Satellites</Typography></center>
                <div style={{
                    borderBottom: '1px solid rgba(0, 0, 0, .125)',
                    marginLeft: 10, marginRight: 10, paddingBottom: 10,
                    }}
                >
                </div>
                <br />
                <Typography variant="button" align="center">{label}</Typography>
                <br />
                <Typography variant="caption" align="center">Temporal Resolution:{time}</Typography>
                <TableContainer sx={sxStyles.tableContainer}>
                <Table size="small">
                    <TableHead>
                        <TableRow sx={sxStyles.tableHeader}>
                            <TableCell align="left" sx={sxStyles.tableCell}>ABI Band</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>Central Wavelength</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>Band Name</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>Type</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>Spatial Resolution</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left" sx={sxStyles.tableCell}>{band}</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{wavelength}</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{band_name}</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{type}</TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}>{resolution}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
                <br />
                <Typography variant="caption" align="center"><b>Primary Application: </b>{content}</Typography>
            </div>
        );
    } else {
        return(
            <div style={{padding: '50px 20px'}}>
                <Typography sx={{fontSize: '0.8em'}} >No feature info found at the specified location. Please try again in another location.</Typography>
            </div>

        );
    }
}

// Use this when satellite feature info is ready (or something like it. It will need units)
//<Typography align="center">{Number.parseFloat(props.data.features[0].properties.Band1).toFixed(2)}</Typography>
