import React from 'react';
import { Typography, Grid } from '@mui/material';
import stationLegend from '../../../images/wfs-legends/station_legend.png';
import cloudLegend from '../../../images/wfs-legends/cloud_cover_legend.png';
import windLegend from '../../../images/wfs-legends/wind_legend.png';
import clusterLegend from '../../../images/wfs-legends/cluster_legend.png';
import weatherLegend from '../../../images/wfs-legends/weather_legend.png';

const sxStyles = {
    header: {
        color: '#252525',
        fontSize: '90%',
        mb: '5px',
        paddingTop: 2
    },
    legendLabel: {
        color: '#252525',
        fontSize: '75%',
        textAlign: 'center',
        mr: 3,
        mb: '5px'
    },
    gridContainer: {
        spacing: 2,
        columnGap: 2,
        direction: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        ml: 3
    }
};

const classes = {
    legendIMG: {
        paddingTop: 5,
        width: '90%'
    },
    cloudLegendIMG: {
        paddingTop: 5,
        width: '50%'
    },
    weatherLegendIMG: {
        paddingTop: 5,
        width: '100%'
    },
    label: {
        color: '#252525',
    },
};

function SurfaceObsLegend(){

    return (
        <div style={{marginLeft:'0.5em' }}>
            <div component="div" style={classes.label}>
                <Typography sx={sxStyles.header}>Observations Legend</Typography>
                <Grid container>
                    <Grid item >
                        <img style={classes.legendIMG} alt="" src={stationLegend} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item >
                        <img style={classes.legendIMG} alt="" src={clusterLegend} />
                    </Grid>
                </Grid>
                <Typography sx={sxStyles.header} align='center'>Cloud Cover Legend</Typography>
                <Grid container>
                    <Grid item >
                        <img style={classes.cloudLegendIMG} alt="" src={cloudLegend} />
                    </Grid>
                </Grid>
                <Typography sx={sxStyles.header} align='center'>Wind Velocity Legend (kts)</Typography>
                <Grid container>
                    <Grid item >
                        <img style={classes.cloudLegendIMG} alt="" src={windLegend} />
                    </Grid>
                </Grid>
                <Typography sx={sxStyles.header} align='center'>Present Weather Legend</Typography>
                <Grid container>
                    <Grid item >
                        <img style={classes.weatherLegendIMG} alt="" src={weatherLegend} />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default SurfaceObsLegend;