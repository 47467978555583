import React from 'react';
import {Typography, CircularProgress, InputLabel, MenuItem, Select} from '@mui/material';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';

import LayerMenuItem from './layer-menu-item.js';
import LayerOptionsContainer from './layer-options-container.js';
import TropicalCycloneLegend from '../legend-menu-items/tropical-cyclone-legend.js';

const TROPICAL_SS_INFO = {
    nhc_inundation: {
        label: 'Storm Surge',
        layerName: 'inundation',
    },
    nhc_tidalmask: {
        label: 'Storm Surge w/ Tidal Mask',
        layerName: 'tidalmask',
    },
};

const classes = {
    menuItemBodyContainer: {
        padding: '0.5em',
    },
};

/**
* CycloneLayerControl - contains check boxes and functionality for allowing user to select cyclone layers
*
* @prop (bool) besttrackWindSwathOn = true if layer on else false
* @prop (bool) besttrackTrackOn - true if layer on else false
* @prop (bool) besttrackCtrposOn - true if layer on else false // unused, combined with track
* @prop (bool) forecastWindExtentOn - true if layer on else false
* @prop (bool) forecastTrackOn - true if layer on else false
* @prop (bool) forecastConeOn - true if layer on else false
* @prop (bool) forecastCtrposOn - true if layer on else false // unused, combined with track
* @prop (bool) forecastWarnOn - true if layer on else false
* @prop (list) layersList - list of strings containing names of layers turned on
* @prop (func) updateLayerToggles - callback for updating layerToggles
* @prop (func) updateOlLayerState - callback for updating olLayerState
*/
function CycloneLayerControl(props) {

    /**
    * Event Handler for when layer checkboxes are checked on/off
    *
    * @param (str) layer - name of layer, (REQUIRES: must match layer values from config / geoserver capabilities layer names)
    * @parm (bool) newToggleState - state of checkbox AFTER it has been clicked
    */
    const changeCycloneLayers = (layer, newToggleState) => {
        // Reconstruct dynamic layers list for this source based on properties, but alter the new one for the layer that is passed in
        const newLayersList = props.layersList.slice();

        if (newToggleState) {
            // Append layer to newLayersList
            if (newLayersList.indexOf(layer) === -1) { // layer not in newLayersList
                newLayersList.push(layer);
                // Special case for tracks which need to add or remove two layers
                if (layer === "tropical_cyclone_track_forecast" && newLayersList.indexOf("tropical_cyclone_intensity_forecast") === -1){
                    newLayersList.push("tropical_cyclone_intensity_forecast");
                }else if (layer === "tropical_cyclone_observed_track" && newLayersList.indexOf("tropical_cyclone_observed_locations") === -1){
                    newLayersList.push("tropical_cyclone_observed_locations");
                }
            }
            // If we are adding to a previously empty layer list, then toggle on cyclone layer via layer toggles
            if (props.layersList.length === 0) {
                props.updateLayerToggles({"tropical_cyclones": true});
            }
        }else{
            // Remove layer from newLayersList
            if (newLayersList.indexOf(layer) !== -1) { // layer in newLayersList
                let pos = newLayersList.indexOf(layer);
                newLayersList.splice(pos, 1);
                if (layer === "tropical_cyclone_track_forecast" && newLayersList.indexOf("tropical_cyclone_intensity_forecast") !== -1){
                    pos = newLayersList.indexOf("tropical_cyclone_intensity_forecast");
                    newLayersList.splice(pos, 1);
                }else if (layer === "tropical_cyclone_observed_track" && newLayersList.indexOf("tropical_cyclone_observed_locations") !== -1){
                    pos = newLayersList.indexOf("tropical_cyclone_observed_locations");
                    newLayersList.splice(pos, 1);
                }
            }
            // If we are deleting the last layer, then toggle off whole cyclone layer via layerToggles
            if (newLayersList.length === 0) {
                props.updateLayerToggles({"tropical_cyclones": false});
            }
        }

        // Note: since this is a custom component for cyclones the source name used as a key in this update is hardcoded
        // (it must match key used for the source object in config.js
        props.updateOlLayerState({'layersParam': newLayersList}, 'tropical_cyclones');
    };

    // Note: the layer 'tropical_cyclone_observed_wind_extent' is not listed in config.js because it is turned off by default.
    return (
        <div>
            <FormGroup>
                <FormLabel><Typography variant="overline" >Tropical Cyclone Forecast</Typography></FormLabel>
                <FormControlLabel
                    control={<Checkbox size="small" checked={props.forecastTrackOn} onChange={() => {changeCycloneLayers("tropical_cyclone_track_forecast", !props.forecastTrackOn)}} />}
                    label={<Typography variant="caption">Track & Intensity</Typography>}
                />
                <FormControlLabel
                    control={<Checkbox size="small" checked={props.forecastWindExtentOn} onChange={() => {changeCycloneLayers("tropical_cyclone_current_wind_extent", !props.forecastWindExtentOn)}} />}
                    label={<Typography variant="caption">Wind Extent</Typography>}

                />
                <FormControlLabel
                    control={<Checkbox size="small" checked={props.forecastConeOn}
                    onChange={() => {changeCycloneLayers("tropical_cyclone_cone_of_uncertainty_forecast", !props.forecastConeOn)}} />}
                    label={<Typography variant="caption" >Cone of Uncertainty</Typography>}
                />
                <FormControlLabel
                    control={<Checkbox size="small"  checked={props.forecastWarnOn} onChange={() => {changeCycloneLayers("tropical_cyclone_watches_warnings", !props.forecastWarnOn)}} />}
                    label={<Typography variant="caption"> Coastal Watches & Warnings</Typography>}
                />
                <FormLabel><Typography variant="overline">Tropical Cyclone (Observed Track)</Typography></FormLabel>
                <FormControlLabel
                    control={<Checkbox size="small" checked={props.besttrackTrackOn} onChange={() => {changeCycloneLayers("tropical_cyclone_observed_track", !props.besttrackTrackOn)}} />}
                    label={<Typography variant="caption">Track Line</Typography>}
                />
                <FormControlLabel
                    control={<Checkbox size="small" checked={props.besttrackWindSwathOn} onChange={() => {changeCycloneLayers("tropical_cyclone_observed_wind_extent", !props.besttrackWindSwathOn)}} />}
                    label={<Typography variant="caption" >Surface Wind Swath</Typography>}
                />
            </FormGroup>
        </div>
    );
}

/**
* StormSurgeSelect: Selects current variable to be displayed for tropicalStormSurge
*
*   @prop (obj) currentLayer - Current geoserver layer in use by tropical_ss
*   @prop (obj) updateOlLayerState - callback for updating olLayerState
*   @prop (bool) ssLayerOn - True if storm surge layer is on, else false
*/
function StormSurgeSelect(props) {
    const stormSurgeLayerTypeOptions = Object.entries(TROPICAL_SS_INFO).map(([layerType, info],i) => {
        return (
            <MenuItem key={i} value={info.layerName}>{info.label}</MenuItem>
        );
    });

    /*
    * Event handler to be used with storm surge check boxes.
    * Branches determine outcome based on checkbox being used and state of layer (on/off) and current layersParam
    * and adjust state accordingly
    * args:
    *   checkBoxLayerName (string) - name of layer to be turned on by checkbox that this event handler is applied to
    *   curLayerParam (string) - name of layer currently in use
    *   layerOn (bool) - true if layer is on else false
    */
    const handleStormSurgeCheck = (checkBoxLayerName, curLayerParam, layerOn) => {
        if (layerOn) {
            if (curLayerParam === "inundation") {
                if (checkBoxLayerName === "inundation") {
                    // Checked inundation box while layer was on and layersParam was inundation
                    props.updateOlLayerState({'on': !layerOn}, 'tropical_ss');
                    return;
                } else { // checkBoxLayerName is tidalmask
                    // Checked tidalmask while layer was on and layersParam was inundation
                    props.updateOlLayerState({'layersParam': checkBoxLayerName}, 'tropical_ss');
                    return;
                }
            } else { // curLayerParam is tidalmask
                if (checkBoxLayerName === "inundation") {
                    // Checked inundation while layer was on and layersParam was tidalmask
                    props.updateOlLayerState({'layersParam': checkBoxLayerName}, 'tropical_ss');
                    return;
                } else { // checkBoxLayerName is tidalmask
                    // Checked tidalmask while layer was on and layersParam was tidalmask
                    props.updateOlLayerState({'on': !layerOn}, 'tropical_ss');
                    return;
                }
            }
        } else { // layer is off
            if (curLayerParam === "inundation") {
                if (checkBoxLayerName === "inundation") {
                    // Checked inundation while layer was off and layersParam was inundation
                    props.updateOlLayerState({'on': !layerOn}, 'tropical_ss');
                    return;
                } else { // checkBoxLayerName is tidalmask
                    // Checked tidalmask while layer was off and layersParam was inundation
                    props.updateOlLayerState({'on': !layerOn, 'layersParam': checkBoxLayerName}, 'tropical_ss');
                    return;
                }
            } else { // curLayerParam is tidalmask
                if (checkBoxLayerName === "inundation") {
                    // Checked inundation while layer was off and layersParam was tidalmask
                    props.updateOlLayerState({'on': !layerOn, 'layersParam': checkBoxLayerName}, 'tropical_ss');
                    return;
                } else { // checkBoxLayerName is tidalmask
                    // Checked tidalmask while layer was off and layersParam was tidalmask
                    props.updateOlLayerState({'on': !layerOn}, 'tropical_ss');
                    return;
                }
            }
        }
    }

    return (
            <div>
            <FormGroup>
                <FormLabel><Typography variant="overline" >Potential Storm Surge Flooding</Typography></FormLabel>
                <FormControlLabel
                    control={<Checkbox size="small" checked={(props.ssLayerOn && props.currentLayer === "inundation")} onChange={() => {handleStormSurgeCheck("inundation", props.currentLayer, props.ssLayerOn)}} />}
                    label={<Typography variant="caption">Storm Surge</Typography>}
                />
                <FormControlLabel
                    control={<Checkbox size="small" checked={(props.ssLayerOn && props.currentLayer === "tidalmask")} onChange={() => {handleStormSurgeCheck("tidalmask", props.currentLayer, props.ssLayerOn)}} />}
                    label={<Typography variant="caption">Storm Surge with Tidal Mask</Typography>}
                />
            </FormGroup>
        </div>
    );
}


/**
* TropicalCyclonesLayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (func) updateDynamicLayerList - callback for updating dynamicLayerNames
*   @prop (bool) cycloneActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setCycloneActive - callback for setting wwaActive
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (react component) capUrlsContent - component containing content to display under capUrls tab
*   @prop (obj) opacity - tropical_cyclones opacity value
*/
function TropicalCyclonesLayerMenuItem(props){
    return (
        <LayerMenuItem
            layerName={"tropical_cyclones"}
            label={"Tropical Cyclones"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.cycloneActive}
            setLayerIsActive={props.setCycloneActive}
            updateOlLayerState={props.updateOlLayerState}
            layersList={props.olLayerState.tropical_cyclones.layersParam}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"tropical_cyclones"}
                    layersList={props.olLayerState.tropical_cyclones.layersParam}
                    infoContent={<div>
                        <Typography variant="caption">Tropical Cyclones</Typography>
                        <br /><br />
                        <Typography variant="caption">
                        Latest forecast information for tropical cyclones in the Atlantic, Gulf of Mexico, Eastern and Central
                        Pacific  from the  NWS hurricane centers including forecast intensity, track, cone of uncertainty
                        and coastal tropical storm/hurricane watches and warnings as well present and past locations.
                        The forecast information is from the latest NHC and CPHC advisory. Data Source: NWS National
                        Hurricane Center and Central Pacific Hurricane Center.
                        </Typography>
                        <br /><br />
                        <Typography variant="caption">Tropical Storm Surge</Typography>
                        <br /><br />
                        <Typography variant="caption">
                        Latest NWS Potential Storm Surge Flooding Map for any significant landfalling tropical cyclone
                        expected to impact the Atlantic or Gulf of Mexico Coasts of the Contiguous United States.
                        The map depicts the geographical area where there is a risk of inundation from storm surge
                        along with the potential heights, above ground, that water could reach in those areas. Once issued,
                        the Potential Storm Surge Flooding Map will be updated by NHC every six hours alongside each new
                        Forecast Advisory for the associated tropical cyclone. Data Source: NWS National Hurricane Center
                        </Typography>
                    </div>}
                    legendContent={
                        props.styleInfo ?
                            <TropicalCycloneLegend
                                tropicalCycloneStyleInfo={props.styleInfo.tropical_cyclones}
                                cycloneLayerList={props.olLayerState.tropical_cyclones.layersParam}
                                activeTropicalSSLayer={props.olLayerState.tropical_ss.layersParam}
                            />
                        : <CircularProgress />
                    }
                    capUrlsContent={props.capUrlsContent}
                >
                    <CycloneLayerControl
                        besttrackWindSwathOn={(props.olLayerState.tropical_cyclones.layersParam.indexOf("tropical_cyclone_observed_wind_extent") !== -1)}
                        besttrackTrackOn={(props.olLayerState.tropical_cyclones.layersParam.indexOf("tropical_cyclone_observed_track") !== -1)}
                        forecastWindExtentOn={(props.olLayerState.tropical_cyclones.layersParam.indexOf("tropical_cyclone_current_wind_extent") !== -1)}
                        forecastTrackOn={(props.olLayerState.tropical_cyclones.layersParam.indexOf("tropical_cyclone_track_forecast") !== -1)}
                        forecastConeOn={(props.olLayerState.tropical_cyclones.layersParam.indexOf("tropical_cyclone_cone_of_uncertainty_forecast") !== -1)}
                        forecastWarnOn={(props.olLayerState.tropical_cyclones.layersParam.indexOf("tropical_cyclone_watches_warnings") !== -1)}
                        layersList={props.olLayerState.tropical_cyclones.layersParam}
                        updateLayerToggles={props.updateLayerToggles}
                        updateOlLayerState={props.updateOlLayerState}
                    />
                    <StormSurgeSelect
                        currentLayer={props.olLayerState['tropical_ss'].layersParam}
                        ssLayerOn={props.olLayerState.tropical_ss.on}
                        updateOlLayerState={props.updateOlLayerState}
                    />
                </LayerOptionsContainer>
            </div>
        </LayerMenuItem>
    );
}

export default TropicalCyclonesLayerMenuItem;
