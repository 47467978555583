//import eventManager from 'event-manager.js';
import logger from './logger.js';

/**
* Utility classes intended to determine all possible layer states based on selected dimension value
*/

/**
* DimensionState maintains a hashtable of all possible states for all layers/sources registered with the class
* for a single dimension (ie. Time, Elevation, etc.). This is intended to help efficiently translate a
* requested dimension value across multiple layers which have varying available values for the given dimension.
* Our intended use is to interface with dimension selection UI components and data animations which select dimension
* values and need to quickly determine which layers needed to be updated based ont he selection.
*
* Main functionality of class is to update/initialize hash table based on known capabilities and to determine
* goal state for dimension based on an input dimension value
*
* @member {[int]} _dimensionVals - Sorted array consisting of union of all valid dimension values for all
*   sources registered with the class. Values correspond to keys in stateTable (must maintain sorted order to quickly
*   determine closest dimension value to the input value which may not match a key in stateTable)
*
*   REQUIRES: dimension must be translated into an integer to operate class in order to have a guaranteed
*             sortable/hashable set of dimension values as the basis for the class
*
* @member {Map(int -> obj)} _stateTable - Maps dimension values to js objects containing desired valid time for
*   each registered source for the given dimension value
*
* @member {obj} _latestCaps - object maintaining latest known valid dimension value
*   arrays from capabilities. Used as authority when updating dimensionVals and stateTable. Updated by event
*   handler for capabiltiies. Used because capabilties updates will come from multiple objects only updating
*   subset of values, so stateTable cant be initialized ONLY from a capabilties update in every case.
*
* @member {obj} _curState - state object associated with current dimension val from state table (more like new)
*
* @member {obj} _prevState - old state used for comparison when a new state is determined (more like current till theres a new one)
*
* @member {int} _prevDimVal - previous dimension val that should correspond to previous state
*
* @event dimensionStateUpdated - triggered when a new current dimension value is set and a new state is retrieved
* from stateTable
*   event object format:
*   {
*       (fill in)
*   }
*
*/
class DimensionState {
    /**
    * Create empty members (data will be initialized updateCapabilities is called)
    */
    constructor() {

        this._stateTable = new Map();
        this._dimensionVals = null;
        this._latestCaps = null;
        this._prevState = null;
        this._curState = null;
        this._prevDimVal = null;
    }

    /**
    * Update capabilities
    *
    * @param {obj} caps - contains capabilities to add or change
    *   ex.
    *       {
    *           capName/Identifier : {
    *               values: [valid values],
    *               snap_threshold: threshold
    *           },
    *           ...
    *       }
    */
    updateCapabilities(caps){
        if (!this._latestCaps) {
            this._latestCaps = {}
        }
        // should this check to see if the new capabilities are different? YES <--------------------------------------------------------------------- ADDRESS THIS

        // sorting all valid times arrays to make it faster later MAYBE DONT DO THIS? built in sort has no performance guaranteees
        const sortedCaps = {};
        for (const [capName, validDimensions] of Object.entries(caps)) {
            Object.assign(sortedCaps, {[capName] : { 'values': validDimensions.values.sort((a, b) => a - b), 'snap_threshold': validDimensions.snap_threshold} });
        }
        Object.assign(this._latestCaps, sortedCaps);
        this.updateStateControl();
    }

    /**
    * Init/reinit the dimensionVals array and stateTable based on latestCaps obj
    * ie. This is where we make the hash table that maps all possible times to all correct times for each dataset
    * at that particular time
    */
    updateStateControl() {
        logger("Updating DimensionControl hash table based on the following capabilities:", "debug", "updateState");
        logger(this._latestCaps, "debug", "updateState")

        //create _dimensionVals sorted array containing union of all dimension values in known capabilities
        const valuesUnion = new Set();

        for (const capName in this._latestCaps) {
        //for (const [capName, validDimensions] of Object.entries(this._latestCaps)) { //<-------------- change loop to remove unused var warning
            this._latestCaps[capName].values.forEach((value) => {
                valuesUnion.add(value);
            });
        }
        const newDimensionVals = [...valuesUnion];
        this._dimensionVals = newDimensionVals.sort((a, b) => a - b); //because default will sort as strings

        //create new stateTable table
        const newStateTable = new Map();
        this._dimensionVals.forEach((value) => {
            newStateTable.set(value, {})
            for (const [capName, validDimensions] of Object.entries(this._latestCaps)) {
                let stateVal = this.getClosest(value, validDimensions.values); // Snapping
                // to use snap threshold, must check diff between value and stateVal and see if it exceeds threshold
                if (Math.abs(stateVal - value) > validDimensions.snap_threshold) {
                    stateVal = null;
                }
                Object.assign(newStateTable.get(value), {[capName]: stateVal});
            }
        });
        this._stateTable = newStateTable;

        //Trigger state update using the previous dimension setting now that capabilities have changed
        if (this._prevDimVal) {
            this.setState(this._prevDimVal);
        }

    }

    /**
    * Finds closest value in an array to an input value (driver function for getClosestSearch)
    * REQUIRES: array os sorted
    * REQUIRES: value is type: "number"
    */
    getClosest(val, list) {

        return this.getClosestSearch(val, list, 0, list.length - 1);
    }

    /**
    * binary search using index, without copying array parts
    */
    getClosestSearch(val, list, startIndex, endIndex){
        //base case
        if ( Math.abs(startIndex - endIndex) >= 0 && Math.abs(startIndex - endIndex) <= 2 ){
            //list size 1-3 iterate to find closest numger
            let minDifference = Infinity;
            let minIndex;
            let i;
            for(i = startIndex; i <= endIndex; i++){
                const difference = Math.abs(val - list[i]);
                if (difference < minDifference) {
                    minDifference = difference;
                    minIndex = i;
                }
            }
            return list[minIndex];
        }

        let splitIndex = startIndex + Math.floor((endIndex - startIndex) / 2);

        // alt base case for exact match
        if (list[splitIndex] === val) {
            return list[splitIndex];
        }

        // recursive step
        if (list[splitIndex] > val) {
            return this.getClosestSearch(val, list, startIndex, splitIndex);
        }else{ //list[splitIndex] < val
            return this.getClosestSearch(val, list, splitIndex, endIndex);
        }
    }

    /**
    * Set the the goal state (current state) of Dimension Control based on a dimension value
    * Triggers dimensionStateUpdated event
    *
    * (ie. for time dimension, this would be update time)
    *
    * @params {int} dimVal - current dimension value (time, elevation, etc.) to snap state to
    */
    setState(dimVal) {
        const snappedDimVal = this.getClosest(dimVal, this._dimensionVals);
        this._prevDimVal = dimVal;
        this._prevState = this._curState;
        this._curState = this._stateTable.get(snappedDimVal);

        //DEBUG vvv
//        console.log("(Dimension Calculator) TOP of setState");
//        console.log("dimval is: " + dimVal);
//        console.log("snappedDimVal is " + snappedDimVal + " from dimval array:");
//        console.log(this._dimensionVals);
//        console.log("Retrieved this current state from snapped val:");
//        console.log(this._curState);
//        console.log("and prev state is:");
//        console.log(this._prevState);
        //DEBUG ^^^

        // Note: In previous viewer version, it made sense to only push state updates for layers that saw a change in state
        // based on the input (its possible that two inputs could snap to the same state values)
        // Since refactoring, we need it all. Consider optimizing this if the new system is slow
        //        if (this._prevState) {
        //            const stateDelta = {}
        //            for (const [capName, goalState] of Object.entries(this._curState)) {
        //                if (this._curState[capName] !== this._prevState[capName]) {
        //                    Object.assign(stateDelta, {[capName]: goalState});
        //                }
        //            }
        //            // if state has changed only return info for elements of state that changed
        //            if (Object.entries(stateDelta).length >= 0){
        //                console.log("delta wasnt empty");
        //                //return (stateDelta);
        //            }
        //
        //            // if no effective change to goal state, return null
        //            return (null);
        //        } else {
        //            // prevState is null so this is first run for this state
        //            return (this._curState); // Consider returning deep copy, if usage changes
        //        }
        return (this._curState); // As noted above... just return the full state every time
    }

    /**
    * Return current union of all valid dimension values for this dimension
    */
    getDimensionValsUnion() {
        if (!this._dimensionVals) {
            return (null);
        }
        // Make copy of dimension values union array and return
        return (this._dimensionVals.slice());
    }

    /**
    * Return the last known dimension state value (ie. current time for time dimension
    *   or whichever dimension value that was used to produce latest state object)
    */
    getPrevDimensionVal() {
        return this._prevDimVal;
    }
}

export default DimensionState;
