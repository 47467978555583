import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';


const sxStyles = {
    accordionText: {
        fontSize: '90%',
    },
};

const FeatureAccordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    '&$expanded': {
        margin: 'auto',
    },
}));

const FeatureAccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        {...props}
    />
))(({ theme }) => ({
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: '0.0em',
    '&$expanded': {
        minHeight: '1em',
    },
    '& .MuiAccordionSummary-content': {
        '&$expanded': {
            margin: '12px 0',
        },
    },
}));

const FeatureAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0),
}));


//OLD
//const useStyles = makeStyles((theme) => ({
//    accordionText: {
//        fontSize: '90%',
//    },
//}));
//
//const FeatureAccordion = withStyles({
//  root: {
//    border: '1px solid rgba(0, 0, 0, .125)',
//    boxShadow: 'none',
//    '&:not(:last-child)': {
//      borderBottom: 0,
//    },
//    '&:before': {
//      display: 'none',
//    },
//    '&$expanded': {
//      margin: 'auto',
//    },
//  },
//  expanded: {},
//})(Accordion);
//
//const FeatureAccordionSummary = withStyles({
//  root: {
//    borderBottom: '1px solid rgba(0, 0, 0, .125)',
//    marginBottom: -1,
//    minHeight: '0.0em',
//    '&$expanded': {
//      minHeight: '1em',
//    },
//  },
//  content: {
//    '&$expanded': {
//      margin: '12px 0',
//    },
//  },
//  expanded: {},
//})(AccordionSummary);
//
//const FeatureAccordionDetails = withStyles((theme) => ({
//  root: {
//    padding: theme.spacing(0),
//  },
//}))(AccordionDetails);

/**
*   Used to create expandable feature info container within map click popup
*   - This is a controlled component, state for whether the feature is open or closed must be maintained elsewhere
*   - Pass a feature name and customize color with css classOverride
*   - Pass any child components to be displayed within the expanded feature info
*
*   @prop (obj) classOverride - additional css to apply to feature header only
*   @prop (bool) accordionOpen - state t/f, opened/closed of feature (for Accordion)
*   @prop (func) setAccordionOpen - setter for changing value of accordionOpen
*   @prop (string) featureName - Text to be displayed in AccordionSummary (name of feature)
*   @prop (jsx) children - any components to be displayed within the expanded feature info
*/
export default function FeatureInfoAccordion(props){

    const [open, setOpen] = useState(false);

    return(
        <FeatureAccordion
            expanded={open}
            onChange={() => {
                setOpen(prev => !prev);
                props.setFeatureOn(!props.featureOn);
            }}>
            <FeatureAccordionSummary
                style={props.styleOverride}
                sx={props.classOverride}
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography sx={sxStyles.accordionText}>{props.featureName}</Typography>
            </FeatureAccordionSummary>
            <FeatureAccordionDetails>
                {props.children}
            </FeatureAccordionDetails>
        </FeatureAccordion>
    );
}
