/**
* Intended to be used as a mixin to enable event-like listening/dispatching/passing of event data
*
* Mix with both the listening and dispatching class and have listener register a callback function (listener)
* with dispatching class via addEventListener method. A class with the mixin can dispatch events as needed
* which will call all callback functions registered as listeners for that event
*
* Note: General work flow for this class involves mixing this with classes as an output mechanism
*       Consumers of these events will be classes with tradition API's for input. Listeners at a higher level
*       will typically catch events from these methods and the pass event object data to consumers via their
*       native API
*/

const eventManager = {
    /**
    * Registers a callback functions (eventListener) with a particular event (eventName)
    *
    *@param {string} eventName - name of event to listen for
    *@param {func obj} eventListener - callback function that will be triggered by event (dispatched to)
    */
    addEventListener(eventName, eventListener) {
        if (!this._eventListeners) {
            this._eventListeners = {};
        }
        if (!this._eventListeners[eventName]) {
            this._eventListeners[eventName] = [];
        }
        this._eventListeners[eventName].push(eventListener);
    },

    /**
    * Dispatches event calling each registered listener callback function registered for the event
    *
    *@param {string} eventName - name of event
    *@param {obj} eventData - contains data specific to event to be passed to callback function
    */

     dispatchEvent(eventName, eventData) {
        if (!this._eventListeners?.[eventName]) {
            return;
        }
        //So this was giving the callback function an argument of undefined with the apply line below.... worked without apply... why?

        //this._eventListeners[eventName].forEach(handler => handler.apply(this, eventData)); // <---------------------------------- ADDRESS
        this._eventListeners[eventName].forEach(handler => handler(eventData));
     },

    /**
    * Unregisters/removes event listener
    *
    *@param {string} eventName - name of event
    *@param {func obj} eventListener - callback function to stop listening with
    */
    removeListener(eventName, eventListener) {
        let listeners = this._eventHandlers?.[eventName];
        if (!listeners) {
            return;
        }
        for (let i = 0; i < listeners.length; i++) {
            if (listeners[i] === eventListener) {
                listeners.splice(i--, 1);
            }
        }
    },

}

export default eventManager