import React, { Fragment } from "react";
import { Typography, FormLabel } from '@mui/material';
import LayerMenuItem from './layer-menu-item.js';
import LayerOptionsContainer from './layer-options-container.js';
import SurfaceObsLegend from '../legend-menu-items/surface-obs-legend.js';

const classes = {
    menuItemBodyContainer: {
        padding: '0.5em',
    },
};

const sxStyles = {
    links: {
        '& a': {
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            },
            '&:visited': {
                color: 'primary.main'
            }
        }
    },
};

/**
* SurfaceObsLayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) productActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setProductActive - callback for setting encActive
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*/
function SurfaceObsLayerMenuItem(props){

    let currentAbstract = <>
        <Typography variant="caption">Latest in-situ surface weather and marine weather observations at observing sites using the World
            Meteorological Organization's  international station model. The station model is a method for representing weather data
            collected at an observing platform on a weather map using standard symbols and numbers. The station model depicts present
            weather conditions, cloud cover, wind direction, wind speed, wind gust, visibility, air temperature, dew point temperature,
            air pressure adjusted to mean sea level, and the change in air pressure over the last 3 hours. In addition, for observations
            from marine sites such as coastal stations, buoys, and ships, the station model also includes sea surface water temperature
            and significant wave height. The circle in the model is centered over the latitude and longitude coordinates of the observing
            platform. Presently, observations are displayed from airports (AWOS, ASOS, manual), fixed buoys, C-MAN stations, and ships
            participating in the Voluntary Observing Ship (VOS) program. Observations from other observing networks (e.g. NOS NWLON and PORTS)
            will be added when available from the NWS map service. The observations cover CONUS and U.S. territories and also other countries
            which make their data available to the public. For most observing platforms, the observations are updated hourly.</Typography>
            <br /><br />
        <Typography variant="caption">Source: NWS</Typography>
    </>;

    const links = <>
    <Typography variant='caption'>External Services</Typography>
    <br />
    <Fragment>
        <Typography variant='caption' sx={sxStyles.links}>
            <a href={"https://mapservices.weather.noaa.gov/vector/rest/services/obs/surface_obs/MapServer/"}
            target="_blank" rel="noopener noreferrer">Surface & Marine Weather Observations</a>
        </Typography>
        <br />
    </Fragment>
    </>;

    return (
        <LayerMenuItem
            layerName={"surface_obs"}
            label={"Surface Observations"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.productActive}
            setLayerIsActive={props.setProductActive}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"surface_obs"}
                    layersList={props.layersList}
                    infoContent={currentAbstract}
                    legendContent={
                        <div>
                            <FormLabel><Typography variant="overline">EXTERNAL SERVICE</Typography></FormLabel>
                            <Typography sx={{mb:'10px',mt:'10px'}}>Surface & Marine Weather Observations</Typography>
                            <SurfaceObsLegend/>
                        </div>
                    }
                    capUrlsContent={links}
                >
                </LayerOptionsContainer>
            </div>
        </LayerMenuItem>
    );
}

export default SurfaceObsLayerMenuItem;