import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery, Button, IconButton, Grow, Paper, ClickAwayListener, Grid} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoRounded';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const sxStyles = {
    siteInfoMenuContainer: { // Paper that contains the site info menu menu
        position: 'absolute',
        top: '3.5rem',
        right: {
            sm: '0rem',
            xs: '-1rem',
        },
        padding: '0.75em',
        width: {
            sm: 'auto',
            xs: '100vw'
        },
        zIndex: '1000',
    },
    siteInfoToggleButton: { // button that toggles the site info menu on and off
        backgroundColor: '#FFFFFF',
        color: 'primary.main',
        '&:hover': {
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
        },
        boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.5)',
        borderRadius: 25,
    },
    siteInfoToggleIcon: {
//        '&:hover': {
//            backgroundColor: '#ffffff',
//            borderRadius: 25,
//            color: 'primary.main',
//        },
    },
    links: {
        '& a': {
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            },
            '&:visited': {
                color: 'primary.main'
            }
        }
    },
    footerText: {
    },
};

const classes = {
    noaaLogo: {
        maxHeight: '1.5em',
        width: '1.5em',
        marginRight: '0.25em',
        //boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 1)',
        userSelect: 'none',
        //borderRadius: '50%',
        //float: 'left',
        display: 'inline',
        position: 'relative',
        top: '0.4em',
    },
};

const IconLabelTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        boxShadow: theme.shadows[2],
        fontSize: '65%',
        '& *' : {
            color: theme.palette.primary.light,
        }
    },
}));

/**
* Wrapper for conditionally rendering a tooltip only at cellphone size
*/
function CellPhoneToolTip(props) {
    const theme = useTheme();
    if (useMediaQuery(theme.breakpoints.down('md'))) { //sm and xs (.down is exclusive)
        return(
            <IconLabelTooltip arrow title="INFO" placement="top">
                {props.children}
            </IconLabelTooltip>
        );
    }
    return(
        <>
            {props.children}
        </>
    );
}

/*
* @prop toggleSiteIngoMenu (func) - Callback for toggling site info menu
**/
function SiteInfoToggleButton(props) {
    const theme = useTheme();
    const buttonContent = <><InfoOutlinedIcon sx={sxStyles.siteInfoToggleIcon} /><Typography variant="caption" style={{fontSize:  useMediaQuery(theme.breakpoints.down('xs')) ? "75%" : "" }}>{useMediaQuery(theme.breakpoints.down('md')) ? '' : 'Info'}</Typography></>;
    const cellphone = (useMediaQuery(theme.breakpoints.down('md'))); //sm and xs (.down is exclusive)

    return (
        <CellPhoneToolTip>
            { (cellphone) ?
                <IconButton size="small" onClick={props.toggleSiteInfoMenu} sx={sxStyles.siteInfoToggleButton} >
                    {buttonContent}
                </IconButton>
                :
                <Button size="small" onClick={props.toggleSiteInfoMenu} sx={sxStyles.siteInfoToggleButton} >
                    {buttonContent}
                </Button>
            }
        </CellPhoneToolTip>
    );
}

function SiteInfoMenuContent(props) {
    return(
        <>
            <Grid container  sx={{mb: '0.5em'}} style={{textAlign: "center"}}>
                <Grid item xs={12} >

                    <Typography align="center" variant="h5" sx={{marginBottom: '0.5em'}}><img style={classes.noaaLogo} alt="NOAA" src={require("../../images/noaa_logo_small_alpha_cropped.png")} />nowCOAST</Typography>
                </Grid>
                <Grid item xs={12} >
                    <Typography align="center" variant="body1">Latest Marine Weather and Coastal Information</Typography>
                </Grid>
            </Grid>
            <div style={{borderBottom: '1px solid rgba(0, 0, 0, .125)', marginLeft: 10, marginRight: 10, marginBottom: 10}}></div>
            <Grid container  sx={{mb: '0.5em'}} style={{textAlign: "center"}}>
                <Grid item xs={12} >
                    <Typography align="center" variant="caption">Website Not Certified for Navigational Use</Typography>
                </Grid>
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <Typography variant="caption" sx={sxStyles.links}><a href="https://nauticalcharts.noaa.gov/about/privacy-policy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a> | <a href="https://oceanservice.noaa.gov/disclaimer.html" target="_blank" rel="noopener noreferrer">Disclaimer</a> | <a href="https://touchpoints.app.cloud.gov/touchpoints/41f29ea6/submit" target="_blank" rel="noopener noreferrer">Take our Survey</a></Typography>
                </Grid>
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <Typography variant="caption" sx={sxStyles.links}>Website Owned By: <a href="https://nauticalcharts.noaa.gov/" target="_blank" rel="noopener noreferrer">NOS/Office of Coast Survey</a></Typography>
                </Grid>
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <Typography variant="caption" sx={sxStyles.links}>Contact: nowcoast.team@noaa.gov</Typography>
                </Grid>
            </Grid>
            <Typography variant="caption" align="center" sx={sxStyles.footerText}>Developed by NOAA/NOS/OCS</Typography>
        </>
    );
}

function SiteInfoMenu(props) {
    const theme = useTheme();
    const [siteInfoMenuOn, setSiteInfoMenuOn] = useState(false);

    const toggleSiteInfoMenu = () => {
        const curState = siteInfoMenuOn;
        setSiteInfoMenuOn(!curState);
    }

    const handleClickAway = () => {
        setSiteInfoMenuOn(false);
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <SiteInfoToggleButton
                    toggleSiteInfoMenu={toggleSiteInfoMenu}
                />
                <Grow in={siteInfoMenuOn} style={{ transformOrigin: 'top right' }}>
                    <Paper elevation={3} sx={sxStyles.siteInfoMenuContainer} >
                        <SiteInfoMenuContent />
                    </Paper>
                </Grow>
            </div>
        </ClickAwayListener>
    );
}

export default SiteInfoMenu;