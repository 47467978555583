import React from 'react';
import { Typography, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { ZONE_LAYERS } from '../../../config.js';

const sxStyles = {
    label: {
        color: '#252525',
        fontSize: '85%',
        mb: 1
    },
    gridContainer: {
        columnGap: 2,
        direction: "row",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    legendBox: {
        width: 16,
        height: 16,
        bgcolor: 'transparent',
        border: '2px solid'
    }
};

const classes = {
    legendIMG:{
        width: '100%'
    }
};

/**
* Displays legend image with a label above it
*
* @prop olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*
* NOTE: dynamic styles not enabled for this layer. This component always grabs first available legend with [0]
*/
function FederalBoundariesLegend(props){

    let content = Object.keys(ZONE_LAYERS).map((zoneType,index) => {
        if(props.olLayerState[zoneType].on) {
            return (
            <Grid container sx={sxStyles.gridContainer} key={ZONE_LAYERS[zoneType].id}>
                <Grid item >
                    {ZONE_LAYERS[zoneType].legend_img ?
                    <img style={classes.legendIMG} alt="" src={ZONE_LAYERS[zoneType].legend_img} />
                    : <Box sx={{...sxStyles.legendBox, borderColor: ZONE_LAYERS[zoneType].color}} ></Box> }
                </Grid>
                <Grid item >
                    <Typography sx={sxStyles.label} >{ZONE_LAYERS[zoneType].label}</Typography>
                </Grid>
            </Grid>)
        }
    })

    return (
        <div style={{marginLeft:'0.5em' }}>
            <Typography sx={{...sxStyles.label, fontSize: '90%'}}>Federal Boundaries</Typography>
            {content}
        </div>
    );
}

export default FederalBoundariesLegend;