import React from 'react';
import { Typography, ListSubheader } from '@mui/material';


const sxStyles = {
    label: {
        color: '#252525',
        fontSize: '98%',
    },
};

const classes = {
    legendIMG:{
        maxWidth: '100%',
    },
};

// NBS provides multiple legends for a SINGLE layer via by changing style parameters (via dynamicStylesList)
// the layer used as a key for the legend info is hard coded here, this should always match the parse found in capabilities-handler-wms.js
// as well as well as styleLayerNames in config.js
const LAYER_KEY = "bathymetry";

/**
* Displays legend image with a label above it
*
* @prop (string) activeStyle - current style to be used
* @prop (obj) nbsStyleInfo - legend info that pertains only to nbs (derived from top-level state obj: styleInfo)
*
* NOTE: Dynamic Styles ARE enabled. The styleList and layerList are related by index. Each style should share an index with the layer in layerList
*       that the style belongs to --- but current implementation does not rely on layerList or styleList, but consider this concept with future revisions
*
* Since Dynamic layers are NOT enabled, a hardcoded key is still used here to select the layer whos style information should
* be used when accessing style info
*/
function NBSLegend(props){

    if (!props.nbsStyleInfo) return null;

    // Identify correct style information and render legend
    for (let styleInfo of props.nbsStyleInfo[LAYER_KEY]) {
            const nbsLegendOpt = '&legend_options=layout:horizontal';

        if (styleInfo.name === props.activeStyle) {
            return (
                <ListSubheader component="div">
                    <Typography sx={sxStyles.label}>{styleInfo.title}</Typography>
                    <img style={{ margin: '0px 0px 0px -18px'}} alt="" src={styleInfo.url + nbsLegendOpt}/>
                </ListSubheader>
            );
        }
    }

    return null;
}

export default NBSLegend;