/**
* Debug Settings Obj (DEBUG)
*
* Because the app is asynchronous, persistent debugging messages are kept to help trace execution when needed
* Assign true to any components you wish to see debug logs for oin DEBUG obj.
*
* Note: Setting DEBUG_ALL=true will override all other debug settings.
*       To debug by component, set DEBUG_ALL=false
*
* Note: highlight.text is intended to be 1-4 chars, the chars are multiplied in the logger
*       (longer string will blow up the logs)
*/
const DEBUG_ALL = false;

export const DEBUG_CONF = {
    'getCapabilities': {
        'debugOn': false,
        'highlight': {
            'on': false,
            'text': '~'
        },
        'messagePrefix': '[capabilities-handler-wms][getCapabilities]',
        'stackTraceOn': false
    },
    'togglePlay': {
        'debugOn': false,
        'highlight': {
            'on': false,
            'text': '~'
        },
        'messagePrefix': '[map][togglePlay]',
        'stackTraceOn': false
    },
    'toggleLayerControl': {
        'debugOn': false,
        'highlight': {
            'on': false,
            'text': '~'
        },
        'messagePrefix': '[map][toggleLayerControl]',
        'stackTraceOn': false
    },
    'changeBasemapLayer': {
        'debugOn': false,
        'highlight': {
            'on': false,
            'text': '~'
        },
        'messagePrefix': '[map][changeBasemapLayer]',
        'stackTraceOn': false
    },
    'updateTimeSliderTicks': {
        'debugOn': false,
        'highlight': {
            'on': false,
            'text': '~'
        },
        'messagePrefix': '[map][updateTimeSliderTicks]',
        'stackTraceOn': false
    },
    'stateUpdatedEventHandler': { //event listener for STATE_UPDATED_EVENT
        'debugOn': false,
        'highlight': {
            'on': false,
            'text': '~'
        },
        'messagePrefix': '[map][stateUpdatedEventHandler][STATE_UPDATED_EVENT]',
        'stackTraceOn': false
    },
    'capsUpdatedEventHandler': { //event listener for STATE_UPDATED_EVENT
        'debugOn': false,
        'highlight': {
            'on': false,
            'text': '~'
        },
        'messagePrefix': '[map][capsUpdatedEventHandler][CAPS_UPDATED_EVENT]',
        'stackTraceOn': false
    },
    'updateState': {
        'debugOn': false,
        'highlight': {
            'on': false,
            'text': '~'
        },
        'messagePrefix': '[DimensionController][updateState]',
        'stackTraceOn': false
    }
};

if (DEBUG_ALL) {
    for (let key of Object.keys(DEBUG_CONF)){
        DEBUG_CONF[key].debugOn = true;
    }
}