import { DEBUG_CONF } from './logger-config.js';
/**
* Rudimentory logging function that wraps console.* functions
* Allows for selecting log levels (debug, info, warning, error)
* Allows for configuring selective debugging messages
*/

/**
* Logs message based on classification
*
* ////// NOT ANY MORE @param (JSON) DEBUG_CONF - logger configuration, typically imported to file calling this func from app config
* @param (string) logLevel - log level, either "debug", "info", "warning", "error"
* @param (*) message - log message to be printed to console (usually string but can be bare object to print object details)
* @param (string) logIdentifier - string identify source of log message, only required for debug level logs
*
* This func is not intended to complain about incorrect usage. It's intended to help find errors
* rather than throw them. When used incorrectly it default to calling console.log(message).
*
* Example DEBUG_CONF (only used for debugging currently):
*
* const <DEBUG_CONF> = {
*     '<logIdentifier>': {
*         'debugOn': true,
*         'highlight': {
*             'on': true,
*             'text': '~'
*         },
*         'messagePrefix': '[prefix]',
*         'stackTraceOn': false
*    }, ...
* }
*
* Highlighting example: for debug level message of type string with proper logIdentifier, the highlighted message
* would look like:
*
* ~~~~~~~~~~~~~~~~~~~~~
* ~~~~~~~~~~~~~~~~~ --> [prefix] string stored in message param
* ~~~~~~~~~~~~~~~~~~~~~
*
* If debugOn is false, debug level logs with the associated logIdentifier will not be logged
*/

function logger(message, logLevel, logIdentifier) {

    if (logLevel === "debug" && DEBUG_CONF && logIdentifier) {
        if (DEBUG_CONF[logIdentifier].debugOn) {
            if (DEBUG_CONF[logIdentifier].highlight.on) {
                let highlightText = DEBUG_CONF[logIdentifier].highlight.text;
                console.log(highlightText.repeat(20)); // top highlight line
                if (typeof(message) === 'string') {
                    console.log(highlightText.repeat(16) + ' --> ' + DEBUG_CONF[logIdentifier].messagePrefix + ' ' + message);
                } else {
                    console.log(message);  // Message is object, must be printed alone to show object attributes
                }
                console.log(highlightText.repeat(20)); //bottom highlight line
            } else {
                console.log(typeof(message) === 'string' ? DEBUG_CONF[logIdentifier].messagePrefix + ' ' + message : message);
            }
        }
        if (DEBUG_CONF.stackTraceOn) {
            console.trace();
        }
        return;
    }

    if (logLevel === "warning") {
        console.warn(message);
        return;
    }

    if (logLevel === "error") {
        console.error(message);
        return;
    }
    //default behavior for log level info or unaccounted for inputs
    if (!message) {
        console.log("[logger] Error, logger function called without a valid message. message val is: " + message);
    }
    console.log(message);
    return;
}

export default logger;