import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, Hidden, useMediaQuery } from '@mui/material';
import { AnimationControlButtonsLg, AnimationControlButtonsSm, StepBackButton,
    StepForwButton, PlayControlButtons } from './animation-control-buttons.js';
import TimeSlider from './time-slider.js';
import AnimationInterval from './animation-interval.js';
import { stepAnimationIndex } from './time-utilities.js';
import { findClosestIndexBS } from '../../utilities/utilities.js';

/**
* Time Control
*
* Manages time/animation related components including:
* time slider, play/pause buttons, step buttons, time slider menu
*
* @prop ([int]) timeValuesUnion - union of all possible time values across all data that can be animated
* NOTE: timeValuesUnion used as a flag to indicate that dimension controller is ready for use
*       it was also used for time slider ticks, but that has changed in refactoring
* @prop (obj) timeValues - maps toggle-able animation layers objects that map layer names to their list
*                          of time values from capabilities
* @prop (func) updateTime - callback function for changing selected/current time
* @prop (int) zIndexVal - zindex for component
* @prop (obj) productToggles - maps toggle-able product names to true/false indicating if they are on or off
* @prop (obj) olLayerState - maps ol layer names to obj containing "on" state as well as "layersParam", "stylesParam", and "currentSource"
* @prop (bool) mapClickPopupOn - (optional) true if map-click popup is open, else false
*/

const sxStyles = {
    animationContainer : {
        position: 'absolute',
        bottom: '1.5em',
        width: '100%',
    },
};

const TimeControl = (props) => {
    const theme = useTheme();
    // Store bool determining if timeslider should be displayed (only display when time-enabled data is turned on)
    const [timeSliderOn, setTimeSliderOn] = useState(false);

    // Store Selected Time of time slider
    const [selectedTime, setSelectedTime] = useState(null);

    // Store Selected Time Index for use with animation, time steps
    const [selectedTimeIndex, setSelectedTimeIndex] = useState(null);

    // Store previous productToggles prop (formerly layerToggles) for comparison to current to help avoid extra
    // timesliderticks calculations. NOTE: unlike productToggles, this is a set of only the keys in productToggles that map to true
    const [prevVisibleProducts, setPrevVisibleProducts] = useState(null);

    // Store previous layer On states derived from olLayerStates prop for comparison to current to help avoid extra
    // timesliderticks calculations (layer names taken specifically from layersParam in olLayerstates)
    // NOTE: unlike olLayerState, this is a set of only the layers in olLayerState for which "on" is true
    // and for which the layersParam is a string containing a single layer name
    const [prevVisibleLayers, setPrevVisibleLayers] = useState(null);

    // Store state to indicate if animation is on true/false
    const [playing, setPlaying] = useState(false);

    // Store time slider ticks (actual data points used on the time slider)
    const [timeSliderTicks, setTimeSliderTicks] = useState(null);

    // Store animation speed (unit of frames/sec) 0.2-2.6
    const [animationSpeed, setAnimationSpeed] = useState(0.8);

    // Track the last present time tick/value that was used when time slider ticks were last calculated:
    const [prevPresentTime, setPrevPresentTime] = useState(null);

    // Handle DimensionControl update when selectedTimeChanges
    useEffect(() => {
        if (!props.updateTime || !selectedTime) return;
        props.updateTime({'time': selectedTime, 'dim_time_reference': selectedTime});
        props.updateSelectedTime(selectedTime); // Update global selected time value
    }, [selectedTime, props.updateTime, props.updateSelectedTime]);

    // Reset global selectedTime to null when timeslider is turned off
    useEffect (() => {
        if (timeSliderOn === false) {
            props.updateSelectedTime(null);
        }
    }, [timeSliderOn, props.updateSelectedTime])

	// Update timeSliderTicks whenever available time values change (new capabilities) or whenever layer toggles change
	// So that the time slider only shows actual datapoints for data that is currently displayed (toggled on)
	// NOTE: timeValuesUnion is only used to trigger the effect when capabilities updates occur. This is needed
	// because timeValues will not trigger the effect since it is an object and only its inner data changes
	useEffect(() => {
	    if (!props.timeValuesUnion) return;
	    // Determine which layers need to be included in timeSliderTicks (any layer that's on and in timeValues)
	    // Create usedLayers obj that maps products that are On to list of layers that are On for that product
	    let usedLayers = {};
	    for (let product of prevVisibleProducts) { // At time of this effect prev layers/products are considered current
            if (props.timeValues && props.timeValues.hasOwnProperty(product)) {
                usedLayers[product] = [];
                for (let layerName in props.timeValues[product]) { // layerName refers to geoserver layer/OpenLayers Source Obj's LAYERS parameter
                    if (prevVisibleLayers.has(layerName)) {
                        usedLayers[product].push(layerName);
                    }
                }
            }
	    }

        // Get clients current time (trusting it now to test with) <------------------------------ REFACTOR (notes below)
	    // maybe do if presentTime null then define/update it with setPresentTime(Date.parse(new Date()))
	    let presentTime = Date.parse(new Date());
	    setPrevPresentTime(presentTime);
	    // Notes cited above for setting present time for client (from Slack with former dev - pn-data-gateway 1/26/21):
        //     but yes it's risky to assume client's computer has correct time
        //     in nowCOAST v5 I implemented a "currenttime" operation in the layerinfo service to return system time. Then I compared that to the user's system time, and if it was off by a certain amount I would store an offset value that I'd apply whenever I retrieve system time
        //     https://nowcoast.noaa.gov/layerinfo?request=currenttime&format=json

	    // Turn off time slider when no animation layers are enabled
	    if (Object.keys(usedLayers).length === 0) {
	        setTimeSliderOn(false);
	        setTimeSliderTicks(null);
	        // In case this is the first execution and selectedTime has never been set, set selected time now with
	        // current time, to trigger the first dimensionState object to be pushed out of dimension controller
	        if (!selectedTime) {
	            setSelectedTime(presentTime);
	        }
	        return;
	    }else{
	        setTimeSliderOn(true);
	    }

        // Build timeSliderTicks, by collecting unique time values and then sorting them
	    let curTimeSliderTicks = []
	    const timeTicks = new Set();
	    timeTicks.add(presentTime);

        for (let productName of Object.keys(usedLayers)) {
            // Product is turned on so add the values of its used layers to time slider ticks
            for (let layerName of usedLayers[productName]) {
                if (props.timeValues[productName][layerName]) {
                    for (let timeVal of props.timeValues[productName][layerName]) {
                        timeTicks.add(timeVal)
                    }
                }
            }
        }

        // Move vals from set to array and sort
        for (let timeVal of timeTicks) {
            curTimeSliderTicks.push(timeVal)
        }
        curTimeSliderTicks = curTimeSliderTicks.sort((a, b) => a - b);

        // Stop animation, if it is on
        // Note: currently not in use, it is disorienting. Particularly because services like MRMS update every 2 min,
        //       causing this code to run. This causes the animation to frequently stop which is not ideal
        //       This is also the reasoning for not resetting to presentTime every time this code runs (too annoying to user)
        //setPlaying(false);
        // Update Time Slider Ticks
        setTimeSliderTicks(curTimeSliderTicks);

        // Set selectedTime and selectedTimeIndex
        // If there is no selected time, then this is the first run: use client's present time
        // if selectedTime exists: use that value to snap to nearest existing tick to get selectedTimeIndex
        if (!selectedTime) {
            setSelectedTime(presentTime);
            const presentTimeIndex = findClosestIndexBS(presentTime, curTimeSliderTicks, 0, curTimeSliderTicks.length - 1);
            if (typeof(presentTimeIndex) === "number") {
                setSelectedTimeIndex(presentTimeIndex);
            }else{
                setSelectedTimeIndex(0);
            }
        } else {
            //setSelectedTime(selectedTime); // Always send selected time update for the previous selected time
            const newTimeIndex = findClosestIndexBS(selectedTime, curTimeSliderTicks, 0, curTimeSliderTicks.length - 1);
            if (typeof(newTimeIndex) === "number") {
                setSelectedTimeIndex(newTimeIndex);
                setSelectedTime(curTimeSliderTicks[newTimeIndex]);
            }else{
                setSelectedTimeIndex(0);
                setSelectedTime(curTimeSliderTicks[0]);
            }
        }

    // NOTE about this effect list:
    // props.timeValues and props.productToggles are objects whos references never change so React does not pick them
    // up as triggers for this effect. But, props.timeValuesUnion does change every time that timeValues does and does
    // trigger this effect. The local state: prevVisibleLayers also triggers this effect. It was created specifically
    // for that purpose since layerToggle changes were not triggering this effect
    // (productToggles has even been removed from this effect, as it is no longer needed, with  prevVisibleLayers)
    // FURTHER NOTE: concerning initialization... timeValuesUnion triggers too early during initialization, so
    // it used to be that it would trigger this effect, but timeValues wouldn't have any data in its arrays... you had to manually
    // manipulate the viewer to cause a render and get the this effect to run after timeValues had been populated
    // (since React doesnt see changes to timeValues) so to overcome this, I start timeValues out as null
    // that way, the first time its updated, this effect gets triggered by it that one time so that the slider actually gets
    // initialized
	}, [props.timeValues, props.timeValuesUnion, prevVisibleLayers, prevVisibleProducts, selectedTime]);

    const togglePlay = () => {
        setPlaying(!playing);
    };

    // Callback used by step buttons (setInterval requires different set up)
    const stepAnimation = (backward) => {
        const newTimeIndex = stepAnimationIndex(selectedTime, selectedTimeIndex, timeSliderTicks, backward)
        if (typeof(newTimeIndex) === "number") {
            setSelectedTime(timeSliderTicks[newTimeIndex]);
            setSelectedTimeIndex(newTimeIndex);
        }
    };

    // Determining if layers'/products' On states have changed since last render then updating prevVisibleLayers
    // and prevVisibleProducts
    let productOnStatesChanged = false;
    for (let product in props.productToggles) {
        if (!prevVisibleProducts){
            // Will be null on first run, so change guaranteed
            productOnStatesChanged = true;
            break;
        }
        if (props.productToggles[product]) {
            // product is on
            if (!prevVisibleProducts.has(product)) {
                productOnStatesChanged = true;
                break;
            }
        }else{
            //product is off
            if (prevVisibleProducts.has(product)) {
                productOnStatesChanged = true;
                break;
            }
        }
    }
    if (productOnStatesChanged) {
        const newVisibleProductsSet = new Set();
        for (let product in props.productToggles) {
            if (props.productToggles[product]) {
                newVisibleProductsSet.add(product);
            }
        }
        setPrevVisibleProducts(newVisibleProductsSet);
    }

    let layerOnStatesChanged = false;
    for (let layer in props.olLayerState) {
        if (!prevVisibleLayers){
            // Will be null on first run, so change guaranteed
            layerOnStatesChanged = true;
            break;
        }
        if (typeof(props.olLayerState[layer].layersParam) === "string" && props.olLayerState[layer].layersParam.length > 2) {
            if (props.olLayerState[layer].on) {
                // layer is on
                if (!prevVisibleLayers.has(props.olLayerState[layer].layersParam)) {
                    layerOnStatesChanged = true;
                    break;
                }
            }else{
                //layer is off
                if (prevVisibleLayers.has(props.olLayerState[layer].layersParam)) {
                    layerOnStatesChanged = true;
                    break;
                }
            }
        }
    }
    if (layerOnStatesChanged) {
        const newVisibleLayersSet = new Set();
        for (let layer in props.olLayerState) {
            if (props.olLayerState[layer].on) {
                // REQUIRES: Expects any time-enabled layer to have a single valid string for its layersParam
                // that matches a layer name from capabilities (some non-time-enabled layers like bathy or
                // cyclones do not do this at present. This must be refactored if we ever add an animated layer
                // that uses dynamic layer list functionality
                // REQUIRES: Time-enabled layers must always have OL Source object LAYERS parameter values greater than two
                // This was done in response to us having a number of non-time-enabled esri services with redundant layer
                // values like numbers 1-10 which caused an infinite render bug
                // ToDo: Refactor to support duplicate LAYERS param values. Until then we cannot have any duplicate layer param
                // values anywhere in the config and cannot animate a layer with values less than length 3. Consider not
                // using a set and going with something structured like olLayerState
                if (typeof(props.olLayerState[layer].layersParam) === "string" && props.olLayerState[layer].layersParam.length > 2) {
                    newVisibleLayersSet.add(props.olLayerState[layer].layersParam);
                }
            }
        }
        setPrevVisibleLayers(newVisibleLayersSet);
    }

    let animationControlButtons =
        <AnimationControlButtonsLg
            playing={playing}
            togglePlay={togglePlay}
            stepAnimation={stepAnimation}
            animationSpeed={animationSpeed}
            setAnimationSpeed={setAnimationSpeed}
            setSelectedTime={setSelectedTime}
            capHandlers={props.capHandlers}
        />;

    if (useMediaQuery(theme.breakpoints.down('md'))) {
        animationControlButtons =
            <AnimationControlButtonsSm
                playing={playing}
                togglePlay={togglePlay}
                animationSpeed={animationSpeed}
                setAnimationSpeed={setAnimationSpeed}
                setSelectedTime={setSelectedTime}
                capHandlers={props.capHandlers}
            />;
    }

    return (
        <Grid container sx={{...sxStyles.animationContainer}} style={{zIndex: props.zIndexVal, display: timeSliderOn ? 'flex' : 'none'}} >
            <Grid item xs={'auto'} md={1} lg={2} >
            </Grid>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container>
                    <Grid item xs={2} md={2} sx={{position: 'relative'}} >
                        {animationControlButtons}
                    </Grid>
                    <Grid item xs={8} onWheel={(event)=>{
                        if (!timeSliderTicks || !(typeof(selectedTimeIndex) === "number")) return;
                        const moveBackward = (event.deltaY > 0); // Negative vals for wheel forward, positive vals for wheel backward
                        const newIndex = stepAnimationIndex(timeSliderTicks[selectedTimeIndex], selectedTimeIndex, timeSliderTicks, moveBackward);
                        if (typeof(newIndex) === "number") {
                            setSelectedTime(timeSliderTicks[newIndex]);
                            setSelectedTimeIndex(newIndex);
                        }
                    }}>
                        <TimeSlider
                            setSelectedTime={setSelectedTime}
                            selectedTimeIndex={selectedTimeIndex}
                            setSelectedTimeIndex={setSelectedTimeIndex}
                            timeValues={timeSliderTicks}
                            playing={playing}
                            togglePlay={togglePlay}
                            mapClickPopupOn={(props.mapClickPopupOn) ? props.mapClickPopupOn : false}
                            prevPresentTime={prevPresentTime}
                        />
                        <AnimationInterval
                            setSelectedTime={setSelectedTime}
                            setSelectedTimeIndex={setSelectedTimeIndex}
                            selectedTime={selectedTime}
                            selectedTimeIndex={selectedTimeIndex}
                            timeValues={timeSliderTicks}
                            playing={playing}
                            animationSpeed={animationSpeed}
                        />
                    </Grid>
                    <Grid item xs={2} md={2} sx={{pl: '1em',}} >
                        <PlayControlButtons
                            playing={playing}
                            togglePlay={togglePlay}
                            stepAnimation={stepAnimation}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={'auto'} md={1} lg={2} >
            </Grid>
        </Grid>
    );
}

export default TimeControl;
