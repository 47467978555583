import React, { useEffect, useState } from "react";

import OLSource from "../sources/source.js";
import AutoRefreshInterval from "../sources/source_autorefresh_interval";

/**
* React Component for wrapping a single OL Layer
* Creates all its own OL Sources (wrapped in React OLSource component)
*
* @prop (obj) layerObj - the openLayers layer obj to use for this layer
* @prop (obj) map - the OpenLayers map object used for app
* @prop (obj) sourceInfo - info required to create sources, originating from app config: 'sources' object within LAYERS
* @prop (str) sourceUrl - url to send to sources
* @prop (bool) layerOn - true/false to toggle layer on/off
* @prop (str) currentSourceName - name of source to be used by layer (from app config)
* @prop (int) zIndex - zIndex val for layer within OL Map
* @prop (obj) dimensionState - contains dimension values for all sources within layer
*   ex. {sourceName: {time: val, dim_time_ref: val}, ...}
*   Note: current supported dimensions are time and reference time
* @prop (int) autoRefresh - if set, the layer should auto refresh at the given interval (update itself to cause new request to server)
*   Note: autoRefresh is ONLY intended for use with wwa. It may become a feature that is coded into config.js but only
*   when the needs of future layers are better understood (ie. once theres at least one other layer that has a similar need)
* @prop (int) opacity - number from 0-100 representing opacity for layer
* @prop (obj) styleList - List of style names to be used for sources STYLES parameter
* @prop (bool) refreshLayers - Use to trigger the re-adding of all layers to the map when toggled
*
*/

const OLLayer = (props) => {
	const layer = useState(props.layerObj)[0];

    // Create OL Layer Object
	useEffect(() => {
		if (!props.map) return;

		props.map.addLayer(layer);

		// Returned funcs from useEffect are run on willUnmount
		return () => {
			if (props.map) {
				props.map.removeLayer(layer);
			}
		};
	}, [props.map, layer, props.refreshLayers]);

	// Update zIndex
	useEffect(() => {
		if (!layer) return;
        layer.setZIndex(props.zIndex);
	}, [props.zIndex, layer]);

	// Update Opacity
	useEffect(() => {
		if (!layer) return;
        layer.setOpacity(props.opacity/100);
	}, [props.opacity, layer]);

	// Toggle layer, based on layerOn value OR Turn off layer if it is displaying a source/layer that has exceed
	// the snapThreshold ie. is null (so it needs to stop displaying, but we do not want to affect layerToggles/menu state)
	useEffect(() => {
		if (!layer) return;
		if (props.dimensionState && props.currentSourceName in props.dimensionState && props.dimensionState[props.currentSourceName].time === null) {
		    layer.setVisible(false);
		} else {
		    layer.setVisible(props.layerOn);
		}
	}, [props.layerOn, layer, props.dimensionState, props.currentSourceName]);

    const sources = Object.entries(props.sourceInfo).map(([srcName, srcInfo]) => {
        try {
            if (props.autoRefresh) {
                return (
                    <OLSource key={srcName}
                        layer={layer}
                        sourceObj={srcInfo.sourceObj}
                        sourceOn={(props.currentSourceName === srcName)}
                        layerList={props.layerList}
                        styleList={props.styleList}
                        time={(props.dimensionState && srcName in props.dimensionState) ? props.dimensionState[srcName].time : null} // time is required, ref time is optional
                        refTime={(props.dimensionState && srcName in props.dimensionState && props.dimensionState[srcName].dim_time_reference) ? props.dimensionState[srcName].dim_time_reference : null}
                    >
                        <AutoRefreshInterval
                            sourceObj={srcInfo.sourceObj}
                            autoRefreshInterval={props.autoRefresh}
                            layerOn={props.layerOn}
                        />
                    </OLSource>
                );
            }

            return (
                <OLSource key={srcName}
                    srcName={srcName}
                    layer={layer}
                    sourceObj={srcInfo.sourceObj}
                    layerName={srcName} //references 'layer' parameter within the source
                    sourceOn={(props.currentSourceName === srcName)}
                    layerList={props.layerList}
                    styleList={props.styleList}
                    time={(props.dimensionState && srcName in props.dimensionState) ? props.dimensionState[srcName].time : null} // time is required, ref time is optional
                    refTime={(props.dimensionState && srcName in props.dimensionState && props.dimensionState[srcName].dim_time_reference) ? props.dimensionState[srcName].dim_time_reference : null}
                />
            );
        } catch (e) {
            console.log("Caught exception while preparing to render source (" + srcName + "): " + e, 'error');
            return null;
        }
    });

	return (
        <div>{sources}</div>
	);
};

export default OLLayer;