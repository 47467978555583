import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';


import BasemapMenu from './menu-basemaps.js';
import LayerMenu from './menu-layers.js';
import LegendMenu from './menu-legend.js';
import SiteInfoMenu from './menu-site-info.js';


const sxStyles = {
    menuContainer : {
        position: 'absolute',
        top: {
            sm: '0.25em',
            xs: '0.5em',
        },
        right: {
          sm:  '1em',
          xs:  '0.5em',
        },
        opacity: '85%',
        width: {
            sm: '26em',
            xs: '15em',
        }
    },
};

/**
* MapMenus: Container for the 3 main map menus: Basemaps, Layers, Legend
*
* @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
* @prop (func) updateLayerToggles - callback for updating layerToggles
* @prop (obj) dynamicLayerList - maps source names to lists of geoserver layer names to use within source
* @prop (func) updateDynamicLayerList - callback for updating dynamicLayerNames
* @prop (obj) initializedCaps - maps layer names that rely on getCapabilities to true if the capabilities are done (ie. layer is ready)
* @prop (int) zIndexVal - z index value for menu components
* @prop (obj) layerOpacities - maps layer groups to their opacity value (0-100)
* @prop (func) updateLayerOpacities - callback func for updating opacities
* @prop (func) updateDynamicStyleList - callback for updating dynamicStyleNames
* @prop (bool) mapClickPopupOn - true if map-click-popup is on, else false
* @prop (func) updateCustomLayerInfo - Callback for setting customLayerInfo
* @prop (obj) customLayerInfo - Contains all state for managing custom/user-added layers
* @prop (func) updateMapClickPopup - callback for updating mapClickPopupOn
* @prop (bool) mapClickPopupOn - true if the feature info popup is open, else false
* @prop (func) setLayerMenuOn - callback for updating layerMenuOn
* @prop (bool) layerMenuOn - true if the layer menu is open, else false
* @prop (func) setLegendMenuOn  - callback for updating legend menu open/closed
* @prop (bool) legendMenuOn - true if the feature info popup is open, else false
* @prop (obj) olLayerState - maps ol layer names to obj containing on, layerParams, styleParams, and currentSources
* @prop (func) updateOlLayerState - callback for updating olLayerState
* @prop (obj) styleInfo - obj containing style info for all products parsed from capabilities
* @prop (obj) productInfo - obj containing info/metadata about a product (keywords, service urls)
*
* @state (bool) layerMenuOn - true if layer menu is open
* @state (bool) legendMenuOn - true if legend menu is open
*/
function MapMenus(props) {
    const theme = useTheme();

    // Set layer menu on/off based on screensize when page loads
    // It is off by default, for anything larger than cellphone, it will turn on after this
    // component renders for the first time
    const [firstRender, setFirstRender]= useState(true);
    const [isCellphone, setIsCellphone] = useState(useMediaQuery(theme.breakpoints.only('xs')));

	useEffect(() => {
        if (firstRender && !isCellphone) {
            props.setLayerMenuOn(true);
            setFirstRender(false);
        }
	}, [isCellphone, props.setLayerMenuOn, firstRender]);

    return (
        <Grid container
            justifyContent="flex-end"
            spacing={2}
            sx={{
                ...sxStyles.menuContainer,
                zIndex:props.zIndexVal
            }}
        >
            <Grid item xs="auto" align="center">
                <BasemapMenu
                    updateBasemap={props.updateBasemap}
                />
            </Grid>
            <Grid item xs="auto" align="center">
                <LayerMenu
                    layerMenuOn={props.layerMenuOn}
                    setLayerMenuOn={props.setLayerMenuOn}
                    setLegendMenuOn={props.setLegendMenuOn}
                    updateLayerToggles={props.updateLayerToggles}
                    layerToggles={props.layerToggles}
                    initializedCaps={props.initializedCaps}
                    layerOpacities={props.layerOpacities}
                    updateLayerOpacities={props.updateLayerOpacities}
                    styleInfo={props.styleInfo}
                    customLayerInfo={props.customLayerInfo}
                    updateCustomLayerInfo={props.updateCustomLayerInfo}
                    updateMapClickPopup={props.updateMapClickPopup}
                    mapClickPopupOn={props.mapClickPopupOn}
                    olLayerState={props.olLayerState}
                    updateOlLayerState={props.updateOlLayerState}
                    productInfo={props.productInfo}
                />
            </Grid>
            <Grid item xs="auto" align="center">
                <LegendMenu
                    legendMenuOn={props.legendMenuOn}
                    setLegendMenuOn={props.setLegendMenuOn}
                    setLayerMenuOn={props.setLayerMenuOn}
                    styleInfo={props.styleInfo}
                    layerToggles={props.layerToggles}
                    updateMapClickPopup={props.updateMapClickPopup}
                    mapClickPopupOn={props.mapClickPopupOn}
                    olLayerState={props.olLayerState}
                />
            </Grid>
            <Grid item xs="auto" align="center">
                <SiteInfoMenu />
            </Grid>
        </Grid>
    );
}

export default MapMenus;