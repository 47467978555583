import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import LightningDensityLegend from '../menu/legend-menu-items/ltng-den-legend.js';

const classes ={
    LightningDensityFeatureInfoContainer: {
        padding: '10px'
    },

};

/**
* Component for rendering results from getFeatureInfo wms requests
*
* @prop (obj) data - json response obj from getFeatureInfo request
* @prop (obj) styleInfo - legend info that pertains only to NLDN Lightning Density (derived from top-level state obj: styleInfo)
**/
export default function LightningDensityFeatureInfo(props) {
    if (Object.keys(props.data).length === 0) {
        return(<CircularProgress sx={{margin: '70px 20px'}} />);
    }

    if (props.data.features && props.data.features.length > 0 ) {
        try {
            const pointDataValue = Number.parseFloat(props.data.features[0].properties['Band1']).toFixed(2)
            return(
                <div className={classes.LightningDensityFeatureInfoContainer}>
                    <Typography align="center">15 Min Lightning Strike Density</Typography>
                    <Typography align="center">(strikes/km<sup>2</sup>/min x 10<sup>3</sup>)</Typography>
                    <div style={{
                        borderBottom: '1px solid rgba(0, 0, 0, .125)',
                        marginLeft: 10, marginRight: 10,
                        }}
                    >
                    </div>
                    <br />
                        <Typography sx={{fontSize: '1.0em'}} align="center">{pointDataValue}</Typography>
                    <br />
                    { (props.styleInfo) ?
                        <div align="center">
                            <LightningDensityLegend
                                LightningDensityStyleInfo={props.styleInfo}
                            />
                        </div>
                    : null }
                </div>
            );
        } catch(e) {
            return(
                <div style={{padding: '50px 20px'}}>
                    <Typography sx={{fontSize: '0.8em'}} >No feature info found at the specified location. Please try again in another location.</Typography>
                </div>
            );
        }
    } else {
        return(
            <div style={{padding: '50px 20px'}}>
                <Typography sx={{fontSize: '0.8em'}} >No feature info found at the specified location. Please try again in another location.</Typography>
            </div>
        );
    }
}