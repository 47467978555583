import React, { useState } from 'react';
import { flushSync } from 'react-dom';
import { styled, useTheme } from '@mui/material/styles';
import { Box, IconButton, Grid, ClickAwayListener, Paper, Grow, Typography,
    useMediaQuery, Button, ButtonGroup, Avatar } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import RefreshIcon from '@mui/icons-material/Refresh';

import AnimationSpeedSlider from '../ui-controls/animation-speed-slider.js';

const PaperMenuContainer = styled(Paper)(({ theme }) => ({
    position: 'absolute',
    bottom: '4em',
    left: 0,
    width: '12em',
    zIndex: '1100',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('xl')]: { // lg
        bottom: '4em',
        left: 0,
    },
    [theme.breakpoints.down('lg')]: { // md
        bottom: '4.5em',
        left: 0,
    },
    [theme.breakpoints.down('sm')]: { // xs
        bottom: '5em',
        left: 0,
    },
}));

let sxStyles = {
    buttonContainerLg: {
        //marginLeft: '4em'
    },
    buttonContainerSm: {
        //marginLeft: '1em'
    },
    buttons : {
        color:'white',
        backgroundColor:'rgba(0,0,0,0.6)',
        position: 'relative',
        '&:hover': {
            backgroundColor: 'primary.main',
        },
    },
    menuButtons : {
        color:'primary.main',
        backgroundColor:'white',
        '&:hover': {
            color:'white',
            backgroundColor: 'primary.main',
        },
    },
    buttonSelected : {
        color:'white',
        backgroundColor:'primary.main',
        position: 'relative',
        '&:hover': {
            color:'white',
            backgroundColor: 'primary.main',
        },
    },
};

/**
* Collction of buttons that handle playback and position on time slider
*   @prop (bool) playing - true if animation is on, else false
*   @prop (obj) togglePlay - callback function for turning animation on/off
*   @prop (func) stepAnimation - Contains all capability handlers for each product
**/
export function PlayControlButtons(props){
    const theme = useTheme();

    let showButton = true;
    let bottomMod = '1.3em'
    if (useMediaQuery(theme.breakpoints.down('lg'))) { // True if breakpoint is md
        bottomMod = '1.5em'
    };
    if (useMediaQuery(theme.breakpoints.down('md'))) { // True if breakpoint is sm or xs
        showButton = false;
        bottomMod = '1.65em'
    };

    return (
        <div style={{width:"10em", position:'absolute', bottom:bottomMod,}} >
            {(showButton) ? <StepBackButton
                                playing={props.playing}
                                togglePlay={props.togglePlay}
                                stepAnimation={props.stepAnimation}
                                 /> : ""}
            <PlayPauseButton
                    playing={props.playing}
                    togglePlay={props.togglePlay}
                />
            {(showButton) ? <StepForwButton
                            playing={props.playing}
                            togglePlay={props.togglePlay}
                            stepAnimation={props.stepAnimation}
                             />: ""}
        </div>
    );
}

/**
* Step backward button for time slider
*   @prop (bool) playing - true if animation is on, else false
*   @prop (obj) togglePlay - callback function for turning animation on/off
*   @prop (func) stepAnimation - Contains all capability handlers for each product
**/
function StepBackButton(props) {
    const handleStepBack = () => {
        if (props.playing) {
            props.togglePlay();
        }
        props.stepAnimation('back');
    };

    return (
        <Box>
            <IconButton size='small' sx={{ float:'left', ml: '.5em', mr: '.5em', ...sxStyles.buttons}} onClick={handleStepBack} >
                <KeyboardArrowLeftIcon />
            </IconButton>
        </Box>
        );
}

/**
* Step forward button for time slider
*   @prop (bool) playing - true if animation is on, else false
*   @prop (obj) togglePlay - callback function for turning animation on/off
*   @prop (func) stepAnimation - Contains all capability handlers for each product
**/
function StepForwButton(props) {
    const handleStepForward = () => {
        if (props.playing) {
            props.togglePlay();
        }
        props.stepAnimation();
    };

    return (
        <Box>
            <IconButton size='small' sx={{float:'left', ml: '.5em', ...sxStyles.buttons}} onClick={handleStepForward} >
                <KeyboardArrowRightIcon />
            </IconButton>
        </Box>
    );
}

/**
* Play/pause button for time slider
*   @prop (bool) playing - true if animation is on, else false
*   @prop (obj) togglePlay - callback function for turning animation on/off
**/
function PlayPauseButton(props) {

    let buttonType = (
        <PlayArrowIcon />
    );
    if (props.playing) {
        buttonType = (
            <PauseIcon />
        );
    }
    return (
        <Box>
            <IconButton sx={{float:'left', ...sxStyles.buttons}} onClick={props.togglePlay} size='small'>
                {buttonType}
            </IconButton>
        </Box>
    );
}

/**
* Collection of buttons for controlling time slider and refresh capabilites button
*   @prop (float) animationSpeed - speed of animation in frames per second
*   @prop (func) setAnimationSpeed - callback function for setting animationSpeed
*   @prop (func) setSelectedTime - callback function for setting selected/current time
*   @prop (obj) togglePlay - callback function for turning animation on/off
*   @prop (bool) playing - true if animation is on, else false
*   @prop (obj) capHandlers - Contains all capability handlers for each product
*   @prop (obj) buttonStyle - Contains definition for button style
**/
export function AnimationControlButtonsLg(props) {

    return (
        <div style={{width:"6em", position:'absolute', right:0, bottom:'1.3em',}} >
            <RefreshCapsButton
                setSelectedTime={props.setSelectedTime}
                capHandlers={props.capHandlers}
                buttonStyle={{...sxStyles.buttons }}
            />
            <AnimationControlMenu
                animationSpeed={props.animationSpeed}
                setAnimationSpeed={props.setAnimationSpeed}
                togglePlay={props.togglePlay}
                playing={props.playing}
            />
        </div>
    );
}

/**
* Collection of buttons for controlling time slider animation speed
*   @prop (float) animationSpeed - speed of animation in frames per second
*   @prop (func) setAnimationSpeed - callback function for setting animationSpeed
*   @prop (obj) togglePlay - callback function for turning animation on/off
*   @prop (func) setSelectedTime - callback function for setting selected/current time
*   @prop (obj) capHandlers - Contains all capability handlers for each product
**/
export function AnimationControlButtonsSm(props) {

    return (
        <div style={{width:"3em", position:'absolute', right:0, bottom:'1.65em',}} >
            <AnimationControlMenu
                animationSpeed={props.animationSpeed}
                setAnimationSpeed={props.setAnimationSpeed}
                togglePlay={props.togglePlay}
                playing={props.playing}
                setSelectedTime={props.setSelectedTime}
                capHandlers={props.capHandlers}
            />
        </div>
    );
}

/**
* Collection of buttons for controlling time slider animation speed and refresh capabilites button
*   @prop (float) animationSpeed - speed of animation in frames per second
*   @prop (func) setAnimationSpeed - callback function for setting animationSpeed
*   @prop (obj) togglePlay - callback function for turning animation on/off
*   @prop (bool) playing - true if animation is on, else false
*   @prop (func) setSelectedTime - callback function for setting selected/current time
*   @prop (obj) capHandlers - Contains all capability handlers for each product
**/
function AnimationControlMenu(props) {

    const [timeSliderMenuOn, setTimeSliderMenuOn] = useState(false);

    const toggleTimeSliderMenu = () => {
        const curState = timeSliderMenuOn;
        setTimeSliderMenuOn(!curState);
    }

    const handleClickAway = () => {
        setTimeSliderMenuOn(false);
        return;
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <IconButton size='small' sx={{float:'left' , ml: '.5em', ...sxStyles.buttons}} onClick={toggleTimeSliderMenu} >
                    <MenuIcon />
                </IconButton>
                <Grow in={timeSliderMenuOn} style={{ transformOrigin: 'bottom left' }}>
                    <PaperMenuContainer elevation={0}>
                        <TimeSliderMenuContent
                            animationSpeed={props.animationSpeed}
                            setAnimationSpeed={props.setAnimationSpeed}
                            togglePlay={props.togglePlay}
                            playing={props.playing}
                            setSelectedTime={props.setSelectedTime}
                            capHandlers={props.capHandlers}
                        />
                    </PaperMenuContainer>
                </Grow>
            </div>
        </ClickAwayListener>
    );
}

/**
*   currentSpeed (float) - speed of animation in frames per second
*   newSpeed (float) - new speed being applied
**/
function buttonSelected(currentSpeed, newSpeed) {
    if (currentSpeed === newSpeed) {
        return(sxStyles.buttonSelected)
    }
    return(sxStyles.buttons)
}

/**
<Typography variant="subtitle2" align="center">Time Control Settings</Typography>
            <AnimationSpeedSlider
                animationSpeed={props.animationSpeed}
                setAnimationSpeed={props.setAnimationSpeed}
            />
**/

/**
* Collection of buttons for controlling time slider animation speed and refresh capabilites button
*   @prop (float) animationSpeed - speed of animation in frames per second
*   @prop (func) setAnimationSpeed - callback function for setting animationSpeed
*   @prop (func) setSelectedTime - callback function for setting selected/current time
*   @prop (obj) togglePlay - callback function for turning animation on/off
*   @prop (bool) playing - true if animation is on, else false
*   @prop (obj) capHandlers - Contains all capability handlers for each product
*   @prop (obj) buttonStyle - Contains definition for button style
**/
function TimeSliderMenuContent(props) {
    const theme = useTheme();

    const speedChange = (value) => {
        props.setAnimationSpeed(value);
        if (!props.playing) { // Play if paused
            props.togglePlay();
        }
        if (props.animationSpeed === value && props.playing) { // Paused if playing and user clicks speed that is already selected
            props.togglePlay();
        }
    };

    let currentSpeed = props.animationSpeed

    let showRefreshCapsButton = false;
    if (useMediaQuery(theme.breakpoints.down('md'))) { // True if breakpoint is sm or xs
        showRefreshCapsButton = true;
    }
    
    let refreshCapsButton = 
    		<>
    			<div style={{display: "flex", justifyContent: "left", mt: "5px"}}>
					<RefreshCapsButton
						setSelectedTime={props.setSelectedTime}
						capHandlers={props.capHandlers}
						buttonStyle={sxStyles.buttons}
					/>
				</div>
			</>;		
							
    return(
		<div>
            <IconLabelTooltip title="Animation Speed" placement="top">
                <ButtonGroup
                    sx={{
                        position: 'relative',
                        justifyContent: "left",
                        mb: "1em",
                    }}
                    orientation="horizontal"
                >
                    <IconButton
                        sx={{mr: ".5em", width: '24%', ...buttonSelected(currentSpeed, 0.4)}}
                        size="small"
                        onClick={() => {speedChange(0.4)}}

                    >
                        <Typography variant="caption" style={{fontSize:"80%"}} >.5x</Typography>
                    </IconButton>
                    <IconButton
                        sx={{mr: ".5em", width: '24%',  ...buttonSelected(currentSpeed, 0.8)}}
                        size="small"
                        onClick={() => {speedChange(0.8)}}
                    >
                        <Typography variant="caption" style={{fontSize:"80%"}} >1x</Typography>
                    </IconButton>
                    <IconButton
                        sx={{mr: ".5em", width: '24%', ...buttonSelected(currentSpeed, 1.6)}}
                        size="small"
                        onClick={() => {speedChange(1.6)}}
                    >
                        <Typography variant="caption" style={{fontSize:"80%"}} >2x</Typography>
                    </IconButton>
                    <IconButton
                        sx={{mr: ".5em", width: '24%',  ...buttonSelected(currentSpeed, 3.2)}}
                        size="small"
                        onClick={() => {speedChange(3.2)}}
                    >
                        <Typography variant="caption" style={{fontSize:"80%"}} >3x</Typography>
                    </IconButton>
                </ButtonGroup>
            </IconLabelTooltip>
			{(showRefreshCapsButton) ? refreshCapsButton : ""}
		</div>
	);

}

/**
* Tooltip style and definition
**/
const IconLabelTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        boxShadow: theme.shadows[2],
        fontSize: '65%',
        '& *' : {
            color: theme.palette.primary.light,
        }
    },
}));

/**
* Wrapper for conditionally rendering a tooltip only at cellphone size
**/
function CellPhoneToolTip(props) {
    const theme = useTheme();
    if (useMediaQuery(theme.breakpoints.up('md'))) {
        return(
            <IconLabelTooltip arrow title="Refresh Services" placement="top">
                {props.children}
            </IconLabelTooltip>
        );
    }
    return(
        <IconLabelTooltip arrow title="Refresh Services" placement="right">
            {props.children}
        </IconLabelTooltip>
    );
}

/**
* RefreshCapsButton clears selected time and forces cap handlers to grab latest capabilities
* As a result selectedTime gets set to presentTime as time slider is updated from new capabilities
*
*   @prop (func) setSelectedTime - callback function for setting selected/current time
*   @prop (obj) capHandlers - Contains all capability handlers for each product
*   @prop (obj) buttonStyle - Contains definition for button style
**/
function RefreshCapsButton(props) {

    let refreshCaps = () => {
        props.setSelectedTime(null);
        props.capHandlers.forEach(async (capHandler) => {
            await capHandler.handler.getCapabilities();
        });
    };

    return(
		<CellPhoneToolTip>
			<IconButton size='small' sx={{float:'left', ...props.buttonStyle}} onClick={() => {refreshCaps()}} >
				<RefreshIcon />
			</IconButton>
		</CellPhoneToolTip>
    );
}
