import { useEffect, useState, useRef } from "react";

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import { GeoJSON } from 'ol/format';

/**
* Hook that manages an OpenLayers layer that shows the click dot (for marking latest map click)
*
* Args:
*   olMap - an OpenLayers map object on which to display the layer
*   zIndex - OpenLayers Z-index value to be used for the layer
*
* Returns:
*   clearDot (func): function that clears the layer
*   drawDot (func): function that draws the dot on the layer at the position of the coords passed to it ([x, y])
*/

export default function useDrawDot(olMap, zIndex) {

    const map = useRef(olMap);

    const [dotSource, setDotSource] = useState(() => new VectorSource());
    const [dotLayer, setDotLayer] = useState(() => new VectorLayer({
        style: [
            new Style({
                image: new CircleStyle({
                    radius: 6,
                    fill: new Fill({
                        color: '#005194',
                    }),
                })
            }),
            new Style({
                image: new CircleStyle({
                    radius: 4,
                    fill: new Fill({
                        color: 'rgba(255,255,0,1.0)',
                    }),
                })
            })
        ]
    }));

    useEffect(() => {
        dotLayer.setSource(dotSource);
        dotLayer.setZIndex(zIndex);
        map.current.addLayer(dotLayer);
        return(() => {map.current.removeLayer(dotLayer)});
    }, [dotSource, dotLayer]);

    const clearDot = () => {
        dotSource.clear();
    };

    const drawDot = ([x, y]) => {
        dotSource.addFeature(new Feature({
            geometry: new Point([x, y]),
            })
        );
    }

    return([clearDot, drawDot]);
}
