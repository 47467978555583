import React, { useEffect, useState } from "react";
import GeoJSON from 'ol/format/GeoJSON';
import { Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';

//import { OL_ZINDEXES } from "../../../app/nowcoast/config.js" dont import this to common component, pass as prop from non-common component
//Update: Now that PN is gone and there are no longer "common" components, no longer need to pass as prop

// because i need styles to be created corresponding to featureTypes that are expected in the feature object
// and since we cant see the geojson ahead of time, there is no way to know what their feature types will be
// also this is where colors would be set i think and we have no way to account for that, so I dont know
// how useful this will be but have to start somewhere
const image = new CircleStyle({
  radius: 5,
  fill: null,
  stroke: new Stroke({color: 'red', width: 1}),
});

const STYLES = {
  'Point': new Style({
    image: image,
  }),
  'LineString': new Style({
    stroke: new Stroke({
      color: 'green',
      width: 1,
    }),
  }),
  'MultiLineString': new Style({
    stroke: new Stroke({
      color: 'green',
      width: 1,
    }),
  }),
  'MultiPoint': new Style({
    image: image,
  }),
  'MultiPolygon': new Style({
    stroke: new Stroke({
      color: 'yellow',
      width: 1,
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 0, 0.1)',
    }),
  }),
  'Polygon': new Style({
    stroke: new Stroke({
      color: 'blue',
      lineDash: [4],
      width: 3,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 255, 0.1)',
    }),
  }),
  'GeometryCollection': new Style({
    stroke: new Stroke({
      color: 'magenta',
      width: 2,
    }),
    fill: new Fill({
      color: 'magenta',
    }),
    image: new CircleStyle({
      radius: 10,
      fill: null,
      stroke: new Stroke({
        color: 'magenta',
      }),
    }),
  }),
  'Circle': new Style({
    stroke: new Stroke({
      color: 'red',
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(255,0,0,0.2)',
    }),
  }),
};

const GEOJSON_OBJ = {
  "url": "/geoserver/wwa/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&FORMAT=image%2Fpng8&TRANSPARENT=true&QUERY_LAYERS=wwa_all_hazards&LAYERS=wwa_all_hazards&INFO_FORMAT=application%2Fjson&I=50&J=50&CRS=EPSG%3A3857&STYLES=&WIDTH=101&HEIGHT=101&BBOX=-10054459.761118103%2C3484561.581589317%2C-10016703.790716909%2C3522317.55199051",
  "coords": {
    "x": -10035581.775917506,
    "y": 3503439.5667899135
  },
  "data": {
    "type": "FeatureCollection",
    "features": [
      {
        "type": "Feature",
        "id": "wwa_all_hazards.fid--40884adf_1814f1acc9b_-6198",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [
                  -10049923.62881674,
                  3509978.49773859
                ],
                [
                  -10047697.23900087,
                  3509978.49773859
                ],
                [
                  -10042131.25332926,
                  3507406.64694416
                ],
                [
                  -10036565.28992154,
                  3506120.91616265
                ],
                [
                  -10030999.31538188,
                  3506120.91616265
                ],
                [
                  -10030999.31538188,
                  3507406.64694416
                ],
                [
                  -10027659.73065808,
                  3507406.64694416
                ],
                [
                  -10024320.14593428,
                  3498409.2519794
                ],
                [
                  -10026546.5246182,
                  3493270.72869967
                ],
                [
                  -10032112.51028981,
                  3491986.4205547
                ],
                [
                  -10034338.90010568,
                  3493270.72869967
                ],
                [
                  -10034338.90010568,
                  3497124.42742433
                ],
                [
                  -10035452.09501361,
                  3498409.2519794
                ],
                [
                  -10037678.48482948,
                  3495839.73206942
                ],
                [
                  -10041018.06955328,
                  3493270.72869967
                ],
                [
                  -10043244.45936914,
                  3494555.16584956
                ],
                [
                  -10045470.84918501,
                  3498409.2519794
                ],
                [
                  -10049923.62881674,
                  3499694.20579976
                ],
                [
                  -10049923.62881674,
                  3509978.49773859
                ]
              ]
            ]
          ]
        },
        "geometry_name": "wkb_geometry",
        "properties": {
          "ogc_fid": 27372604,
          "cap_id": "urn:oid:2.49.0.1.840.0.aa48b1aac75f24cb0cb46c8a7d45a332aca64143.001.1",
          "vtec": "/O.NEW.KLIX.FA.W.0002.220610T1943Z-220610T2145Z/",
          "phenom": "FA",
          "sig": "W",
          "wfo": "KLIX",
          "event": "0002",
          "url": "https://alerts-v2.weather.gov/#/?id=urn:oid:2.49.0.1.840.0.aa48b1aac75f24cb0cb46c8a7d45a332aca64143.001.1",
          "msg_type": "FLW",
          "prod_type": "Flood Warning",
          "render_order": 91,
          "issuance": "2022-06-10T19:43:00Z",
          "expiration": "2022-06-10T21:45:00Z",
          "onset": "2022-06-10T19:43:00Z",
          "ends": "2022-06-10T21:45:00Z"
        }
      }
    ],
    "totalFeatures": "unknown",
    "numberReturned": 1,
    "timeStamp": "2022-06-10T20:33:52.513Z",
    "crs": {
      "type": "name",
      "properties": {
        "name": "urn:ogc:def:crs:EPSG::3857"
      }
    }
  }
}

/**
* React Component for wrapping a single OL Layer
* Creates all its own OL Sources (wrapped in React OLSource component)
*
* @prop (obj) map - the OpenLayers map object used for app
* @prop (str) url - url to send to sources
* @prop (bool) layerOn - true/false to toggle layer on/off
* @prop (int) zIndex - zIndex val for layer within OL Map
* @prop (int) opacity - number from 0-100 representing opacity for layer
*
*/

const CustomLayer = (props) => {

	const [layer, setLayer] = useState(null);

    // Create OL Layer Object
	useEffect(() => {
		if (!props.map) return;
		if (layer === null) {
		    // Create layer here (file reading and such)
		    // Rather than get into the weeds with file-handling here:
		    let geojsonObject = GEOJSON_OBJ;
            const layerObj = new VectorLayer({
                source: new VectorSource({
                    features: new GeoJSON().readFeatures(geojsonObject),
                }),
                style: (feature) => {
                    return STYLES[feature.getGeometry().getType()];
                },
            });
		    setLayer(layerObj);
		    props.map.addLayer(layerObj);
		}

		// Returned funcs from useEffect are run on willUnmount
		return () => {
			if (props.map) {
				props.map.removeLayer(layer);
			}
		};
	}, [props.map, layer, setLayer]);

	// Update zIndex
	useEffect(() => {
		if (!layer) return;
        layer.setZIndex(props.zIndex);
	}, [props.zIndex, layer]);

	// Update Opacity
	useEffect(() => {
		if (!layer) return;
        layer.setOpacity(props.opacity/100);
	}, [props.opacity, layer]);

	// Toggle layer, based on layerOn
	useEffect(() => {
		if (!layer) return;
        layer.setVisible(props.layerOn);
	}, [props.layerOn, layer]);

	return (null);
};

export default CustomLayer;