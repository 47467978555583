//import { SNAP_THRESHOLDS } from './config.js';

/**
* eventListeners obj maps valid event names to functions to be used as event listeners with certain objects
* The purpose of these functions is to pass data between specific objects and in most cases to transform
* one objects output data into usable input for the listening objects callback function
*
* These functions are part of our custom even handling system (see event-manager.js mixin to see how event
* registration and execution is handled)
*
* These functions are designed to couple various objects, that are otherwise decoupled. Thus they are designed
* with very specific object-to-object connections in mind. In order to use one of these listeners, the event must
* have been registered with the emitting object. The callback function(s) (belonging to the listening/subscribing
* objects) passed to these event listeners must accept the data passed in by the event listener
*
*/

const eventListeners = {
    /**
    * Event Listener: "capabilitiesUpdated"
    * Passes capabilities data to dimension controller and timeValues react hook
    * INPUT DATA (by/from WMSCapabilitiesHandler:
    * { [product_name] : { // MUST correspond to a top-level key in LAYERS obj of config.js
    *     [capabilities_layer_name] : {
    *       dimensions: { //NOTE: only required dimension is time
    *         time: timeVals,
    *         dim_time_reference: refTimeVals,
    *       },
    *     snapThreshold: this._snapThreshold
    *     }
    * }
    *
    * OUTPUT DATA (to DimensionControl.updateCapabilities):
    * {
    *     product : [product_name],
    *     capabilities: {
    *         [capabilities_layer_name]: {
    *             dimensions: { //NOTE: only required dimension is time
    *                 time: [array of all time vals for the layer, in epoch time],
    *                 dim_time_reference: [array of all ref time vals for the layer, in epoch time]
    *             },
    *         },
    *     }
    *     snap_thresholds: {
    *         time: [snapThreshold],
    *         dim_time_reference: Infinity
    *     }
    * }
    *
    * OUTPUT DATA (to updateTimeValues):
    * {
    *    [product_name]: {
    *       [layer_name]: [list of epoch times for that layer (not explicitly sorted)],
    *       ...
    *    }
    * }
    *
    */
    capabilitiesUpdated: (dimControlCallback, timeValuesCallback) => (caps) => {
        // Note: caps always have a single product key (corresponds to top level keys in LAYERS in config.js)
        const currentProduct = Object.keys(caps)[0];

        const snapThreshold = caps[currentProduct][Object.keys(caps[currentProduct])[0]].snapThreshold;

        // Transform timestamp strings into epoch time ints and arrange data for input to dimension controller
        const epochCaps = {};
        const newTimeVals = {[currentProduct]:{}};
        for (const [capName, capData] of Object.entries(caps[currentProduct])) {
            const epochTimeVals = [];
            capData.dimensions.time.forEach( (timestamp) => {
                // Note: our epoch times are in milliseconds which work with javascript Date. IF we want seconds can
                // convert here but also need to convert back to milli before using Date to make a timestamp string
                // from the epoch int
                //epochTimeVals.push(Math.floor(Date.parse(timestamp)/1000));
                const convertedTimestamp = Date.parse(timestamp);
                epochTimeVals.push(convertedTimestamp);
            });

            if (capData.dimensions.hasOwnProperty('dim_time_reference')) { // Dataset uses reference times
                const epochRefTimeVals = [];
                capData.dimensions.dim_time_reference.forEach( (timestamp) => {
                    const convertedTimestamp = Date.parse(timestamp);
                    epochRefTimeVals.push(convertedTimestamp);
                });

                Object.assign(epochCaps, {
                    [capName]: {
                        dimensions: {
                            time: epochTimeVals,
                            dim_time_reference: epochRefTimeVals
                        },
                    }
                });
            } else {
                Object.assign(epochCaps, { // Dataset only uses time (no reference time)
                    [capName]: {
                        dimensions: {
                            time: epochTimeVals,
                        },
                    }
                });
            }

            // Update newTimeVals object for this layer/capName
            Object.assign(newTimeVals[currentProduct], { // Dataset only uses time (no reference time)
                [capName]: epochTimeVals,
            });
        }

        const dimControlInput = {
            product : currentProduct,
            capabilities: epochCaps,
            snap_thresholds: {
                time: snapThreshold,
                dim_time_reference: Infinity
            }
            //Note: dim_time_reference needs a snapThreshold because it is treated like any dimension by
            // the dimension controller, BUT it should really have a value of Infinity to be safe.
            // Having a large gap between selected time and reference time is common and not a situation
            // where we want data turned off because of such a gap. so only passing a usable value for time
        };

    //    logger("Produced a Dimension Control input from capilities:", 'debug', DEBUG_CONF, 'capsUpdatedEventHandler');
    //    logger(dimControlInput, 'debug', DEBUG_CONF, 'capsUpdatedEventHandler');

        dimControlCallback(dimControlInput);
        timeValuesCallback(newTimeVals);
    },
//    staticCapsUpdated: (caps) => {
//        return;
//    // The old version of this function doesnt work at all with the new map/layers/sources and the entire concept/handling
//    // of "staticCaps/layers" is questionable, it is very likely that we will be changing map services for wwa to include
//    // time values even though its not animated. This function won't be updated untile there is a better plan
//    },
    timeValuesUpdated: (subscriberFunc) => (eventData) => {
            subscriberFunc(eventData);
    },
    dimensionStateUpdated: (subscriberFunc) => (stateUpdates) => {
        // stateUpdates have the form:
        // { <dimension> : { <capID> : <int_value>, ... }, ...}
        // capName/layerName needs to be parsed out of capID and int_value needs to be transformed into
        // usable value for consumer/subscriber source objects in following format:
        //{ <product>: {<capName>: {<dimension1>: <val>, <dimension2>: <val>, ...}, ...}, ...}

        const fixedStateUpdate = {};

        // Iterate: parse capName and url, if its source exists update it, if it doesnt exist, add it and update it
        for (const [dimension, stateData] of Object.entries(stateUpdates)) {
            if (!stateData) continue;
            for (const [capID, val] of Object.entries(stateData)) {
                // Parse url and capName from capID
                const product = capID.split("|")[0];
                const capName = capID.split("|")[1];

                if (!(product in fixedStateUpdate)) {
                    Object.assign(fixedStateUpdate, {[product]: {}});
                }
                if (!(capName in fixedStateUpdate[product])) {
                    Object.assign(fixedStateUpdate[product], {[capName]: {}});
                }

                // Convert value to usable form (timestamp string from epoch int)
                if (val){
                    const dateVal = new Date(val);
                    const timeStampVal = dateVal.toISOString();
                    Object.assign(fixedStateUpdate[product][capName], {[dimension]: timeStampVal});
                } else if (val === null){
                    //val is null, hopefully due to exceeding the snap threshold
                    Object.assign(fixedStateUpdate[product][capName], {[dimension]: val});
                }
            }
        }

        subscriberFunc(fixedStateUpdate);
    },
    stylesUpdated: (styleInfoCallback) => (eventData) => {
    /**
    * Event Listener: "stylesUpdated"
    * Passes capabilities style info to styleInfo react state
    * INPUT DATA (by/from WMSCapabilitiesHandler):
    *{ <OL layer name> :
    *    [
    *        { name: <name of style parsed from capabilities>,
    *          title: <value parsed from capabilities - used as label in viewer>,
    *          width: <width (int) parsed from capabilities>,
    *          height: <height (int) parsed from capabilities>,
    *          format: <format (ex img/png) parsed from capabilities>,
    *          url: <url of legend image parsed from capabilities>,
    *        }, ...
    *    ]
    *}, ...
    * OUTPUT DATA: same
    */
            styleInfoCallback(eventData);
    },
    infoUpdated: (productInfoCallback) => (eventData) => {
    /**
    * Event Listener: "infoUpdated"
    * Passes capabilities info/metadata to productInfo react state
    * INPUT DATA (by/from WMSCapabilitiesHandler):
    *{
    *   <productName> : {
    *       keywords : [],
    *       wmsCapUrl: "",
    *   }, ...
    *}
    * OUTPUT DATA: same
    */
            productInfoCallback(eventData);
    },
};

export default eventListeners;
