import React from 'react';
import { Typography, ListSubheader } from '@mui/material';


const sxStyles = {
    label: {
        color: '#252525',
        fontSize: '98%',
        mb: '10px',
    },
};

const classes = {
    legendIMG:{
        width: '100%'
    },
};



/**
* Displays legend image with a label above it
*
* @prop (string) activeVariable - current NDFD variable being displayed (this string is also a source name and OL source layers parameter value)
*   (the value for activeVariable is derived from olLayerState[layerName]currentSource within app.js state, but only the actual value should be passed to this component
* @prop (obj) ndfdStyleInfo - legend info that pertains only to NDFD (derived from top-level state obj: styleInfo)
*
* NOTE: dynamic styles not enabled for this layer. This component always grabs first available legend with [0]
*/
function NDFDLegend(props){
    const styleKey = "conus_" + props.activeVariable; // styles are parsed and stored under conus layers
    if (!props.ndfdStyleInfo || !props.ndfdStyleInfo[styleKey]) return null;

    return (
        <ListSubheader component="div">
            <Typography sx={sxStyles.label}>{props.ndfdStyleInfo[styleKey][0].title}</Typography>
            <img style={{ margin: '0px 0px 0px -18px'}} alt="" src={props.ndfdStyleInfo[styleKey][0].url} />
        </ListSubheader>
    );
}

export default NDFDLegend;